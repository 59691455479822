
import { B_REST_Utils, B_REST_Requests } from "@/bREST/core/classes";
import Calendar                          from "@/custom/components/calendar/Calendar.js";
import CalendarEvent                     from "@/custom/components/calendar/CalendarEvent.js";
import RegFlowFilters                    from "./filters/RegFlowFilters.js";

import { default as MyApp_Class } from "@/custom/App.js";
const MyApp = MyApp_Class.instance;

const STEPS    = MyApp.consts.regFlow_step;
const REASONS  = MyApp.consts.regFlow_reason;

const STATUSES = MyApp.consts.regFlow_status;
const STATUSES_CLOSED = [STATUSES.CLOSED_COMPLETED,STATUSES.CLOSED_USER,STATUSES.CLOSED_TIMEOUT];

const CONTRACT_TYPES = MyApp.consts.clientSessionContract_type;
const CONTRACT_TYPES_NORMAL_PRESENTIAL_OR_COMBO = [CONTRACT_TYPES.NORMAL_PRESENTIAL,CONTRACT_TYPES.NORMAL_COMBO];
const CONTRACT_TYPES_NORMAL_VIRTUAL_OR_COMBO    = [CONTRACT_TYPES.NORMAL_VIRTUAL,   CONTRACT_TYPES.NORMAL_COMBO];

/*
NOTES:
	We might not have a idUser yet (so no access token), so we must rely on hashes to point to our Model_RegFlow PK
*/



export default class RegFlow
{
	static get ALLOW_PAYMENT_METHOD_EXTERNAL()          { return false; }  //Because... U mad. Don't allow hell anymore
	static get ALLOW_PAYMENT_METHOD_EXTERNAL_SESSIONS() { return [1];   }  //At most, only the initial winter one
	
	//NOTE: Those only work if we're in sandbox mode in right account
		static get DEBUG_CC_INFO_OK()    { return {cardName:'Test OK',    cardNumber:'4502285070000007', cardMonth:'04', cardYear:2024, cardCvv:'123'}; }
		static get DEBUG_CC_INFO_WRONG() { return {cardName:'Test wrong', cardNumber:'4355310002576375', cardMonth:'04', cardYear:2024, cardCvv:'123'}; }
	
	static get REQUEST_HEADERS_PK_HASH() { return "x-outdoorfitness-reg-flow"; } //NOTE: Should match server's RouteParser_OutdoorFitness_base::REQUEST_HEADERS_PK_HASH
	
	static get STATUSES() { return STATUSES; }
	static get STEPS()    { return STEPS;    }
	static get REASONS()  { return REASONS;  }
	
	static get LS_KEY() { return "regFlow"; }
	
	static get TIME_REMAINING_INTERVAL_MSECS()     { return 1000*5; } //NOTE: Since instance is passed as prop in lots of components, having timer in this class causes lots of refresh and hell when we get exceptions. Check _timeRemaining_checkReSetupInterval() docs
	static get TIME_REMAINING_EXPIRING_SOON_SECS() { return 60*2;   }
	
	static get CALL_BOOT()                                                            { return "boot";                                                       }
	static get CALL_ABORT()                                                           { return "abort";                                                      }
	static get CALL_EXTEND()                                                          { return "extend";                                                     }
	static get CALL_STEPS_NAV_BACK()                                                  { return "steps_navBack";                                              }
	static get CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_CHANGE_INFO()                { return "steps_newReasonFranchiseeSchedule_changeInfo";               }
	static get CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_SET_AS_OF_DT()               { return "steps_newReasonFranchiseeSchedule_set_asOf_dt";              }
	static get CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_SELECT_OCCURRENCES_TRY()     { return "steps_newReasonFranchiseeSchedule_selectOccurrences_try";    }
	static get CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_SELECT_OCCURRENCES_UNDO()    { return "steps_newReasonFranchiseeSchedule_selectOccurrences_undo";   }
	static get CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_UN_SELECT_OCCURRENCES_TRY()  { return "steps_newReasonFranchiseeSchedule_unSelectOccurrences_try";  }
	static get CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_UN_SELECT_OCCURRENCES_UNDO() { return "steps_newReasonFranchiseeSchedule_unSelectOccurrences_undo"; }
	static get CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_COMPLETE()                   { return "steps_newReasonFranchiseeSchedule_complete";                 }
	static get CALL_STEPS_CHECKOUT_UPDATE_PENALTY_FEES()                              { return "steps_checkout_updatePenaltyFees";                           }
	static get CALL_STEPS_CHECKOUT_SET_INITIAL_INSTALLMENT_D()                        { return "steps_checkout_set_initialInstallment_d";                    }
	static get CALL_STEPS_CHECKOUT_COMPLETE()                                         { return "steps_checkout_complete";                                    }
	
	//Must match w server's RouteParser_RegFlow::FILTERS_x
		static get FILTERS_REASON()         { return "r"; } //Must point to a Model_RegFlow::REASON_x
		static get FILTERS_FRANCHISEE()     { return "f"; } //Must point to a Model_Franchisee::marketingTag
		static get FILTERS_CONFIG_PROGRAM() { return "p"; } //Must point to a Model_ConfigProgram::marketingTag
	
	static _REASONS_NORMAL = [REASONS.NORMAL_PRESENTIAL, REASONS.NORMAL_VIRTUAL, REASONS.NORMAL_COMBO, REASONS.NORMAL_REVOKE];
	static _REASONS_W_SCHEDULE = [
		null, //NOTE: Must include, otherwise won't be able to see schedule before choosing first event
		REASONS.NORMAL_PRESENTIAL,
		REASONS.NORMAL_COMBO,
		REASONS.NORMAL_VIRTUAL, //NOTE: Must include virtual, because when we choose virtual, we get sent to cart, and if we nav back, must be able to see presential anyways
		REASONS.NORMAL_REVOKE,  //NOTE: Same note as for virtual
		//NOTE: Just like for virtual, we include their x_REVOKE counter part to future proof code
		REASONS.MAKEUP,        REASONS.MAKEUP_REVOKE,
		REASONS.TRIAL,         REASONS.TRIAL_REVOKE,
		REASONS.SPECIAL_EVENT, REASONS.SPECIAL_EVENT_REVOKE,
		REASONS.FLEX,          REASONS.FLEX_REVOKE,
		REASONS.A_LA_CARTE,    REASONS.A_LA_CARTE_REVOKE,
	];
	static _REASONS_W_CHECKOUT_STEP = [
		REASONS.NORMAL_PRESENTIAL, REASONS.NORMAL_COMBO, REASONS.NORMAL_VIRTUAL, REASONS.NORMAL_REVOKE,
		//NOTE: Just like for virtual, we include their x_REVOKE counter part to future proof code
		REASONS.TRIAL,         REASONS.TRIAL_REVOKE,
		REASONS.MAKEUP,        REASONS.MAKEUP_REVOKE,
		REASONS.SPECIAL_EVENT, REASONS.SPECIAL_EVENT_REVOKE,
		REASONS.FLEX,          REASONS.FLEX_REVOKE,
		REASONS.A_LA_CARTE,    REASONS.A_LA_CARTE_REVOKE,
	];
	static _REASONS_W_CHECKOUT_STEP_W_CONTRACT = [
		REASONS.NORMAL_PRESENTIAL, REASONS.NORMAL_COMBO, REASONS.NORMAL_VIRTUAL, REASONS.NORMAL_REVOKE,
		//NOTE: Just like for virtual, we include their x_REVOKE counter part to future proof code
		REASONS.SPECIAL_EVENT, REASONS.SPECIAL_EVENT_REVOKE,
		REASONS.FLEX,          REASONS.FLEX_REVOKE,
		REASONS.A_LA_CARTE,    REASONS.A_LA_CARTE_REVOKE,
	];
	
	static _REASONS_W_ACCEPTATIONS_TO_FILL = [
		REASONS.NORMAL_PRESENTIAL,
		REASONS.NORMAL_VIRTUAL,
		REASONS.NORMAL_COMBO,
		REASONS.MAKEUP,
		REASONS.TRIAL,
		REASONS.PRIVATE_GROUP,
		REASONS.SPECIAL_EVENT,
		REASONS.FLEX,
		REASONS.A_LA_CARTE,
	];
	
	static PAYMENT_METHOD_MONERIS_NEW      = "moneris_new";
	static PAYMENT_METHOD_MONERIS_EXISTING = "moneris_existing";
	static PAYMENT_METHOD_EXTERNAL         = "external";
	static _PAYMENT_METHODS = [
			RegFlow.PAYMENT_METHOD_MONERIS_NEW,
			RegFlow.PAYMENT_METHOD_MONERIS_EXISTING,
			RegFlow.PAYMENT_METHOD_EXTERNAL,
		];
		static get PAYMENT_METHODS() { return RegFlow._PAYMENT_METHODS; }
		//Frontend's RegFlow::PAYMENT_METHOD_x & server's RouteParser_RegFlow::PAYMENT_METHOD_x must be consistent
	
	
	static get ACCEPTATION_SPAP_NO_PROB()   { return "noProb";   }
	static get ACCEPTATION_SPAP_HAS_PROBS() { return "hasProbs"; }
	
	static _nextUUID = 0;
	
	static get DEBUG_HASHES()
	{
		return {
			normal: "1-5a1441ec98c254cbd67a64325948fb0cee44a204cf0ca8019e768314e29bab384169555ddf89cd7db765d01b3c4da70740b8c03d5832670b1c980df6026d7dc8",
			makeUp: "2-91f586902a39ff4496e9a32f197f0a39a0ac9b13f3902bf6f83f1820ad2961f1cfd4adfa6d43112d7fe75bff6e8a0e37bbc47ce1636afba0d4b70bb3d9887945",
			trial:  "3-eaa73421b8b574e7b007fee2078f9c5b6c65d9e517ab9776c9d3d74607cee9e48596b8a38a8b8e6c5a3606a5d22e44bfab1c89093c166772c557a3a2c6c1e8ed",
		};
	} //To use these, do hash_ls_set() and reload page
	
	
	
	_uuid                                                             = null;
	_isLoading                                                        = true;
	_model                                                            = null;                                                             //Instance of Model_RegFlow
	_isDestroyed                                                      = false;
	_filters                                                          = new RegFlowFilters();                                             //Instance of RegFlowFilters
	_newContract                                                      = null;                                                             //Check server's RouteParser_RegFlow::_checkPrepContracts()
	_currentContract                                                  = null;                                                             //Check server's RouteParser_RegFlow::_checkPrepContracts()
	_finalContract                                                    = null;                                                             //Either _newContract or _currentContract, or NULL
	_steps_newReasonFranchiseeSchedule_listener_occurrenceTypeChanges = null;                                                             //Func as (event_fk, occurrenceTypeChanges) - See server's Struct_EventClient_OccurrenceTypeChanges
	_handleCalendarEventHook_listener                                 = null;                                                             //Func as (which,calendar,event)
	_acceptation_termsAndConditions                                   = false;
	_acceptation_spap                                                 = null;                                                             //Const of ACCEPTATION_SPAP_x
	_paymentMethod                                                    = null;                                                             //Const of PAYMENT_METHOD_x. NOTE: Check paymentMethod setter docs about why this should sometimes stay NULL
	_creditCardInfo                                                   = {cardName:'',cardNumber:'',cardMonth:'',cardYear:'',cardCvv:''};  //For VuePayCard.vue in Step5CheckoutConfirm.vue, only if PAYMENT_METHOD_MONERIS_NEW
	_paymentGateway_successful_dashboardRefNumber                     = null;                                                             //Transaction ref number that can be shown to the user + can be seen in payment gateway's dashboard
	_paymentGateway_successful_amount                                 = null;                                                             //To know if we did a payment or refund
	_ongoingRegFlowUIRouteConflict                                    = null;                                                             //Check boot() docs
	//Time remaining related
		_timeRemaining_intervalPtr              = null;
		_timeRemaining_secs                     = null;
		_timeRemaining_flaggedExpiringSoon      = false;  //If timeRemaining_listener_expiresSoon has been fired
		_timeRemaining_selectionsBookedUntil_dt = null;   //Points on selectionsBookedUntil_dt of the RegFlow, but as a Date instance, instead of YmdHis
		_timeRemaining_listener_expiresSoon     = null;   //Func as ()
		_timeRemaining_listener_expired         = null;   //Func as ()
			//WARNING: Defining timer here makes all components using the instance to recompute all computed each interval
	
	
	
	constructor(options)
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			on_occurrenceTypeChanges:     {accept:[Function], default:null},
			on_handleCalendarEventHook:   {accept:[Function], default:null},
			on_timeRemaining_expiresSoon: {accept:[Function], default:null},
			on_timeRemaining_expired:     {accept:[Function], default:null},
		}, "RegFlow");
		
		this._uuid = RegFlow._nextUUID++;
		this._steps_newReasonFranchiseeSchedule_listener_occurrenceTypeChanges = options.on_occurrenceTypeChanges;
		this._handleCalendarEventHook_listener                                 = options.on_handleCalendarEventHook;
		this._timeRemaining_listener_expiresSoon                               = options.on_timeRemaining_expiresSoon;
		this._timeRemaining_listener_expired                                   = options.on_timeRemaining_expired;
		
		this._model = MyApp.models_make("RegFlow");
	}
		/*
		Use filters to specify things from WP, ex wanted session, franchisee, configPrograms etc
		Check server's RouteParser_RegFlow::_regFlow_checkLoadReCreate() docs for what this does
		We update and reuse received hash in LS, since we can start reg flow wo having a user yet
		If we're just starting, pre select current session if we have only 1 ongoing, against Model_CurrentSessionInfo
		Could throw
		UI route conflict:
			When we detect user is trying to do something that would imply losing cart (ex was on trial reg but going on makeUp), ongoingRegFlowUIRouteConflict will then contain:
				{
					uiRoute_expected:        regFlow-trial, //Then, if dude says to stay on prev route, can kick out and go back there
					uiRoute_expected_reason: REASON_TRIAL,
					uiRoute_wanted:          regFlow-makeUp //Then, if dude says to become new route, must do a new API call after confirm to call steps_newReasonFranchiseeSchedule_changeInfo(). NOTE: For normal, we've got 4 reasons, so stays NULL and can't fully change
					uiRoute_wanted_reason:   REASON_MAKEUP,
				}
			Check server's RouteParser_RegFlow::_check_load_reCreate_cycleTimeout_kickOut() for more info
		*/
		async boot(options)
		{
			options = B_REST_Utils.object_hasValidStruct_assert(options, {
				filters:     {accept:[Object], default:null},
				uiRouteName: {accept:[String], default:null},
			}, "RegFlow - boot");
			
			const options_filters = options.filters;
			
			const filters_configProgram_marketingTag = options_filters[RegFlow.FILTERS_CONFIG_PROGRAM];
			if (filters_configProgram_marketingTag)
			{
				const configProgramList = MyApp.sharedLists_getItems("configProgramList");
				
				const filters_configProgram = configProgramList.find(loop_configProgram => loop_configProgram.select("marketingTag").val===filters_configProgram_marketingTag);
				if (!filters_configProgram) { RegFlow.throwEx(`Couldn't find "${filters_configProgram_marketingTag}" in programs`); }
				
				this._filters.configPrograms.selections_add(filters_configProgram.pk);
			}
			
			const responseData = await this._apiCall(RegFlow.CALL_BOOT, {filters:options_filters,uiRouteName:options.uiRouteName}); //Throws
				if (this._isDestroyed) { RegFlow.throwEx(`Should never end up w destroyed instance in boot call`); } //IMPORTANT: Don't check against isDestroyedOrTimeouting, as we want to allow timeouting, to give a chance to user to see that it expired
			
			this._ongoingRegFlowUIRouteConflict = responseData.extraData?.ongoingRegFlowUIRouteConflict ?? null;
		}
		//To destroy bound model & release countdown set interval
		destroy(clearLocalStorage)
		{
			this._isDestroyed = true;
			this._model       = null;
			this._timeRemaining_checkClearInterval();
			//Sometimes we don't want to drop LS, ex if we're just releasing the instance from a component or reloading the page
			if (clearLocalStorage) { RegFlow.hash_ls_remove(); }
		}
			get isDestroyed()             { return this._isDestroyed;                                      }
			get isDestroyedOrTimeouting() { return this._isDestroyed || this.status===STATUSES.TIMEOUTING; }
	
	
	static throwEx(msg, details=null) { MyApp.throwEx(msg,details); }
	
	static get hash_ls_has() { return B_REST_Utils.localStorage_has(RegFlow.LS_KEY);                            }
	static hash_ls_get()     { return B_REST_Utils.localStorage_get(RegFlow.LS_KEY,/*throwIfNull*/false);       }
	static hash_ls_set(hash) { return B_REST_Utils.localStorage_set(RegFlow.LS_KEY,hash,/*isPersistent*/false); }
	static hash_ls_remove()  { return B_REST_Utils.localStorage_remove(RegFlow.LS_KEY);                         }
	
	
	get uuid() { return this._uuid; }
	
	get isLoading() { return this._isLoading; }
	
	get hash() { return this._model ? this._model.select("hash").data : null; }
	
	get status()                   { return this._model ? this._model.select("status").val : STATUSES.CLOSED_USER; } //Not sure about default status, as it could be CLOSED_TIMEOUT and it's weird to force it being closed by the user. Maybe ONGOING is also OK
	get status_isOngoing()         { return this.status===STATUSES.ONGOING;                                        }
	get status_isTimeouting()      { return this.status===STATUSES.TIMEOUTING;                                     }
	get status_isClosedCompleted() { return this.status===STATUSES.CLOSED_COMPLETED;                               }
	get status_isClosedUser()      { return this.status===STATUSES.CLOSED_USER;                                    }
	get status_isClosedTimeout()   { return this.status===STATUSES.CLOSED_TIMEOUT;                                 }
	get status_isClosed_any()      { return STATUSES_CLOSED.includes(this.status);                                 }
	
	set step(val)                            { this._model.select("step").val=val;                                                         }
	get step()                               { return this._model ? this._model.select("step").val : STEPS.NEW_REASON_FRANCHISEE_SCHEDULE; }
	get step_isNewReasonFranchiseeSchedule() { return this.step===STEPS.NEW_REASON_FRANCHISEE_SCHEDULE;                                    }
	get step_isCheckout()                    { return this.step===STEPS.CHECKOUT;                                                          }
	get step_isCompleted()                   { return this.step===STEPS.COMPLETED;                                                         }
	
	set reason(val) { this._model.select("reason").val=val;             }
	get reason()    { return this._model?.select("reason").val ?? null; }
	
	get reason_isNormal_any()          { return RegFlow._REASONS_NORMAL.includes(this.reason); }
	get reason_isNormal_presential()   { return this.reason===REASONS.NORMAL_PRESENTIAL;       }
	get reason_isNormal_virtual()      { return this.reason===REASONS.NORMAL_VIRTUAL;          }
	get reason_isNormal_combo()        { return this.reason===REASONS.NORMAL_COMBO;            }
	get reason_isNormal_revoke()       { return this.reason===REASONS.NORMAL_REVOKE;           }
	get reason_isMakeUp()              { return this.reason===REASONS.MAKEUP;                  }
	get reason_isMakeUp_revoke()       { return this.reason===REASONS.MAKEUP_REVOKE;           }
	get reason_isTrial()               { return this.reason===REASONS.TRIAL;                   }
	get reason_isTrial_revoke()        { return this.reason===REASONS.TRIAL_REVOKE;            }
	get reason_isPrivateGroup()        { return this.reason===REASONS.PRIVATE_GROUP;           }
	get reason_isPrivateGroup_revoke() { return this.reason===REASONS.PRIVATE_GROUP_REVOKE;    }
	get reason_isSpecialEvent()        { return this.reason===REASONS.SPECIAL_EVENT;           }
	get reason_isSpecialEvent_revoke() { return this.reason===REASONS.SPECIAL_EVENT_REVOKE;    }
	get reason_isFlex()                { return this.reason===REASONS.FLEX;                    }
	get reason_isFlex_revoke()         { return this.reason===REASONS.FLEX_REVOKE;             }
	get reason_isALaCarte()            { return this.reason===REASONS.A_LA_CARTE;              }
	get reason_isALaCarte_revoke()     { return this.reason===REASONS.A_LA_CARTE_REVOKE;       }
	get reason_isArticle()             { return this.reason===REASONS.ARTICLE;                 }
	get reason_isArticle_revoke()      { return this.reason===REASONS.ARTICLE_REVOKE;          }
	
	get reason_hasSchedule()               { return RegFlow._REASONS_W_SCHEDULE.includes(this.reason);                 }
	get reason_hasCheckoutStep()           { return RegFlow._REASONS_W_CHECKOUT_STEP.includes(this.reason);            } //Includes makeUp/trials
	get reason_hasCheckoutStep_wContract() { return RegFlow._REASONS_W_CHECKOUT_STEP_W_CONTRACT.includes(this.reason); } //Excludes makeUp/trials
		//-> Need to have checkout step in order to have login etc btns. It's just we don't need contracts
	
	get penaltyFeesWOTaxes_modelField() { return this._model?.select("penaltyFeesWOTaxes") ?? null; }
	async penaltyFeesWOTaxes_update()
	{
		await this._apiCall(RegFlow.CALL_STEPS_CHECKOUT_UPDATE_PENALTY_FEES, {
			penaltyFeesWOTaxes: this._model.select("penaltyFeesWOTaxes").val,
		});
			if (this.isDestroyedOrTimeouting) { return "RegFlow instance invalidated while doing penaltyFeesWOTaxes_update() API call"; }
	}
	
	get asOf_dt_modelField() { return this._model?.select("asOf_dt") ?? null; }
	async asOf_dt_update()
	{
		await this._apiCall(RegFlow.CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_SET_AS_OF_DT, {
			asOf_dt: this._model.select("asOf_dt").val,
			reason:  this._model.select("reason").val, //NOTE: Must pass this, otherwise if we go in trial/makeUp and set it first, we'll get kicked out to normal regs
		});
			if (this.isDestroyedOrTimeouting) { return "RegFlow instance invalidated while doing asOf_dt_update() API call"; }
	}
	
	get initialInstallment_d_modelField() { return this._model?.select("initialInstallment_d") ?? null; }
	async initialInstallment_d_update()
	{
		await this._apiCall(RegFlow.CALL_STEPS_CHECKOUT_SET_INITIAL_INSTALLMENT_D, {
			initialInstallment_d: this._model.select("initialInstallment_d").val,
		});
			if (this.isDestroyedOrTimeouting) { return "RegFlow instance invalidated while doing initialInstallment_d_update() API call"; }
	}
	
	get cartItem_count()
	{
		if (!this.reason || this.reason_isNormal_any)
		{
			let count = null;
			
			if (this._newContract)
			{
				if (!this._newContract.invoice.type) { return count; } //Happens when we're in creation mode and don't know yet whether it's gonna be a presential/virtual/combo
				
				count = RegFlow._cartItem_count_calcNbOfServicesInContract(this._newContract);
				if (count===0) { RegFlow.throwEx(`If we need a new contract, it should have at least 1 service in it`); }
			}
			/*
			When we have a creditNote here and no new contract, means full unreg
			For now we put it -1, but:
				-In a downgrade we could note the nb of things we're removing, but we'd have to compare
				-We could make it 0 (if it makes more sense in UI) and do that cartItem_has checks for !==null instead of !==0
			*/
			else if (this._currentContract?.creditNote)
			{
				count = -RegFlow._cartItem_count_calcNbOfServicesInContract(this._currentContract);
				if (count===0) { RegFlow.throwEx(`If we fully unreg, we should have had at least 1 service remaining in it first`); }
			}
			
			return count;
		}
		else if (this.reason_isMakeUp || this.reason_isTrial)
		{
			const rems = this._eventClientList_occurrenceTypeChanges_x_count("rem").occurrences;
			if (rems) { RegFlow.throwEx(`Not (yet?) supposed to be able to remove prev selections here`); }
			
			const adds = this._eventClientList_occurrenceTypeChanges_x_count("add").occurrences;
			return adds;
		}
		else { RegFlow.throwEx(`Got unhandled reason "${this.reason}" for cart item count`); }
	}
		static _cartItem_count_calcNbOfServicesInContract(xContract)
		{
			let count = 0;
			
			const {presentialOccurrences,presentialSpecialOccurrences,presentialRegFees,virtualDays} = xContract.invoice.services;
			
			if (presentialOccurrences)        { count += presentialOccurrences.details.length;        }
			if (presentialSpecialOccurrences) { count += presentialSpecialOccurrences.details.length; }
			if (virtualDays)                  { count++;                                              }
			//NOTE: We don't count presentialRegFees as 1, otherwise people will freak out
			
			return count;
		}
	get cartItem_has() { return !!this.cartItem_count; } //Allow neg & pos counts, but not 0/NULL
	
	get filters() { return this._filters; }
	get filters_selections_count()
	{
		let filtersWSelections = 0;
		for (const loop_filterName of RegFlowFilters.FILTER_NAMES) { if(this._filters[loop_filterName].selections_has){filtersWSelections++;} }
		return filtersWSelections;
	}
	filters_selections_has() { return this.filters_selections_count>0; }
	
	get session_fk()            { return this._model?.select("session_fk").val ?? null; }
	set session_fk(val)         { this._model.select("session_fk").val=val;             }
	get session_fk_modelField() { return this._model?.select("session_fk")     ?? null; }
	get session_isBeforePriorityReg()
	{
		const session_fk = this.session_fk;
		if (!session_fk) { this.$bREST.throwEx(`Shouldn't get here wo session_fk`); }
		const session = MyApp.sharedLists_getSrc("sessionList").get_byPK(session_fk);
		
		const dt_now_Ymd = this.asOf_dt_modelField?.val ?? MyApp.currentSessionInfo_dt_now_Ymd;
		
		return dt_now_Ymd<session.select("d_priorityReg_from").val;
	}
	
	get franchisee_fk()            { return this._model?.select("franchisee_fk").val ?? null; }
	set franchisee_fk(val)         { this._model.select("franchisee_fk").val=val;             }
	get franchisee_fk_modelField() { return this._model?.select("franchisee_fk")     ?? null; }
	
	get eventClientList_occurrenceTypeChanges() { return this._model ? this._model.select("eventClientList_occurrenceTypeChanges").val : null; }
		//Rets as {events,occurrences} counts
		_eventClientList_occurrenceTypeChanges_x_count(which) //add|rem
		{
			if (!this.eventClientList_occurrenceTypeChanges) { return {events:0, occurrences:0}; }
			
			let count_events      = 0;
			let count_occurrences = 0;
			for (const loop_event_fk in this.eventClientList_occurrenceTypeChanges)
			{
				const loop_eventClient_occurrenceTypeChanges = this.eventClientList_occurrenceTypeChanges[loop_event_fk];
				let   loop_eventClient_counts                = false;
				
				for (const loop_occurrenceIdx in loop_eventClient_occurrenceTypeChanges.occurrenceList)
				{
					const loop_occurrenceTypeChangeInfo = loop_eventClient_occurrenceTypeChanges.occurrenceList[loop_occurrenceIdx];
					if (loop_occurrenceTypeChangeInfo[which]!==null)
					{
						loop_eventClient_counts = true;
						count_occurrences++;
					}
				}
				
				if (loop_eventClient_counts) { count_events++; }
			}
			
			return {events:count_events, occurrences:count_occurrences};
		}
	
	get acceptation_termsAndConditions()    { return this._acceptation_termsAndConditions; }
	set acceptation_termsAndConditions(val) { this._acceptation_termsAndConditions=val;    }
	
	get acceptation_spap()    { return this._acceptation_spap; }
	set acceptation_spap(val) { this._acceptation_spap=val;    }
	
	get acceptation_mustFill() { return RegFlow._REASONS_W_ACCEPTATIONS_TO_FILL.includes(this.reason);    }
	get acceptation_filled()   { return this._acceptation_termsAndConditions && !!this._acceptation_spap; }
	
	set paymentMethod(val)
	{
		if (val===RegFlow.PAYMENT_METHOD_EXTERNAL && !this.paymentMethod_canChooseExternal) { RegFlow.throwEx(`Payment method must be Moneris`); } //IMPORTANT: Don't end up having client being able to skip Moneris
		
		this.creditCardInfo_clear();
		this._paymentMethod = val;
	}
	get paymentMethod()                    { return this._paymentMethod;                                           }
	get paymentMethod_isUndefined()        { return this._paymentMethod===null;                                    }
	get paymentMethod_isMoneris_new()      { return this._paymentMethod===RegFlow.PAYMENT_METHOD_MONERIS_NEW;      }
	get paymentMethod_isMoneris_existing() { return this._paymentMethod===RegFlow.PAYMENT_METHOD_MONERIS_EXISTING; }
	get paymentMethod_isExternal()         { return this._paymentMethod===RegFlow.PAYMENT_METHOD_EXTERNAL;         }
	get paymentMethod_canChooseExternal()
	{
		if (!RegFlow.ALLOW_PAYMENT_METHOD_EXTERNAL || !RegFlow.ALLOW_PAYMENT_METHOD_EXTERNAL_SESSIONS.includes(this.session_fk)) { return false; }
		
		const paymentType = this.finalContract_balanceBeforePayments_type; //payment|refund|none
		if (paymentType==="none") { return false; }
		
		if (MyApp.perms_both_canChooseRegFlowPaymentMethod_paymentAndRefund) { return true; }
		return paymentType==="refund" && MyApp.perms_both_canChooseRegFlowPaymentMethod_refundOnly;
	}
	
	get creditCardInfo_mustFill()
	{
		if (this.finalContract_installments_calc_amountToPayOrRefundNow_type!=="none") { return true; }
		if (this.initialInstallment_d_modelField.val)                                  { return true; }
		return false;
	}
	get creditCardInfo()    { return this._creditCardInfo; }
	set creditCardInfo(val) { this._creditCardInfo=val;    }
	get creditCardInfo_filled()
	{
		return this._creditCardInfo.cardName!=="" && this._creditCardInfo.cardNumber!=="" && this._creditCardInfo.cardMonth!=="" && this._creditCardInfo.cardYear!=="" && this._creditCardInfo.cardCvv!=="";
	}
	creditCardInfo_clear()
	{
		this._creditCardInfo.cardName   = "";
		this._creditCardInfo.cardNumber = "";
		this._creditCardInfo.cardMonth  = "";
		this._creditCardInfo.cardYear   = "";
		this._creditCardInfo.cardCvv    = "";
	}
	
	get paymentGateway_successful_dashboardRefNumber() { return this._paymentGateway_successful_dashboardRefNumber; }
	get paymentGateway_successful_amount()             { return this._paymentGateway_successful_amount;             }
	
	get ongoingRegFlowUIRouteConflict() { return this._ongoingRegFlowUIRouteConflict; }
	
	get newContract()     { return this._newContract;     }
	get currentContract() { return this._currentContract; }
	get finalContract()   { return this._finalContract;   }
	
	//NOTE: All of the below actually either point on _newContract or _currentContract
		get finalContract_franchisee_name()                         { return this._finalContract?.franchisee?.name                 ?? null; }
		get finalContract_franchisee_corpoShortName()               { return this._finalContract?.franchisee?.corpoShortName       ?? null; }
		get finalContract_franchisee_contactEmail()                 { return this._finalContract?.franchisee?.contactEmail         ?? null; }
		get finalContract_franchisee_phone()                        { return this._finalContract?.franchisee?.phone                ?? null; }
		get finalContract_franchisee_address()                      { return this._finalContract?.franchisee?.address              ?? null; }
		get finalContract_franchisee_configCity()                   { return this._finalContract?.franchisee?.configCity           ?? null; }
		get finalContract_franchisee_postalCode()                   { return this._finalContract?.franchisee?.postalCode           ?? null; }
		get finalContract_franchisee_hc_state()                     { return this._finalContract?.franchisee?.hc_state             ?? null; }
		get finalContract_franchisee_hc_country()                   { return this._finalContract?.franchisee?.hc_country           ?? null; }
		get finalContract_client_email()                            { return this._finalContract?.client?.email                    ?? null; }
		get finalContract_client_firstName()                        { return this._finalContract?.client?.firstName                ?? null; }
		get finalContract_client_lastName()                         { return this._finalContract?.client?.lastName                 ?? null; }
		get finalContract_client_address()                          { return this._finalContract?.client?.address                  ?? null; }
		get finalContract_client_configCity()                       { return this._finalContract?.client?.configCity               ?? null; }
		get finalContract_client_postalCode()                       { return this._finalContract?.client?.postalCode               ?? null; }
		get finalContract_client_hc_state()                         { return this._finalContract?.client?.hc_state                 ?? null; }
		get finalContract_client_hc_country()                       { return this._finalContract?.client?.hc_country               ?? null; }
		get finalContract_client_ccInfo()                           { return this._finalContract?.client?.ccInfo                   ?? null; } //As stored in DB partly, but wo the client_dataKey_perm
		get finalContract_invoice_type()                            { return this._finalContract?.invoice?.type                    ?? null; }
		get finalContract_invoice_status()                          { return this._finalContract?.invoice?.status                  ?? null; }
		get finalContract_invoice_status_isTerminatedForFullUnreg() { return this._finalContract?.invoice?.status===MyApp.consts.clientSessionContract_status.TERMINATED_FOR_FULL_UNREG; }
		get finalContract_invoice_pk()                              { return this._finalContract?.invoice?.pk                      ?? null; }
		get finalContract_invoice_sessionName()                     { return this._finalContract?.invoice?.sessionName             ?? null; }
		get finalContract_invoice_services_dt_from()                { return this._finalContract?.invoice?.services_dt_from        ?? null; }
		get finalContract_invoice_services_dt_to()                  { return this._finalContract?.invoice?.services_dt_to          ?? null; }
		get finalContract_invoice_calc_services_duration()          { return this._finalContract?.invoice?.calc_services_duration  ?? null; }
		get finalContract_invoice_calc_presential_regFees()         { return this._finalContract?.invoice?.calc_presential_regFees ?? null; }
		get finalContract_invoice_calc_services_subTotal()          { return this._finalContract?.invoice?.calc_services_subTotal  ?? null; }
		get finalContract_invoice_taxes()                           { return this._finalContract?.invoice?.taxes                   ?? null; }
		get finalContract_invoice_total()                           { return this._finalContract?.invoice?.total                   ?? null; } //NOTE: consider finalContract_installments_calc_amountToPayOrRefundNow()
		get finalContract_invoice_services()                        { return RegFlow._xContract_invoice_services(this._finalContract?.invoice?.services); }
			//Wrapper, because API rets all possibilities, even if NULL
			static _xContract_invoice_services(services_all_orUndefined) //W keys like presentialOccurrences|presentialSpecialOccurrences|presentialRegFees|virtualDays|balados, but could be NULL
			{
				if (!services_all_orUndefined) { return null; }
				
				const services = [];
				for (const loop_serviceKey in services_all_orUndefined) { if(services_all_orUndefined[loop_serviceKey]!==null){services.push(services_all_orUndefined[loop_serviceKey]);} }
				return services;
			}
		get finalContract_payments_extra_giftCard_amount()   { return this._finalContract?.payments?.extra_giftCard_amount   ?? null; }
		get finalContract_payments_extra_referral_amount()   { return this._finalContract?.payments?.extra_referral_amount   ?? null; }
		get finalContract_payments_calc_contractGrandTotal() { return this._finalContract?.payments?.calc_contractGrandTotal ?? null; } //NOTE: consider finalContract_installments_calc_amountToPayOrRefundNow()
		get finalContract_installments()                     { return this._finalContract?.installments                      ?? null; } //NOTE: consider finalContract_installments_calc_amountToPayOrRefundNow() below
			get finalContract_installments_calc_amountToPayOrRefundNow()
			{
				const installments = this._finalContract?.installments ?? null;
				if (!installments) { return null; }
				
				/*
				NOTES:
					-We should pimp algo so if it's today/past it counts as being now, but server's RouteParser_RegFlow::steps_checkout_set_initialInstallment_d() prevents that,
						so after API call is done, it can't point in the past.
					-Maybe the for() below should check if it's a TYPE_CLIENT_SESSION_CONTRACT_INITIAL_INSTALLMENT one (but maybe unsafe).
						However, server's RouteParser_RegFlow::steps_checkout_complete()'s $contract_clientPayment_now algo also checks against STATUS_OVERDUE
				*/
				if (this.initialInstallment_d_modelField.val) { return null; }
				
				const ongoingStatuses = [MyApp.consts.clientPayment_status.UPCOMING, MyApp.consts.clientPayment_status.OVERDUE];
				for (const loop_installment of installments) { if(ongoingStatuses.includes(loop_installment.status)){return loop_installment.amount;} }
				return null;
			}
				get finalContract_installments_calc_amountToPayOrRefundNow_type() { return RegFlow._getPaymentOrRefundType(this.finalContract_installments_calc_amountToPayOrRefundNow); }
		//For contract "fixed balance" before any dynamic payments we're about to do
		get finalContract_balanceBeforePayments()
		{
			return this.finalContract_invoice_status_isTerminatedForFullUnreg ? this._currentContract.creditNote.total : this._finalContract?.payments?.calc_contractGrandTotal ?? null;
		}
			//Rets payment|refund|none
			get finalContract_balanceBeforePayments_type() { return RegFlow._getPaymentOrRefundType(this.finalContract_balanceBeforePayments); }
			static _getPaymentOrRefundType(formattedVal)
			{
				const total = MyApp.money_unFormat(formattedVal ?? "0.00 $");
				if (!total) { return "none"; } //When NULL or 0$
				return total>0 ? "payment" : "refund";
			}
	//Things we need for current contract
		get currentContract_invoice_type()                            { return this._currentContract?.invoice?.type ?? null; }
		get currentContract_invoice_type_isNormal_presentialOrCombo() { return CONTRACT_TYPES_NORMAL_PRESENTIAL_OR_COMBO.includes(this.currentContract_invoice_type); }
		get currentContract_invoice_type_isNormal_virtualOrCombo()    { return CONTRACT_TYPES_NORMAL_VIRTUAL_OR_COMBO.includes(this.currentContract_invoice_type);    }
		//NOTE: For credit note, no matter we up/downgrade/terminate, the credit note is always in current contract, so new contract never has one
			get currentContract_creditNote_has()                                               { return !!this._currentContract?.creditNote;                                                          }
			get currentContract_creditNote_type()                                              { return this._currentContract?.creditNote?.type                                              ?? null; }
			get currentContract_creditNote_pk()                                                { return this._currentContract?.creditNote?.pk                                                ?? null; }
			get currentContract_creditNote_received_sessionPayments_giftCard_referral_amount() { return this._currentContract?.creditNote?.received_sessionPayments_giftCard_referral_amount ?? null; }
			get currentContract_creditNote_services_usedTotalWTaxes()                          { return this._currentContract?.creditNote?.services_usedTotalWTaxes                          ?? null; }
			get currentContract_creditNote_calc_relatedContracts_doneStuff_totalWTaxes()       { return this._currentContract?.creditNote?.calc_relatedContracts_doneStuff_totalWTaxes       ?? null; }
			get currentContract_creditNote_calc_relatedContracts_doneStuff_services()          { return this._currentContract?.creditNote?.calc_relatedContracts_doneStuff_services          ?? null; }
			get currentContract_creditNote_calc_presentialRegAndSystemFeesWTaxes()             { return this._currentContract?.creditNote?.calc_presentialRegAndSystemFeesWTaxes             ?? null; }
			get currentContract_creditNote_penaltyFeesWTaxes()                                 { return this._currentContract?.creditNote?.penaltyFeesWTaxes                                 ?? null; }
			get currentContract_creditNote_penaltyFeesInfo()                                   { return this._currentContract?.creditNote?.penaltyFeesInfo                                   ?? null; }
			get currentContract_creditNote_penaltyFees_can()                                   { return !!this._currentContract?.creditNote?.penaltyFeesInfo;                                         }
			get currentContract_creditNote_total()                                             { return this._currentContract?.creditNote?.total                                             ?? null; }
			get currentContract_creditNote_services()                                          { return RegFlow._xContract_invoice_services(this._currentContract?.creditNote?.services);             }
	//Things we need for new contract
		get newContract_invoice_services()                { return RegFlow._xContract_invoice_services(this._newContract?.invoice?.services); }
		get newContract_invoice_calc_services_subTotal()  { return this._newContract?.invoice?.calc_services_subTotal  ?? null;               }
		
	
	
	get timeRemaining_secs() { return this._timeRemaining_secs; }
	get timeRemaining_formatted()
	{
		if (this._timeRemaining_secs===null) { return null; }
		
		const mins = Math.floor(this._timeRemaining_secs/60);
		const secs = this._timeRemaining_secs%60;
		return `${mins.toString().padStart(2,'0')}m ${secs.toString().padStart(2,'0')}s`;
	}
		/*
		Called each time we do an API call, to be sure all makes sense
		WARNING: Putting timer here makes all components using the instance to recompute all computed each interval
		*/
		_timeRemaining_checkReSetupInterval()
		{
			if (this.status_isClosed_any) { this._timeRemaining_checkClearInterval(); return; }
			
			const selectionsBookedUntil_dt_YmdHis = this._model.select("selectionsBookedUntil_dt").val;
			this._timeRemaining_selectionsBookedUntil_dt = selectionsBookedUntil_dt_YmdHis ? B_REST_Utils.dt_fromYmdHis(selectionsBookedUntil_dt_YmdHis) : null;
			if (!this._timeRemaining_selectionsBookedUntil_dt) { this._timeRemaining_checkClearInterval(); return; }
			
			this._timeRemaining_update();
			if (this._timeRemaining_secs>0 && !this._timeRemaining_intervalPtr)
			{
				this._timeRemaining_intervalPtr = setInterval(() =>
				{
					if (this.isLoading) { return; } //Prevent hell in _apiCall()
					this._timeRemaining_update();
				}, RegFlow.TIME_REMAINING_INTERVAL_MSECS);
			}
		}
		//Also called in fake destructor, to avoid hell
		_timeRemaining_checkClearInterval()
		{
			if (this._timeRemaining_intervalPtr)
			{
				clearInterval(this._timeRemaining_intervalPtr);
				this._timeRemaining_intervalPtr = null;
			}
			
			this._timeRemaining_secs = null;
		}
			_timeRemaining_update()
			{
				if (this.status_isClosed_any) { this._timeRemaining_checkClearInterval(); return; }
				
				const secondsDiff = -B_REST_Utils.dt_now_secondsDiff(this._timeRemaining_selectionsBookedUntil_dt);
				this._timeRemaining_secs = secondsDiff>0 ? secondsDiff : 0;
				
				if (this._timeRemaining_secs>0)
				{
					if (this._timeRemaining_listener_expiresSoon && this._timeRemaining_secs<=RegFlow.TIME_REMAINING_EXPIRING_SOON_SECS && !this._timeRemaining_flaggedExpiringSoon)
					{
						this._timeRemaining_flaggedExpiringSoon = true;
						this._timeRemaining_listener_expiresSoon();
					}
				}
				else
				{
					this._timeRemaining_checkClearInterval();
					if (this._timeRemaining_listener_expired) { this._timeRemaining_listener_expired(); }
				}
			}
	
	/*
	Either when reg flow is ongoing and we want to stop, or it got timeouted and we want to acknowledge we took too long
	Will drop hash from LS, and nullify _model
	IMPORTANT: Check for isDestroyedOrTimeouting before doing more w the instance
	*/
	async abort()
	{
		return this._apiCall(RegFlow.CALL_ABORT);
	}
		get abort_can() { return !this.status_isClosed_any && this.steps_checkout_canGoToCart; } //For now, otherwise btn appears even when we're just browsing
	/*
	Adds SELECTIONS_BOOKED_UNTIL_DT_DURATION_EXTEND_MINS more mins until expiration
	IMPORTANT: Check for isDestroyedOrTimeouting before doing more w the instance
	*/
	async extend()
	{
		this._timeRemaining_flaggedExpiringSoon = false;
		return this._apiCall(RegFlow.CALL_EXTEND);
	}
	/*
	For now, only when we're in checkout and want to go back to initial step
	IMPORTANT: Check for isDestroyedOrTimeouting before doing more w the instance
	*/
	async navBack()
	{
		if (!this.navBack_can) { RegFlow.throwEx(`Can't nav back now`); }
		return this._apiCall(RegFlow.CALL_STEPS_NAV_BACK);
	}
		get navBack_can() { return !this.status_isClosed_any && this.step_isCheckout; }
	
	/*
	NOTE:
		The following is req, because otherwise, in Step0NewReasonFranchiseeSchedule we give access to session_fk & franchisee_fk model fields,
		so updating the vals wouldn't automatically trigger an API call (vs say putting call in setters)
	IMPORTANT: Check for isDestroyedOrTimeouting before doing more w the instance
	*/
	async steps_newReasonFranchiseeSchedule_changeInfo(changes) //Map of keys to change like session_fk|reason|franchisee_fk|thenCompleteStep
	{
		const responseData = await this._apiCall(RegFlow.CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_CHANGE_INFO, {
			altering_session_fk:    B_REST_Utils.object_hasPropName(changes,"session_fk")    ? changes.session_fk    : false, //False or new vals
			altering_reason:        B_REST_Utils.object_hasPropName(changes,"reason")        ? changes.reason        : false, //False or new vals
			altering_franchisee_fk: B_REST_Utils.object_hasPropName(changes,"franchisee_fk") ? changes.franchisee_fk : false, //False or new vals
			thenCompleteStep:       changes.thenCompleteStep??false,
		});
		
		return true; //Since we get responseData, if we get here it means it always worked
	}
	//IMPORTANT: Check for isDestroyedOrTimeouting before doing more w the instance
	async steps_newReasonFranchiseeSchedule_complete()
	{
		if (!this.steps_newReasonFranchiseeSchedule_complete_can) { RegFlow.throwEx(`Can't complete first step`); }
		
		return this._apiCall(RegFlow.CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_COMPLETE, {});
	}
		/*
		These 2 do roughly the same, except that one just check to complete whole flow wo having to show a checkout page, while the other checks if we need to
		Used to be a complicated algo where it depended on if we have a session, franchisee + diff algo per reason, but now it's taken care of in cartItem_count()
		*/
		get steps_newReasonFranchiseeSchedule_complete_can() { return this.cartItem_has; }
		get steps_checkout_canGoToCart()                     { return this.step_isNewReasonFranchiseeSchedule && this.cartItem_has && this.reason_hasCheckoutStep; }
	//Used from CalendarEventClientView
	async steps_newReasonFranchiseeSchedule_handleCalendarEventHook(which, calendar, event) //Instance of CalendarEvent
	{
		let result = null;
		try
		{
			result = await this._steps_newReasonFranchiseeSchedule_handleCalendarEventHook_inner(which, calendar, event); //Rets true, translated err msg or false for generic err msg
		}
		catch (e) { result=false; } //Say we want to display a generic err msg
		
		switch (result)
		{
			//Redirection; do nothing
			case null: return;
			//Worked
			case true:
				if (this._handleCalendarEventHook_listener) { this._handleCalendarEventHook_listener(which,calendar,event); }
			break;
			//Generic err
			case false:
				MyApp.notifs_error_generic();
			break;
			default:
				//Translated err msg
				if (B_REST_Utils.string_is(result)) { MyApp.notifs_tmp({msg:result,color:"error"}); }
				else { B_REST_Utils.throwEx(`Got unexpected event hook result type`,result); }
			break;
		}
	}
		//Rets Promise w true to proceed w handleCalendarEventHook_listener, translated err msg or false for generic err msg, or NULL to skip because we did a redirection
		async _steps_newReasonFranchiseeSchedule_handleCalendarEventHook_inner(which, calendar, event) //Instance of CalendarEvent
		{
			switch (which)
			{
				case "presential.normal_attendance_set_notifyMiss_andReceiveMakeUpToken":
					MyApp.routes_go_name("calendar_mySchedule");
						/*
						Otherwise, could do the following, if we'd pick the week first
							await calendar.eventActions_mySchedule_client(event);
						*/
					return null;
				//The following always resolve as true, translated err msg or false for generic err msg, or throw
				case "presential.normal_selectFutureOccurrences_try":    return this.steps_newReasonFranchiseeSchedule_selectOccurrences_try(   calendar,event);
				case "presential.normal_selectFutureOccurrences_undo":   return this.steps_newReasonFranchiseeSchedule_selectOccurrences_undo(  calendar,event);
				case "presential.normal_unSelectFutureOccurrences_undo": return this.steps_newReasonFranchiseeSchedule_unSelectOccurrences_undo(calendar,event);
				case "presential.normal_unSelectFutureOccurrences_orRedirectToFAQ":
					if (MyApp.user_isSudoing) { return this.steps_newReasonFranchiseeSchedule_unSelectOccurrences_try(calendar,event); } //Always resolve as true/err msgs, or throw
					else
					{
						MyApp.routes_goBlank_external(MyApp.wpUrl_faq);
						return null;
					}
				//The following always resolve as true, translated err msg or false for generic err msg, or throw
				case "trial.trial_selectOccurrence_try":    return this.steps_newReasonFranchiseeSchedule_selectOccurrences_try( calendar,event);
				case "trial.trial_selectOccurrence_undo":   return this.steps_newReasonFranchiseeSchedule_selectOccurrences_undo(calendar,event);
				case "makeUp.makeUp_selectOccurrence_try":  return this.steps_newReasonFranchiseeSchedule_selectOccurrences_try( calendar,event);
				case "makeUp.makeUp_selectOccurrence_undo": return this.steps_newReasonFranchiseeSchedule_selectOccurrences_undo(calendar,event);
				/*
				NOTE: We used to have the following cases too:
					case "presential.logIn": case "makeUp.logIn": case "trial.logIn": MyApp.routes_go_landpage({}, {thenReg:which}); return null;
					case "presential.switchPurpose_makeUp":                           return this.steps_newReasonFranchiseeSchedule_changeInfo({reason:REASONS.MAKEUP,thenCompleteStep:false}); Always resolve as true, or throw
					case "presential.switchPurpose_trial":                            return this.steps_newReasonFranchiseeSchedule_changeInfo({reason:REASONS.TRIAL, thenCompleteStep:false}); Always resolve as true, or throw
				*/
				default: RegFlow.throwEx(`Unknown which "${which}"`);
			}
		}
		/*
		Rets true, translated err msg or generic false
		IMPORTANT: Check for isDestroyedOrTimeouting before doing more w the instance
		*/
		async steps_newReasonFranchiseeSchedule_selectOccurrences_try(   calendar,event) { return this._steps_newReasonFranchiseeSchedule_xSelectOccurrences_x(calendar,event,RegFlow.CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_SELECT_OCCURRENCES_TRY,    -1); }
		async steps_newReasonFranchiseeSchedule_selectOccurrences_undo(  calendar,event) { return this._steps_newReasonFranchiseeSchedule_xSelectOccurrences_x(calendar,event,RegFlow.CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_SELECT_OCCURRENCES_UNDO ,  +1); }
		async steps_newReasonFranchiseeSchedule_unSelectOccurrences_try( calendar,event) { return this._steps_newReasonFranchiseeSchedule_xSelectOccurrences_x(calendar,event,RegFlow.CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_UN_SELECT_OCCURRENCES_TRY, +1); }
		async steps_newReasonFranchiseeSchedule_unSelectOccurrences_undo(calendar,event) { return this._steps_newReasonFranchiseeSchedule_xSelectOccurrences_x(calendar,event,RegFlow.CALL_STEPS_NEW_REASON_FRANCHISEE_SCHEDULE_UN_SELECT_OCCURRENCES_UNDO,-1); }
			//NOTE: We need ifSuccessful_deltaPlaceCount, because for unselect calls, they're not applied yet to DB until we complete the reg flow, so we must fake the apparent places remaining
			async _steps_newReasonFranchiseeSchedule_xSelectOccurrences_x(calendar, event, which, ifSuccessful_deltaPlaceCount)
			{
				B_REST_Utils.instance_isOfClass_assert(Calendar,      calendar);
				B_REST_Utils.instance_isOfClass_assert(CalendarEvent, event);
				
				const responseData = await this._apiCall(which, {
					event_pk:      event.pk,
					occurrenceIdx: event.occurrenceIdx, //Only set if PURPOSE_REG_FLOW_MAKEUP or PURPOSE_REG_FLOW_TRIAL
				});
					if (this.isDestroyedOrTimeouting) { return "RegFlow instance invalidated while doing _steps_newReasonFranchiseeSchedule_xSelectOccurrences_x() API call"; }
				
				const {
					could,
					translatedErrorMsg, //NULL most of the time, even when couldn't. For now only filled in server's Model_RegFlow::steps_newReasonFranchiseeSchedule_unSelectOccurrences_try()
					calendarEventInfo: {
						occurrenceTypeChanges: calendarEventInfo_occurrenceTypeChanges, //Filled only if it changed. Check server's Struct_EventClient_OccurrenceTypeChanges
						regFlow_actionState:   calendarEventInfo_regFlow_actionState,   //Filled only if it changed. NOTE: Could change even if could=false, ex because of timing
					},
				} = responseData.extraData;
				
				if (calendarEventInfo_regFlow_actionState) { event.regFlow_actionState=calendarEventInfo_regFlow_actionState; }
				
				if (could)
				{
					event.placeCounts[calendar.regFlowPlaceCountKeyName].remaining += ifSuccessful_deltaPlaceCount;
					
					if (this._steps_newReasonFranchiseeSchedule_listener_occurrenceTypeChanges) { this._steps_newReasonFranchiseeSchedule_listener_occurrenceTypeChanges(event.pk,calendarEventInfo_occurrenceTypeChanges); }
					
					return true;
				}
				else
				{
					if (event.regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_FULL) { event.placeCounts[calendar.regFlowPlaceCountKeyName].remaining=0; }
					
					return translatedErrorMsg ?? false; //Ret translated err msg or false for generic err msg
				}
			}
		
	/*
	No matter we have to pay/refund or not, this will confirm selection changes etc and we'll then be redirected to completed page
	WARNING: If Moneris fucked, won't throw, and contain a translatedErrorMsg prop instead
	IMPORTANT: Check for isDestroyedOrTimeouting before doing more w the instance
	*/
	async steps_checkout_complete()
	{
		if (!this.steps_checkout_complete_can) { RegFlow.throwEx(`Can't complete checkout`); }
		
		const postData = {};
		
		if (this.paymentMethod_isMoneris_new)
		{
			postData.cc = {
				ownerName: this._creditCardInfo.cardName,
				number:    this._creditCardInfo.cardNumber,
				expMonth:  this._creditCardInfo.cardMonth,
				expYear:   this._creditCardInfo.cardYear,
				cvv:       this._creditCardInfo.cardCvv,
			};
		}
		
		postData.paymentMethod = this._paymentMethod; //WARNING: Check paymentMethod setter docs about why this should sometimes stay NULL
		
		const responseData = await this._apiCall(RegFlow.CALL_STEPS_CHECKOUT_COMPLETE, postData);
			if (this.isDestroyedOrTimeouting) { return null; }
			
		//Can be NULL even if successful, if we had no payment to do. WARNING: If Moneris fucked, won't throw, and contain the translatedErrorMsg prop instead
		const translatedErrorMsg       = responseData.extraData?.translatedErrorMsg ?? null;
		const extraData_paymentGateway = responseData.extraData?.paymentGateway     ?? null;
		
		if (translatedErrorMsg)
		{
			MyApp.notifs_tmp({msg:translatedErrorMsg,color:"error"});
			return false; //NOTE: In some err cases we can still get a failed transaction number so we could refactor to have a paymentGateway_failure_dashboardRefNumber
		}
		
		if (extraData_paymentGateway)
		{
			this._paymentGateway_successful_dashboardRefNumber = extraData_paymentGateway.dashboardRefNumber;
			this._paymentGateway_successful_amount             = extraData_paymentGateway.amount;
		}
		
		return responseData;
	}
		get steps_checkout_complete_can()
		{
			if (this.acceptation_mustFill && !this.acceptation_filled)                     { return false;                      }
			if (this.finalContract_installments_calc_amountToPayOrRefundNow_type==="none") { return true;                       } //Ex 0$, or makeUp/trial
			if (this.paymentMethod_isUndefined)                                            { return false;                      }
			if (this.paymentMethod_isExternal || this.paymentMethod_isMoneris_existing)    { return true;                       }
			if (this.paymentMethod_isMoneris_new)                                          { return this.creditCardInfo_filled; }
			
			RegFlow.throwEx(`Got unhandled checkout case`);
		}
	
	
	
	/*
	We might not have a idUser yet (so no access token), so we must rely on hashes to point to our Model_RegFlow PK in API calls
	In server, will be available in RouteParser_OutdoorFitness_base::requestHeaders_regFlow_fk, especially for RouteParser_RegFlow & RouteParser_Calendar
	*/
	static apiCalls_injectRegFlowHash(request, regFlow)
	{
		B_REST_Utils.instance_isOfClass_assert(RegFlow, regFlow);
		RegFlow._apiCalls_injectRegFlowHash(request, regFlow.hash);
	}
		static _apiCalls_injectRegFlowHash(request, hash)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Requests.base, request);
			request.extraHeaders_add(RegFlow.REQUEST_HEADERS_PK_HASH, hash);
		}
	
	//If call makes it that we close the reg flow (normally or not), will drop hash from LS and nullify model
	async _apiCall(which, postDataOrNULL={}) //Setting NULL = GET
	{
		this._isLoading = true;
		
		try
		{
			const MethodName = postDataOrNULL===null ? MyApp.GET : MyApp.POST;
			const request    = new MethodName(`regFlow/${which}`);
			request.needsAccessToken = false;
			if (postDataOrNULL!==null) { request.data=postDataOrNULL; }
			
			//Check if we have something in LS
			if (RegFlow.hash_ls_has) { RegFlow._apiCalls_injectRegFlowHash(request,RegFlow.hash_ls_get()); }
			
			const response = await MyApp.call(request);
			const responseData = response.data;
			
			//NOTE: It's possible that we started w PK A and now we're PK B because of timeouts, or that user completely quit, so always update model & LS
			if (responseData.regFlow)
			{
				this._model.fromObj(responseData.regFlow);
				RegFlow.hash_ls_set(this.hash);
				
				if (which===RegFlow.CALL_BOOT && this.status_isTimeouting)
				{
					if (this._timeRemaining_listener_expired) { this._timeRemaining_listener_expired(); }
				}
				else { this._timeRemaining_checkReSetupInterval(); }
				
				//NOTE: Sometimes that node isn't ret, because there were no changes to evaluated contracts
				const responseDataContracts = responseData.clientSessionContract_userFriendly;
				if (responseDataContracts)
				{
					this._newContract     = responseDataContracts.new     ?? null;
					this._currentContract = responseDataContracts.current ?? null;
					this._finalContract   = responseDataContracts.new     ?? responseDataContracts.current ?? null;
				}
			}
			//If we get here, it means we're done w this instance forever and are destructing it
			else
			{
				//IMPORTANT: Here it's OK to call destroy(), but not the other way around; Read RegFlow::destroy() docs
				this.destroy(/*clearLocalStorage*/true);
			}
			
			this._isLoading = false;
			
			return responseData;
		}
		catch (e)
		{
			this._isLoading = false;
			RegFlow.throwEx(`Err happened during "${which}" call`,e); //Rethrow
		}
	}
};
