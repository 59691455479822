<template>
	<br-error-page-base :msg="t_alt('msg')" :leave-text="t_alt('leave')" />
</template>

<script>
	
	import { createErrorPage } from "./BrErrorPageBase.vue";
	
	export default createErrorPage(403);
	
</script>