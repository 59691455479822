<template>
	<br-app-booter text-color="white" progressbar-color="#037cff" :logo="logo" :logo-width="400" logo-style="margin-top:15%;" background-color="#1e1e1e" :background-image="backgroundImage">
		<!--
			NOTE: We can override the default slot like this: Check BrAppBooter.vue for more info
				<template #default="{isSpinning, errorMsg, reboot}">...</template>
		-->
	</br-app-booter>
</template>

<script>
	
	export default {
		data()
		{
			const isNarrow = this.$bREST.uiBreakpoint.name==="xs";
			
			return {
				logo:            require("@/custom/assets/logo-white.svg"),
				backgroundImage: isNarrow ? require("@/custom/assets/splashscreen--vertical--greyscale.jpg") : require("@/custom/assets/splashscreen--horizontal--greyscale.jpg"),
			};
		},
	};
	
</script>

<style> @import './App.css'; </style>