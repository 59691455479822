/*
Check BrFieldDb::_picker_emit() docs for normal use cases, including having a [+] btn to create on the fly, specifying opt parent FK
NOTE: Check note in B_REST_VueApp_base::pickers_getHandle() about pre-filtering todo
*/

import B_REST_Vuetify_PickerDef from "./B_REST_Vuetify_PickerDef.js";
import { B_REST_Utils }         from "../../../../classes";
import B_REST_VueApp_base       from "../../B_REST_VueApp_base.js";



export default class B_REST_Vuetify_PickerHandle
{
	static get NO_SELECTION() { return undefined; }
	
	_def = null;  //Instance of B_REST_Vuetify_PickerDef
	//Stuff defined in the B_REST_Vuetify_PickerDef, but that we can override
	_isModal  = null;  //If we are allowed to close it by clicking out of the popup, or if we must make a choice first
	_maxWidth = null;
	_vBind    = null;  //Stuff to pass as props to the component (Ex if it was a derived of BrGenericListBase, we could want to specify some of {showTitle, fromModelList, fromLoader}
	_vOn      = null;  //Events to pass to the component
	//State
	_isMultiple      = false;                                     //If user wants to have 1 vs N things returned, so either <anything> or [<anything>]
	_selection       = B_REST_Vuetify_PickerHandle.NO_SELECTION;  //Either NO_SELECTION, <anything> or [<anything>]. Can be set prior opening as well, if we want to express default selection / previous selection
	_isPrompting     = false;
	_promiseResolver = null;                                      //Instance of Promise that gets recreated everytime we call open(), and yields the current _selection after. Becomes NULL when completed
	//Accessing component behind the BrPicker
	_component                        = null;  //Once we have called prompt() at least once, points to the allocated <component :is="..." />. Set via component_set() in BrPicker
	_component_mountedPromise         = null;  //Will resolve when BrPicker will say it's ready
	_component_mountedPromiseResolver = null;  //Resolver in the Promise instance
	
	
	
	constructor(def, options={})
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Vuetify_PickerDef,def);
		this._def = def;
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			isModal:    {accept:[Boolean],   default:this._def.default_isModal},
			maxWidth:   {accept:[Number],    default:this._def.default_maxWidth},
			vBind:      {accept:[Object],    default:this._def.default_vBind??{}},
			vOn:        {accept:[Object],    default:this._def.default_vOn  ??{}},
			isMultiple: {accept:[Boolean],   default:false},
			selection:  {accept:[undefined], default:B_REST_Vuetify_PickerHandle.NO_SELECTION},
		}, "Picker handle");
		
		this._isModal    = options.isModal;
		this._maxWidth   = options.maxWidth;
		this._vBind      = options.vBind;
		this._vOn        = options.vOn;
		this._isMultiple = options.isMultiple;
		this._selection  = options.selection;
	}
	
	
	get def() { return this._def; }
	
	get isPrompting() { return this._isPrompting; }
	
	get isModal() { return this._isModal; }
	set isModal(val)
	{
		this._assertNotMutatingWhilePicking();
		this._isModal = val;
	}
	
	get maxWidth() { return this._maxWidth; }
	set maxWidth(val)
	{
		this._assertNotMutatingWhilePicking();
		this._maxWidth = val;
	}
	
	get vBind() { return this._vBind; }
	set vBind(val)
	{
		this._assertNotMutatingWhilePicking();
		this._vBind = val;
	}
	
	get vOn() { return this._vOn; }
	set vOn(val)
	{
		this._assertNotMutatingWhilePicking();
		this._vOn = val;
	}
	
	get isMultiple() { return this._isMultiple; }
	set isMultiple(val)
	{
		if (this._isMultiple===val) { return; }
		
		this._selection  = B_REST_Vuetify_PickerHandle.NO_SELECTION; //Prevent hell
		this._isMultiple = val;
	}
	
	get selection() { return this._selection; }
	set selection(val)
	{
		//Check if we need to make sure that selection makes sense
		if (val!==B_REST_Vuetify_PickerHandle.NO_SELECTION)
		{
			const isArr = B_REST_Utils.array_is(val);
			if (this._isMultiple!==isArr) { B_REST_Utils.throwEx(`Picker selection not making sense against isMultiple requirement`,{picker:this,selection:val}); }
		}
		
		this._selection = val;
	}
	get selection_has() { return this._selection!==B_REST_Vuetify_PickerHandle.NO_SELECTION && (!this._isMultiple||this._selection.length>0); }
	get selection_count()
	{
		if (this._selection===B_REST_Vuetify_PickerHandle.NO_SELECTION) { return 0; }
		return this._isMultiple ? this._selection.length : 1;
	}
	
	_assertNotMutatingWhilePicking() { if(this.isPrompting){B_REST_Utils.throwEx(`Can't mutate picker while it's opened`);} }
	
	
	/*
	When we want to reuse a picker handle, we could decide to change options that were set in the constructor
	Ex resume a previous selection, switch being modal / not modal...
	*/
	updateOptions(options)
	{
		B_REST_Utils.object_assert(options);
		
		//NOTE: We use setters
		if (B_REST_Utils.object_hasPropName(options,"isModal"))    { this.isModal   =options.isModal;    }
		if (B_REST_Utils.object_hasPropName(options,"maxWidth"))   { this.maxWidth  =options.maxWidth;   }
		if (B_REST_Utils.object_hasPropName(options,"vBind"))      { this.vBind     =options.vBind;      }
		if (B_REST_Utils.object_hasPropName(options,"vOn"))        { this.vOn       =options.vOn;        }
		if (B_REST_Utils.object_hasPropName(options,"isMultiple")) { this.isMultiple=options.isMultiple; }
		if (B_REST_Utils.object_hasPropName(options,"selection"))  { this.selection =options.selection;  }
	}
	
	async prompt()
	{
		if (this.isPrompting) { this.cancel(); }
		
		if (!this._component)
		{
			this._component_mountedPromise = new Promise((s,f) =>
			{
				this._component_mountedPromiseResolver = s;
			});
		}
		
		return new Promise((s,f) =>
		{
			this._promiseResolver = s;
			this._isPrompting       = true; //IMPORTANT: Don't remove _isPrompting to figure out against !!this._promiseResolver, as in _set() we want isPrompting to become false before we resolve (check its docs for why)
		});
	}
	
	//Intended to be called only by BrPicker, even when we destroy the picker, so could get NULL
	component_set(component)
	{
		this._component = component;
		if (this._component) { this._component_mountedPromiseResolver(); }
	}
	//Wait for the BrPicker to be mounted and ret a ref to its inner component, which will then be accessible via the getter below
	async component_waitMounted() { return this._component_mountedPromise || B_REST_Utils.throwEx(`Must call prompt() at least once to use this`,this); }
	get component()               { return this._component; }
	
	/*
	Will cause the prompt() promise to terminate, with NO_SELECTION
	Throws if it wasn't opened
	If not reusable, will get auto deleted by app
	*/
	cancel() { this._set(B_REST_Vuetify_PickerHandle.NO_SELECTION); }
	/*
	Depending on isMultiple, should ret as <anything> or [<anything>]
	Will cause the prompt() promise to terminate, with the value of _selection
	Selection remains available later in _selection
	Throws if it wasn't opened
	If not reusable, will get auto deleted by app
	*/
	submit(objOrArr) { this._set(objOrArr); }
		_set(objOrArrOrNoSelection)
		{
			if (!this.isPrompting) { B_REST_Utils.throwEx(`Picker not currently picking`,this); }
			
			this._isPrompting = false; //IMPORTANT: Must happen before we change selection; mainly for BrGenericListBase::pickerHandle watch, to make BrGenericListBase::picker_setup() code easier and prevent event from firing a 2nd time while we're selecting items (since it's a deep watch)
			
			this.selection = objOrArrOrNoSelection; //IMPORTANT: Use setter, for validation
			
			this._promiseResolver(this.selection);
			this._promiseResolver = null;
			
			//Put back selections to nothing, to prevent hell w opening it next time when we reuse
			this.selection = B_REST_Vuetify_PickerHandle.NO_SELECTION;
			
			B_REST_VueApp_base.instance.pickers_checkRelease(this);
		}
};
