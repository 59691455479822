<!--
	NOTE:
		Files are splitted like such, for now:
			/components/calendar/
				Calendar.vue
				CalendarEventClientView.vue
				CalendarEventStaffView.vue
			/routerViews/modules/event/
				EventForm.vue
				MySchedule.vue
				PresentialManagementCalendar.vue
	WARNING:
		For now, this is only used to create / make sense w Model_Event::TYPE_PRESENTIAL
		If we ren this file to something else, ex PresentialEventManagementForm.vue, generator will stop working
-->
<template>
	<br-generic-form-base :derived-component="_self">
		
		<template v-if="calendar" #title>
			<span>{{ title }}</span>
			<v-btn icon @click="_calendar_notifyAction('abort')"><v-icon>mdi-close</v-icon></v-btn>
			<!-- NOTE: In BrGenericFormBase we have a closable prop, but it wouldn't help here -->
		</template>
		
		<template #fields>
			<v-row>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="configProgram_fk" items="configProgramList"                                                      /> </v-col>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="weekday" :items="$bREST.consts_weekdays_asTranslatedItems"                                       /> </v-col>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="t_from"                                                                                          /> </v-col>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="t_to"                                                                                            /> </v-col>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="franchiseePark_fk" picker="franchiseeParkList" :picker-options="pickerOptions_franchiseePark_fk" /> </v-col>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="staff_fk"          picker="staffList"                                                            /> </v-col>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="placeCount_fixed_normal_max"                                                                     /> </v-col>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="isSpecial"          as="checkbox" :disabled="!IS_SPECIAL_ALLOW_SETTING"                          /> </v-col>
				<v-col cols="12" sm="6"> <br-field-db :model="model" field="isVisibleToClients" as="checkbox"                                                                /> </v-col>
				<!-- 🚀↑app>modules>x>XForm.vue>template>fields↑🚀 -->
			</v-row>
			
			<v-alert type="warning" border="left" outlined>{{ t("warnInsufficientMaxCount") }}</v-alert> <v-divider class="my-4" />
			
			<v-alert v-if="model.isNew" type="warning" border="left" outlined>{{ t("warnUnchangeableFieldsAfterCreate") }}</v-alert>
			<template v-else-if="currentOccurrence_has">
				<v-divider class="mb-4" />
				<v-card-title class="justify-center">
					<v-select :value="currentOccurrence.idx" :items="occurrences" :prefix="t('occurrences.occurrenceSelector')" class="flex-grow-0" style="flex-basis:260px;" filled outlined @change="currentOccurrence_load($event,true)" />
				</v-card-title>
				<v-card color="secondary darken-3" :disabled="currentOccurrence.isCanceled.val">
					<v-expand-transition>
						<v-card-text>
							<v-alert v-if="currentOccurrence.isCanceled.val" type="warning" border="left" outlined class="mb-12">{{ t(`occurrences.fields.isCanceled.cantUndoText.${allOccurrences_canceled?"all":"one"}`) }}</v-alert>
								<!-- IMPORTANT: Check server docs at top of Model_Event for why it can't be undone after -->
							<v-row>
								<v-col cols="12" sm="6"> <br-field-db :field="currentOccurrence.placeCount_makeUp_max"           :disabled="currentOccurrence_isCanceled" icon4="mdi-content-copy|white" @click:icon4="currentOccurrence_cloneInfo('placeCount_makeUp_max')" @change="currentOccurrence_apply_placeCount_varying_x_max('makeUp')" /> </v-col>
								<v-col cols="12" sm="6"> <br-field-db :field="currentOccurrence.placeCount_trial_max"            :disabled="currentOccurrence_isCanceled" icon4="mdi-content-copy|white" @click:icon4="currentOccurrence_cloneInfo('placeCount_trial_max')" @change="currentOccurrence_apply_placeCount_varying_x_max('trial')"  /> </v-col>
								<v-col cols="12" sm="6"> <br-field-db :field="currentOccurrence.alt_staff_fk" picker="staffList" :disabled="currentOccurrence_isCanceled" icon4="mdi-content-copy|white" @click:icon4="currentOccurrence_cloneInfo('alt_staff_fk')" @change="currentOccurrence_apply_altInfo()" /> </v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6"> <br-field-db :field="currentOccurrence.alt_meetingPoint" as="textarea" for-lang="fr" :disabled="currentOccurrence_isCanceled" icon4="mdi-content-copy|white" @click:icon4="currentOccurrence_cloneInfo('alt_meetingPoint')" @change="currentOccurrence_apply_altInfo()" /> </v-col>
								<v-col cols="12" sm="6"> <br-field-db :field="currentOccurrence.alt_meetingPoint" as="textarea" for-lang="en" :disabled="currentOccurrence_isCanceled" icon4="mdi-content-copy|white" @click:icon4="currentOccurrence_cloneInfo('alt_meetingPoint')" @change="currentOccurrence_apply_altInfo()" /> </v-col>
							</v-row>
							<v-row v-if="!currentOccurrence.isCanceled.val" class="mb-4">
								<!-- IMPORTANT: Check server docs at top of Model_Event for why it can't be undone after -->
								<v-col cols="12">
									<custom-btn                                 color="error" icon="mdi-tag-check"              @click="currentOccurrence_promptCancel()">{{ t("occurrences.fields.isCanceled.btn")     }}</custom-btn>
									<custom-btn v-if="!allOccurrences_canceled" color="error" icon="mdi-tag-check" class="ml-2" @click="allOccurrences_promptCancel()"   >{{ t("occurrences.fields.isCanceled.btn_all") }}</custom-btn>
								</v-col>
							</v-row>
							<br-prompt :instance="currentOccurrence.confirmPrompt_cloneInfo"  />
							<br-prompt :instance="currentOccurrence.confirmPrompt_isCanceled" />
						</v-card-text>
					</v-expand-transition>
				</v-card>
			</template>
			
		</template>
		
		<!-- 🚀↑app>modules>x>XForm.vue>template>slots↑🚀 -->
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_ModelFields } from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericForm, B_REST_VueApp_CreateCoreMixin, B_REST_Vuetify_Prompt } from "@/bREST/core/implementations/vue";
	import CalendarEvent from "@/custom/components/calendar/CalendarEvent.js";
	
	const IS_SPECIAL_ALLOW_SETTING = false; //Because now don't want franchisees to use this because they're not sure what they want to do w that anymore... However, some are still checked
	
	const EXISTING_AUTO_LOAD_FIRST_OCCURRENCE       = true;
	const OCCURRENCE_CONFIRM_PROMPT_ACTIONS_CANCEL  = null;
	const OCCURRENCE_CONFIRM_PROMPT_ACTIONS_PROCEED = "proceed";
	
	const DEFAULT_PLACE_COUNT_FIXED_NORMAL_MAX = 17;
	
	const PLACE_COUNT_VARYING_X_ENCODING_CAPACITY = 35; //Check CryptoUtils::convert_36_to_0z() for why. If we go above this, will break encoding
	
	
	
	export default {
		name: "eventForm",
		components: {
			/* 🚀↑app>modules>x>XForm.vue>js>components↑🚀 */
		},
		mixins: B_REST_Vuetify_GenericForm.createMixin({
			modelName:          "Event",
			apiBaseUrl:         "/events/",
			showValidationErrs: true,    //🚀❓ If we want red err msgs near the save btn at the bottom of the form ❓🚀
			showSkeletonLoader: true,    //🚀❓ While we load an existing record, do we want a grey "skeleton" overlay ? ❓🚀
		  //autoUpdateInterval: 2000,    //🚀❓ If we want that blurring fields auto save modifs every X msecs ❓🚀
			requiredFields:     "<dbOnly>", //🚀❓ Check server's ModelOptions_base.php docs for "fieldNamePath" possibilities ❓🚀
			todos: [
				//🚀❓ Arr of {isDone,isBug,text} that will appear automatically at the top of the form ❓🚀
			],
			async modelReady()
			{
				if (this.model.isNew)
				{
					if (!this.calendar || !this.event) { this.throwEx(`For new instances, reqs extraData:{calendar,event}, passed down from Calendar.vue`); }
					
					this.model.select("type").val                        = this.$bREST.consts.event_type.PRESENTIAL;
					this.model.select("session_fk").val                  = this.calendar.ifManagement_session_fk;
					this.model.select("franchisee_fk").val               = this.calendar.ifManagement_franchisee_fk;
					this.model.select("configProgram_fk").val            = this.event.configProgram_pk;
					this.model.select("franchiseePark_fk").val           = this.event.park_pk;
					this.model.select("weekday").val                     = this.event.weekday;
					this.model.select("t_from").val                      = this.event.t_from_Hi;
					this.model.select("t_to").val                        = this.event.t_to_Hi;
					this.model.select("placeCount_fixed_normal_max").val = DEFAULT_PLACE_COUNT_FIXED_NORMAL_MAX;
				}
				else
				{
					if (this.model.select("type").val!==this.$bREST.consts.event_type.PRESENTIAL) { this.throwEx(`Can only work w TYPE_PRESENTIAL for now`); }
					
					if (EXISTING_AUTO_LOAD_FIRST_OCCURRENCE) { this.currentOccurrence_load(0,/*savePrevious*/false); }
				}
			},
			async afterLoad(response,models) { }, //🚀❓ When an existing record, a hook where we still have the API B_REST_Response available to pimp the model. Called before modelReady() ❓🚀
			async customValidator()          { }, //🚀❓ Called at the beginning of awaitUnsavedChangesSaved_x() to check if it's ok to save. Can also be called manually. Should put customErrorList.x_add/x_if() here. Check BrGenericFormBase.vue::customErrors_x() docs or how we use it ex in UserForm.vue or MyProfile.vue ❓🚀
			async beforeSave(request,model)       //🚀❓ When we want to save, a hook so we can pimp the API B_REST_Request_x that will be sent to the server ❓🚀
			{
				if (!this.model.isNew)
				{
					if (this.currentOccurrence_has) { this.currentOccurrence_apply_all(); }
				}
			},
			async afterSave(response,model,isSuccess,wasNew) //🚀❓ Like afterLoad(), we just saved and we still have access to the API B_REST_Response, to do extra stuff ❓🚀
			{
				this.currentOccurrence_load(this.currentOccurrence_has?this.currentOccurrence.idx:0, /*savePrevious*/false);
				
				this._calendar_notifyAction("eventForm_saved");
			},
		}),
		data()
		{
			return {
				IS_SPECIAL_ALLOW_SETTING,
				dataSets: {
					/* 🚀↑app>modules>x>XForm.vue>js>dataSets↑🚀 */
				},
				currentOccurrence: null, //As {idx, placeCount_makeUp_max, placeCount_trial_max, alt_staff_fk, alt_meetingPoint, isCanceled, confirmPrompt_cloneInfo, confirmPrompt_isCanceled}, where most are standalone B_REST_ModelField instances, created on demand. Confirm prompt is an instance of B_REST_Vuetify_Prompt
			};
		},
		computed: {
			is_new() { return this.model.isNew; },
			occurrences()
			{
				return this.model.select("occurrences_dates").val.map((loop_occurrenceDate,loop_occurrenceIdx) => ({
					value: loop_occurrenceIdx,
					text:  `${loop_occurrenceIdx+1} - ${loop_occurrenceDate}`,
				}));
			},
			calc_occurrences_total() { return this.model.select("calc_occurrences_total").val; },
			currentOccurrence_has() { return this.currentOccurrence!==null; },
			currentOccurrence_isCanceled() { return this.currentOccurrence_has && this.currentOccurrence.isCanceled.val; },
			//Things only set when we come from Calendar.vue and we do <event-form :extra-data="{...}">, yielding instances of Calendar & CalendarEvent
			calendar() { return this.extraData?.calendar ?? null; },
			event()    { return this.extraData?.event    ?? null; },
			pickerOptions_franchiseePark_fk()
			{
				const parent_pkTag = this.model.select("franchisee_fk").val;
				
				return {
					vBind: {
						parent_modelName: "Franchisee",
						parent_pkTag,
						fromLoader: {
							apiBaseUrl:           "/franchisees/{franchisee}/franchiseeParks",
							apiBaseUrl_path_vars: {franchisee:parent_pkTag},
						},
					},
					vOn: {},
				};
			},
			allOccurrences_canceled() { return this.model.select("occurrences_canceled").val===CalendarEvent.OCCURRENCES_CANCELED_ALL; },
		},
		methods: {
			//IMPORTANT: Check server docs at top of Model_Event for why it can't be undone after
			async allOccurrences_promptCancel()
			{
				this._currentOccurrence_assert_has(); //Shouldn't happen; means that btn was moved outside of v-card for occurrences
				
				const selectedActionOrNull = await this.currentOccurrence.confirmPrompt_isCanceled.show();
				if (selectedActionOrNull!==OCCURRENCE_CONFIRM_PROMPT_ACTIONS_PROCEED) { return; }
				
				this.currentOccurrence.isCanceled.val = true;
				this.model.select("occurrences_canceled").val = CalendarEvent.OCCURRENCES_CANCELED_ALL;
			},
			currentOccurrence_load(occurrenceIdx, savePrevious)
			{
				//If first time
				if (!this.currentOccurrence_has)
				{
					const isNullable               = true;
					let   confirmPrompt_cloneInfo  = null;  //Instance of B_REST_Vuetify_Prompt
					let   confirmPrompt_isCanceled = null;  //Instance of B_REST_Vuetify_Prompt
					
					//Setup clone info confirm prompt
					{
						const locBasePathPrefix_cloneInfo_prompt = `occurrences.cloneInfoPrompt`;
						
						confirmPrompt_cloneInfo = new B_REST_Vuetify_Prompt.Prompt(this.t(`${locBasePathPrefix_cloneInfo_prompt}.title`), /*isModal*/false);
						confirmPrompt_cloneInfo.body  = this.t(`${locBasePathPrefix_cloneInfo_prompt}.body`);
						confirmPrompt_cloneInfo.color = "secondary";
						
						confirmPrompt_cloneInfo.actions = [
							new B_REST_Vuetify_Prompt.Prompt_Action(OCCURRENCE_CONFIRM_PROMPT_ACTIONS_CANCEL, this.t(`${locBasePathPrefix_cloneInfo_prompt}.cancel`), null),
							null,
							new B_REST_Vuetify_Prompt.Prompt_Action(OCCURRENCE_CONFIRM_PROMPT_ACTIONS_PROCEED, this.t(`${locBasePathPrefix_cloneInfo_prompt}.proceed`), "accent"),
						];
					}
					
					//Setup cancel confirm prompt
					{
						const locBasePathPrefix_isCanceled_prompt = `occurrences.fields.isCanceled.prompt`;
						
						confirmPrompt_isCanceled = new B_REST_Vuetify_Prompt.Prompt(this.t(`${locBasePathPrefix_isCanceled_prompt}.title`), /*isModal*/false);
						confirmPrompt_isCanceled.body  = this.t(`${locBasePathPrefix_isCanceled_prompt}.body`);
						confirmPrompt_isCanceled.color = "secondary";
						
						confirmPrompt_isCanceled.actions = [
							new B_REST_Vuetify_Prompt.Prompt_Action(OCCURRENCE_CONFIRM_PROMPT_ACTIONS_CANCEL, this.t(`${locBasePathPrefix_isCanceled_prompt}.cancel`), null),
							null,
							new B_REST_Vuetify_Prompt.Prompt_Action(OCCURRENCE_CONFIRM_PROMPT_ACTIONS_PROCEED, this.t(`${locBasePathPrefix_isCanceled_prompt}.proceed`), "accent"),
						];
					}
					
					const modelFields_locBasePathPrefix = `${this.t_baseLocPath}.occurrences.fields`;
					this.currentOccurrence = {
						idx:                   null,
						placeCount_makeUp_max: B_REST_ModelFields.DB.createStandalone_int(               "placeCount_makeUp_max", {isNullable,locBasePath:`${modelFields_locBasePathPrefix}.placeCount_varying_makeUp_max`,min:0, max:PLACE_COUNT_VARYING_X_ENCODING_CAPACITY}),
						placeCount_trial_max:  B_REST_ModelFields.DB.createStandalone_int(               "placeCount_trial_max",  {isNullable,locBasePath:`${modelFields_locBasePathPrefix}.placeCount_varying_trial_max` ,min:0, max:PLACE_COUNT_VARYING_X_ENCODING_CAPACITY}),
						alt_staff_fk:          B_REST_ModelFields.DB.createStandalone_int(               "alt_staff_fk",          {isNullable,locBasePath:`${modelFields_locBasePathPrefix}.alt_staff`                    ,lookupInfo:{modelName:"Staff",fieldName:"pk"}}),
						alt_meetingPoint:      B_REST_ModelFields.DB.createStandalone_multilingualString("alt_meetingPoint",      {isNullable,locBasePath:`${modelFields_locBasePathPrefix}.alt_meetingPoint`}),
						isCanceled:            B_REST_ModelFields.DB.createStandalone_bool(              "isCanceled",        {isNullable,locBasePath:`${modelFields_locBasePathPrefix}.isCanceled`}),
						confirmPrompt_cloneInfo,
						confirmPrompt_isCanceled,
					};
				}
				
				//Check if we should and got a previous occurrence to store back tmp vals in real model fields
				if (savePrevious && this.currentOccurrence.idx!==null) { this.currentOccurrence_apply_all(); }
				
				this.currentOccurrence.idx = occurrenceIdx;
				
				const occurrences_altInfo = this.model.select("occurrences_altInfo").val;
				const occurrence_altInfo  = occurrences_altInfo?.[this.currentOccurrence.idx] ?? null;
				
				this.currentOccurrence.placeCount_makeUp_max.val = this.model.select("placeCount_varying_makeUp_max").val[this.currentOccurrence.idx]||0;
				this.currentOccurrence.placeCount_trial_max.val  = this.model.select("placeCount_varying_trial_max" ).val[this.currentOccurrence.idx]||0;
				this.currentOccurrence.alt_staff_fk.val          = occurrence_altInfo?.[CalendarEvent.ALT_INFO_KEY_STAFF_FK]      ?? null;
				
				if (occurrence_altInfo?.[CalendarEvent.ALT_INFO_KEY_MEETING_POINT])
				{
					this.currentOccurrence.alt_meetingPoint.val = {...occurrence_altInfo[CalendarEvent.ALT_INFO_KEY_MEETING_POINT]}; //Make a copy of the obj, otherwise in currentOccurrence_apply_all(), serialized_new will always equal serialized_old
				}
				else { this.currentOccurrence.alt_meetingPoint.val=null; }
				
				const occurrences_canceled = this.model.select("occurrences_canceled").val;
				this.currentOccurrence.isCanceled.val = occurrences_canceled===CalendarEvent.OCCURRENCES_CANCELED_ALL || (this.$bREST.utils.array_is(occurrences_canceled)&&occurrences_canceled.includes(this.currentOccurrence.idx));
			},
			_currentOccurrence_assert_has() { if(!this.currentOccurrence_has){this.throwEx(`No current occurrenceIdx`);} },
			//IMPORTANT: Check server docs at top of Model_Event for why it can't be undone after
			async currentOccurrence_promptCancel()
			{
				const selectedActionOrNull = await this.currentOccurrence.confirmPrompt_isCanceled.show();
				if (selectedActionOrNull===OCCURRENCE_CONFIRM_PROMPT_ACTIONS_PROCEED)
				{
					this.currentOccurrence.isCanceled.val = true;
					
					let occurrences_canceled = this.model.select("occurrences_canceled").val;
					if      (occurrences_canceled===null)                                   { occurrences_canceled = [this.currentOccurrence.idx];                              }
					else if (this.$bREST.utils.array_is(occurrences_canceled))              { occurrences_canceled.push(this.currentOccurrence.idx);                            }
					else if (occurrences_canceled===CalendarEvent.OCCURRENCES_CANCELED_ALL) { this.throwEx(`Shouldn't be able to cancel occurrence when all already canceled`); }
					else                                                                    { this.throwEx(`Unexpected occurrences_canceled state`,occurrences_canceled);       }
					
					this.model.select("occurrences_canceled").val = occurrences_canceled;
				}
			},
			//NOTE: Excludes changes to occurrences_canceled
			currentOccurrence_apply_all()
			{
				this.currentOccurrence_apply_placeCount_varying_x_max("makeUp");
				this.currentOccurrence_apply_placeCount_varying_x_max("trial");
				this.currentOccurrence_apply_altInfo();
			},
			currentOccurrence_apply_placeCount_varying_x_max(which)
			{
				this._currentOccurrence_assert_has();
				
				const modelField = this.model.select(`placeCount_varying_${which}_max`);
				const oldCount   = modelField.val[this.currentOccurrence.idx];
				const newCount   = this.currentOccurrence[`placeCount_${which}_max`].val||0;
				
				if (oldCount!==newCount)
				{
					modelField.val[this.currentOccurrence.idx]=newCount;
					modelField.unsavedChanges_flag();
				}
			},
			currentOccurrence_apply_altInfo()
			{
				this._currentOccurrence_assert_has();
				
				const modelField = this.model.select("occurrences_altInfo");
				if (!modelField.val) { modelField.val={}; }
				const occurrences_altInfo = modelField.val;
				
				const occurrence_altInfo        = occurrences_altInfo[this.currentOccurrence.idx] = {};
				const alt_staff_fk              = this.currentOccurrence.alt_staff_fk.val;
				const alt_meetingPoint          = this.currentOccurrence.alt_meetingPoint.val;
				const alt_meetingPoint_isFilled = alt_meetingPoint && (alt_meetingPoint.fr!==null || alt_meetingPoint.en!==null);
				
				if (alt_staff_fk)              { occurrence_altInfo[CalendarEvent.ALT_INFO_KEY_STAFF_FK]     =alt_staff_fk;     }
				if (alt_meetingPoint_isFilled) { occurrence_altInfo[CalendarEvent.ALT_INFO_KEY_MEETING_POINT]=alt_meetingPoint; }
				
				this._altInfo_cleanup(modelField);
			},
				_altInfo_cleanup(modelField)
				{
					const occurrences_altInfo = modelField.val;
					if (!occurrences_altInfo) { return; }
					
					for (const loop_occurrenceIdx in occurrences_altInfo)
					{
						const loop_occurrence_altInfo = occurrences_altInfo[loop_occurrenceIdx];
						
						if (this.$bREST.utils.object_hasPropName(loop_occurrence_altInfo,CalendarEvent.ALT_INFO_KEY_STAFF_FK))
						{
							if (!loop_occurrence_altInfo[CalendarEvent.ALT_INFO_KEY_STAFF_FK]) { this.$bREST.utils.object_removeProp(loop_occurrence_altInfo,CalendarEvent.ALT_INFO_KEY_STAFF_FK); }
						}
						if (this.$bREST.utils.object_hasPropName(loop_occurrence_altInfo,CalendarEvent.ALT_INFO_KEY_MEETING_POINT))
						{
							const loop_alt_meetingPoint = loop_occurrence_altInfo[CalendarEvent.ALT_INFO_KEY_MEETING_POINT];
							if (loop_alt_meetingPoint.fr===null&&loop_alt_meetingPoint.en===null) { this.$bREST.utils.object_removeProp(loop_occurrence_altInfo,CalendarEvent.ALT_INFO_KEY_MEETING_POINT); }
						}
						
						if (this.$bREST.utils.object_isEmpty(loop_occurrence_altInfo)) { this.$bREST.utils.object_removeProp(occurrences_altInfo,loop_occurrenceIdx); }
					}
					
					if (this.$bREST.utils.object_isEmpty(occurrences_altInfo)) { modelField.val=null; }
					modelField.unsavedChanges_flag();
				},
			/*
			Copies the field's val into all occurrences
			WARNING: Agreed that for now we don't validate that it'd cause hell ex w nb of participants already in occurrences, or for when it's canceled or in the past...
			*/
			async currentOccurrence_cloneInfo(which) //One of placeCount_makeUp_max|placeCount_trial_max|alt_staff_fk|alt_meetingPoint
			{
				this._currentOccurrence_assert_has();
				
				const selectedActionOrNull = await this.currentOccurrence.confirmPrompt_cloneInfo.show();
				if (selectedActionOrNull!==OCCURRENCE_CONFIRM_PROMPT_ACTIONS_PROCEED) { return; }
				
				switch (which)
				{
					case "placeCount_makeUp_max":
						{
							const newCount   = this.currentOccurrence.placeCount_makeUp_max.val||0;
							const modelField = this.model.select("placeCount_varying_makeUp_max");
							for (let i=0; i<this.calc_occurrences_total; i++) { modelField.val[i]=newCount; }
							modelField.unsavedChanges_flag();
						}
					break;
					case "placeCount_trial_max":
						{
							const newCount   = this.currentOccurrence.placeCount_trial_max.val||0;
							const modelField = this.model.select("placeCount_varying_trial_max");
							for (let i=0; i<this.calc_occurrences_total; i++) { modelField.val[i]=newCount; }
							modelField.unsavedChanges_flag();
						}
					break;
					case "alt_staff_fk":
						{
							const alt_staff_fk = this.currentOccurrence.alt_staff_fk.val;
							const modelField   = this.model.select("occurrences_altInfo");
							if (!modelField.val) { modelField.val={}; }
							const occurrences_altInfo = modelField.val;
							for (let i=0; i<this.calc_occurrences_total; i++)
							{
								const loop_hasOccurrenceData = this.$bREST.utils.object_hasPropName(occurrences_altInfo,i);
								if (alt_staff_fk)
								{
									if (!loop_hasOccurrenceData) { occurrences_altInfo[i]={}; }
									occurrences_altInfo[i][CalendarEvent.ALT_INFO_KEY_STAFF_FK] = alt_staff_fk;
								}
								else if (loop_hasOccurrenceData) { this.$bREST.utils.object_removeProp(occurrences_altInfo[i],CalendarEvent.ALT_INFO_KEY_STAFF_FK); }
							}
							this._altInfo_cleanup(modelField);
						}
					break;
					case "alt_meetingPoint":
						{
							const alt_meetingPoint          = this.currentOccurrence.alt_meetingPoint.val;
							const alt_meetingPoint_isFilled = alt_meetingPoint && (alt_meetingPoint.fr!==null || alt_meetingPoint.en!==null);
							const modelField                = this.model.select("occurrences_altInfo");
							if (!modelField.val) { modelField.val={}; }
							const occurrences_altInfo = modelField.val;
							for (let i=0; i<this.calc_occurrences_total; i++)
							{
								const loop_hasOccurrenceData = this.$bREST.utils.object_hasPropName(occurrences_altInfo,i);
								if (alt_meetingPoint_isFilled)
								{
									if (!loop_hasOccurrenceData) { occurrences_altInfo[i]={}; }
									occurrences_altInfo[i][CalendarEvent.ALT_INFO_KEY_MEETING_POINT] = alt_meetingPoint;
								}
								else if (loop_hasOccurrenceData) { this.$bREST.utils.object_removeProp(occurrences_altInfo[i],CalendarEvent.ALT_INFO_KEY_MEETING_POINT); }
							}
							this._altInfo_cleanup(modelField);
						}
					break;
					default: this.$bREST.throwEx(`Unknown which "${which}"`);
				}
			},
			//For when it's used within a Calendar.vue
			_calendar_notifyAction(actionName,details=null) { this.$emit("calendar-event-action",{actionName,details}); },
		},
	};
	
</script>