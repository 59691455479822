<template>
	<div>Not used</div>
</template>

<script>
	
	export default {
		
	};
	
</script>