<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<!-- Common things between client & staff -->
				<client-staff-form-common-stuff ref="clientStaffFormCommonStuff" :user-type="$bREST.consts.user_types.STAFF" :model="model" :extra-data="extraData" @change:recoveryEmail="recoveryEmail_change($event)" @change:pwdConfirmation="recalcPwdConfirmationValidation()" @change:recoveryEmailConfirmation="recalcRecoveryEmailConfirmationValidation()" />
			<!-- More staff fields, for management -->
				<template v-if="reason_isModule">
					<v-row v-if="$bREST.roles_isAnyRoleAdmin || !isSelf">
						<v-col cols="12" sm="6"> <br-field-db :model="model" field="coach_since_d" /> </v-col>
						<v-col cols="12" sm="6"> <br-field-db :model="model" field="coach_confidentiality_d" /> </v-col>
						<!-- 🚀↑app>modules>x>XForm.vue>template>fields↑🚀 -->
					</v-row>
					<v-row v-if="!isSelf"><v-col cols="12"> <br-field-db :model="model" field="user.isEnabled" as="checkbox" /> </v-col></v-row>
				</template>
				<!-- Roles - maybe should always be there -->
					<v-card v-if="(reason_isMyProfile||reason_isModule) && roles_canSee" color="secondary">
						<v-card-title>{{ t('roles.title') }}</v-card-title>
						<v-card-text>
							<v-simple-table>
								<thead>
									<tr>
										<th>{{ t('roles.role') }}</th>
										<th>{{ t('roles.franchise') }}</th>
										<th style="width:100px"><v-btn v-if="roles_canAdd" @click="model.select('roles').add(); recalcRolesValidation()" color="success"><v-icon>mdi-plus</v-icon></v-btn></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(loop_role,loop_idx) in roles_visible" :key="`role${loop_idx}`" :class="loop_role.toDelete ? 'red darken-4' : null">
										<td> <br-field-db                                  :model="loop_role" field="type"          no-label :disabled="!loop_role.isNew" @change="roles_onTypeChange(loop_role)"       :items="loop_role.isNew?roles_new_types_itemsOverride:null" /> </td>
										<td> <br-field-db v-if="!roles_isAdmin(loop_role)" :model="loop_role" field="franchisee_fk" no-label :disabled="!loop_role.isNew" @change="roles_onFranchiseeChange(loop_role)"  items="filteredFranchiseeList"                             /> </td>
										<td>
											<template v-if="roles_canDel">
												<v-btn v-if="loop_role.toDelete" @click="loop_role.toDelete_unflag(); recalcRolesValidation()" color="secondary"><v-icon>mdi-delete-off</v-icon></v-btn>
												<v-btn v-else                    @click="loop_role.toDelete_flag();   recalcRolesValidation()" color="error"><v-icon>mdi-delete</v-icon></v-btn>
											</template>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
						<br-prompt v-if="checkMergeDuplicatesPrompt" :instance="checkMergeDuplicatesPrompt" />
					</v-card>
		</template>
		
		<!-- 🚀↑app>modules>x>XForm.vue>template>slots↑🚀 -->
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericForm, B_REST_Vuetify_Prompt } from "@/bREST/core/implementations/vue";
	
	const CHECK_MERGE_DUPLICATES_PROMPT_ACTIONS_CANCEL  = null;
	const CHECK_MERGE_DUPLICATES_PROMPT_ACTIONS_PROCEED = "proceed";
	 
	
	
	export default {
		name: "staffForm",
		components: {
			ClientStaffFormCommonStuff: () => import("@/custom/components/ClientStaffFormCommonStuff.vue"),
			/* 🚀↑app>modules>x>XForm.vue>js>components↑🚀 */
		},
		mixins: B_REST_Vuetify_GenericForm.createMixin({
			modelName:          "Staff",
			apiBaseUrl:         "/staffs/",
			showValidationErrs: true,    //🚀❓ If we want red err msgs near the save btn at the bottom of the form ❓🚀
			showSkeletonLoader: true,    //🚀❓ While we load an existing record, do we want a grey "skeleton" overlay ? ❓🚀
		  //autoUpdateInterval: 2000,    //🚀❓ If we want that blurring fields auto save modifs every X msecs ❓🚀
			requiredFields:     "<all>|user(<dbOnly>|pwd_dbHash|sudoHash)|roles(<dbOnly>|franchisee.<toLabel>)", //🚀❓ Check server's ModelOptions_base.php docs for "fieldNamePath" possibilities ❓🚀
									//IMPORTANT: Server RouteParser_Staffs::PROFILE_ALLOWED_FIELD_NAME_PATHS & frontend's StaffForm.vue::requiredFields must match
			todos: [
				//🚀❓ Arr of {isDone,isBug,text} that will appear automatically at the top of the form ❓🚀
			],
			async modelReady()
			{
				/*
					🚀❓
						When a new record, called right on component mount.
						When an existing record, called after it's done loading & afterLoad() hook done
						Usage ex, where we also adjust for possible cases where we'd get a parent pk
							if (this.model.isNew)
							{
								//For when route is like /citizens/:citizen/animals/:pkTag, or we're from a BrGenericListBase::openFormInVDialog(), etc. Can get better cue parent_modelName or parent_routeName. Check props docs
								if (this.parent_pkTag) { this.model.select("citizen_fk").val=this.parent_pkTag; }
							}
					🚀❓
				*/
			},
			async beforeLoad(request)
			{ 
				if (this.reason_isMyProfile)
				{
					request.reConstruct(request.constructor.METHOD_GET, "staffs/profile");
					request.needsAccessToken = true;
				}
			},
			async afterLoad(response,models) { }, //🚀❓ When an existing record, a hook where we still have the API B_REST_Response available to pimp the model. Called before modelReady() ❓🚀
			//🚀❓ Called at the beginning of awaitUnsavedChangesSaved_x() to check if it's ok to save. Can also be called manually. Should put customErrorList.x_add/x_if() here. Check BrGenericFormBase.vue::customErrors_x() docs or how we use it ex in UserForm.vue or MyProfile.vue ❓🚀
			async customValidator()
			{
				//🚀❓ User name & email unicity validation + email verification security code are taken care of automatically in B_REST_App_base::_commonDefs_setupDescriptorHooks()
				
				this.recalcPwdConfirmationValidation();
				this.recalcRecoveryEmailConfirmationValidation();
				this.recalcRolesValidation();
			},
			//🚀❓ When we want to save, a hook so we can pimp the API B_REST_Request_x that will be sent to the server ❓🚀
			async beforeSave(request,model)
			{ 
				if (this.reason_isAccountCreation)
				{
					request.reConstruct(request.constructor.METHOD_POST, "staffs/profile");
					request.needsAccessToken_setDont();
				}
				else if (this.reason_isMyProfile)
				{
					request.reConstruct(request.constructor.METHOD_PATCH, "staffs/profile");
					request.needsAccessToken = true;
				}
			},
			async afterSave(response,model,isSuccess,wasNew) { }, //🚀❓ Like afterLoad(), we just saved and we still have access to the API B_REST_Response, to do extra stuff ❓🚀
		}),
		data()
		{
			return {
				dataSets: {
					/* 🚀↑app>modules>x>XForm.vue>js>dataSets↑🚀 */
				},
				age: null,
				checkMergeDuplicatesPrompt: null, //Instance of B_REST_Vuetify_Prompt. Check recoveryEmail_change()
			};
		},
		computed: {
			reason_isAccountCreation() { return  this.extraData?.reason==="accountCreation"; },
			reason_isMyProfile()       { return  this.extraData?.reason==="myProfile";       },
			reason_isModule()          { return !this.extraData?.reason;                     },
			isSelf()                   { return this.reason_isAccountCreation || this.reason_isMyProfile || this.model.select("user_fk").val===this.$bREST.user_pk; },
			//For most users, rem the ability to set a "ADMIN" type on new roles (and for existing ones, they're readonly so it's ok)
			roles_new_types_itemsOverride()
			{
				if (this.$bREST.roles_isAnyRoleAdmin) { return null; }
				
				const typeAdminTag = this.$bREST.consts.staffRole_type.ADMIN;
				const enumMembers = this.$bREST.models_field_getEnumMembers("StaffRole","type");
				const items = [];
				for (const loop_enumMember of enumMembers)
				{
					if (loop_enumMember.tag===typeAdminTag) { continue; }
					items.push({key:loop_enumMember.tag, label:loop_enumMember.label});
				}
				return items;
			},
			//When not admin, we must only see roles for franchisees we have perms. Dude might have roles in A,B,C and we have B,C,D, so we should just see B,C"
			roles_visible()
			{
				const allRoles = this.model.select('roles').modelList.models;
				
				if (this.$bREST.roles_isAnyRoleAdmin) { return allRoles; }
				
				const roles_staff_visibleFranchisees = this.$bREST.roles_staff_visibleFranchisees;
				return allRoles.filter(loop_role => loop_role.isNew||roles_staff_visibleFranchisees.includes(loop_role.select("franchisee_fk").val));
			},
			roles_canSee() { return !this.reason_isAccountCreation; }, //Think anyone should be able to see his roles in myProfile too
			roles_canAdd()
			{
				if (!this.reason_isModule) { return false; } //WARNING: If we decide to allow myProfile to be able to add some (ex if admin), then will need to go in server's RouteParser_Staff and add calc_rolesSummary to PROFILE_ALLOWED_FIELD_NAME_PATHS[save], which is a security breach
				return this.$bREST.roles_isAnyRoleAdmin || (this.$bREST.roles_isAnyRoleManagerAndUp&&!this.isSelf); //If we're admin, or not self
			},
			roles_canDel() { return this.roles_canAdd; }, //Keep simple for now
		},
		methods: {
			/*
			Checks if a staff w the specified email exists.
			It's possible that 2 franchisees try to create the same coach (w the same email). Ex:
				We have perms to see staffs in franchisees A & B, and there's a staff #123 test@test.com having roles only in C for now, so we can't see it.
				If we try to create a new test@test.com, we'll get a duplicate key err in DB, so this tells us to redirect to #123 and then apply changes we need (adding roles in A and/or B)
			NOTE: If user says no to the prompt, then upon trying to save, will throw a duplicate key exception
			WARNING:
				For now, if we're in myProfile, we'll close our eyes and not run any validation. It's really important we don't ask person to switch to someone else w same info and hijack
				For now, we allow people to change their email address even after creation, so this func needs to work w existing ones too...
			*/
			async recoveryEmail_change(email)
			{
				if (!this.reason_isModule) { return; }
				if (!email) { return; }
				
				const request  = new this.$bREST.POST("staffs/checkMergeDuplicates");
				request.data_set("email", email);
				
				const response = await this.$bREST.call(request);
				const pkOrFalse = response.data.pk;
				if (!pkOrFalse || pkOrFalse===this.model.pk) { return; }
				
				const locBasePath = `checkMergeDuplicatesPrompt`;
				
				this.checkMergeDuplicatesPrompt       = new B_REST_Vuetify_Prompt.Prompt(this.t(`${locBasePath}.title`), /*isModal*/true);  //Make modal, because it's an important Q
				this.checkMergeDuplicatesPrompt.body  = this.t(`${locBasePath}.body`);
				this.checkMergeDuplicatesPrompt.color = "secondary";
				
				this.checkMergeDuplicatesPrompt.actions = [
					new B_REST_Vuetify_Prompt.Prompt_Action(CHECK_MERGE_DUPLICATES_PROMPT_ACTIONS_CANCEL, this.t(`${locBasePath}.cancel`), null),
					null,
					new B_REST_Vuetify_Prompt.Prompt_Action(CHECK_MERGE_DUPLICATES_PROMPT_ACTIONS_PROCEED, this.t(`${locBasePath}.proceed`), "accent"),
				];
				
				const selectedActionOrNull = await this.checkMergeDuplicatesPrompt.show();
				if (selectedActionOrNull===CHECK_MERGE_DUPLICATES_PROMPT_ACTIONS_PROCEED) { this.$bREST.routes_go_moduleForm_pkTag("staff",pkOrFalse); }
			},
			recalcPwdConfirmationValidation()
			{
				//🚀❓ Calls a func in BrFieldDbConfirmation hosted in common base form, to check if we should register a custom err in the form. Check its docs + BrGenericFormBase::shouldSavingBeEnabled() docs
				this.customErrorList.fast_if(this.$refs.clientStaffFormCommonStuff.pwdConfirmation_checkShouldWarn(), "pwdMismatch");
					//WARNING: $refs aren't reactive, so don't end up using this in some computed - use $emit instead
			},
			recalcRecoveryEmailConfirmationValidation()
			{
				//🚀❓ Calls a func in BrFieldDbConfirmation hosted in common base form, to check if we should register a custom err in the form. Check its docs + BrGenericFormBase::shouldSavingBeEnabled() docs
				this.customErrorList.fast_if(this.$refs.clientStaffFormCommonStuff.recoveryEmailConfirmation_checkShouldWarn(), "recoveryEmailMismatch");
					//WARNING: $refs aren't reactive, so don't end up using this in some computed - use $emit instead
			},
			recalcRolesValidation()
			{
				const rolesModelField = this.model.select("roles");
				
				/*
				Do this, otherwise it's not possible to merge staffs w same email address together while giving it a role in one of our franchisees.
				Ex if we had franchisees A,B,C, how could we decide it should be linked to which of these + as a TYPE_FRANCHISEE_OWNER ? TYPE_COACH ?
				*/
				this.customErrorList.fast_if(this.model.isNew&&!rolesModelField.has, "roles_mustHaveOneOnCreation");
				
				let allRolesProperlyDefined = true;
				for (const loop_role of rolesModelField.subModels)
				{
					const loop_role_type = loop_role.select("type").val;
					if (!loop_role_type) { allRolesProperlyDefined=false;break; }
					
					const loop_role_isAdmin         = loop_role_type===this.$bREST.consts.staffRole_type.ADMIN;
					const loop_role_isFranchiseeSet = loop_role.select("franchisee_fk").isNotEmpty_andValid;
					if (loop_role_isAdmin===loop_role_isFranchiseeSet) { allRolesProperlyDefined=false;break; }
				}
				this.customErrorList.fast_if(!allRolesProperlyDefined, "roles_mustBeProperlyDefined");
			},
			roles_onTypeChange(role)
			{
				if (this.roles_isAdmin(role)) { role.select("franchisee_fk").clear(); }
				this.recalcRolesValidation();
			},
			roles_onFranchiseeChange(role) { this.recalcRolesValidation(); },
			roles_isAdmin(role) { return role.select("type").val===this.$bREST.consts.staffRole_type.ADMIN; },
		},
	};
	
</script>