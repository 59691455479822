
import B_REST_Utils             from "../B_REST_Utils.js";
import B_REST_App_RouteDef_base from "./B_REST_App_RouteDef_base.js";



export default class B_REST_App_RouteInfo_base
{
	_domainNameWPort = null;  //Simply result of B_REST_Utils::url_current_domainName() for now, ex "localhost:8080"
	_fullPath        = null;  //Actual URL, ex "/clients/123?a=456#stuff". WARNING: Can actually be an unsupported URL, but we'll keep it as is. Doesn't include .env file's BASE_URL (ex "/pwa/"). To get it, use fullPath_wBaseUrl()
	_path            = null;  //Full path, but wo QSA & hashtag. WARNING: Doesn't touch last "/", so navigating to "/a/b/c" & "/a/b/c/" will yield diff results
	_depth           = null;  //Where "/" is 0, "/a" & "/a/" is 1, "/a/b" & "/a/b/" is 2, etc
	_routeDef        = null;  //Instance of B_REST_App_RouteDef_base der. Can be NULL too (ex doesn't match w anything, but we still want the path vars etc. Check with isUn/Known())
	_lang            = null;  //Tells it matches for which lang's URL in the routeDef. IMPORTANT: If multiple langs have the same URL, then lang will equal NULL
	_pathVars        = null;  //Ex if def was "/clients/:id", we'd get {id:123}
	_qsa             = null;  //Against the above ex, we'd get {a:456}
	_hashTag         = null;  //We'd get "stuff"
	
	
	
	constructor(fullPath, routeDef=null, pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		if (routeDef) { B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteDef_base,routeDef); }
		
		this._domainNameWPort = B_REST_Utils.url_current_domainName(/*wPort*/true); //Check var docs
		this._fullPath        = fullPath;
		this._path            = B_REST_Utils.url_getInfo(this._fullPath).path; //NOTE: For root URL, "" and "/" both eval to "/"
		this._depth           = (this._path.match(/\/[^/]/g)||[]).length; //Gets the nb of non-last "/"
		this._routeDef        = routeDef;
		this._lang            = lang;
		this._pathVars        = pathVars;
		this._qsa             = qsa;
		this._hashTag         = hashTag!=="" ? hashTag : null;
		
		//Check to convert stuff to ints to reduce hell
		B_REST_App_RouteInfo_base._intifyObj(this._pathVars);
		B_REST_App_RouteInfo_base._intifyObj(this._qsa);
	}
	
	
	get domainNameWPort() { return this._domainNameWPort;              }
	get fullPath()        { return this._fullPath;                     }
	get path()            { return this._path;                         }
	get depth()           { return this._depth;                        }
	get routeDef()        { return this._routeDef;                     }
	get lang()            { return this._lang;                         }
	get pathVars()        { return this._pathVars;                     }
	get qsa()             { return this._qsa;                          }
	get hashTag()         { return this._hashTag;                      }
	get isKnown()         { return !!this._routeDef;                   }
	get isUnknown()       { return !this._routeDef;                    }
	get needsAuth()       { return this._routeDef?.needsAuth ?? false; }
	get isRoot()          { return this._path==="/";                   } //Works whether we get "/" or ""
	
	get fullPath_wBaseUrl() { return `${process.env.BASE_URL}${this._fullPath}`.replace("//","/"); } // We rem double "//" because we join ex "/pwa/" w "/some/path". NOTE: Doesn't contain domainName
	
	static _intifyObj(obj)
	{
		if (!obj) { return; }
		
		for (const loop_key in obj)
		{
			let loop_val = obj[loop_key];
			if (B_REST_Utils.int_is(loop_val)) { obj[loop_key]=parseInt(loop_val); }
		}
	}
	
	_assertRouteDef() { if(!this._routeDef){B_REST_Utils.throwEx(`Can only do this if bound to a route def`,this);} }
	
	/*
	Converts the route info to another instance, keeping same QSA, path vars etc, but in another lang
	Ex:
		/utilisateurs/123?a=1&b=2#someTag -> /users/123?a=1&b=2#someTag
	*/
	toOtherLang_clone(lang)
	{
		this._assertRouteDef();
		return this._routeDef.toRouteInfo(this._pathVars, this._qsa, this._hashTag, lang);
	}
	//Same as toOtherLang_clone(), but instead of creating an obj w extra props, just rets the full path
	toOtherLang_fullPath(lang)
	{
		this._assertRouteDef();
		
		return this._routeDef.toFullPath(this._pathVars, this._qsa, this._hashTag, lang);
	}
	
	toObj()
	{
		const obj = {
			domainNameWPort: this._domainNameWPort,
			fullPath:        this._fullPath,
			routeDefName:    this._routeDef?.name ?? null,
			lang:            this._lang,
			pathVars:        this._pathVars,
			qsa:             this._qsa,
			hashTag:         this._hashTag,
		};
		
		this._abstract_toObj(obj);
		
		return obj;
	}
		_abstract_toObj(obj) { B_REST_Utils.throwEx(`Must override base method`); }
};
