
import B_REST_Utils from "../B_REST_Utils.js";



export default class B_REST_DOMFilePtr
{
	static get TYPE_TAG_IMG()          { return "<img>";         }
	static get TYPE_TAG_CANVAS()       { return "<canvas>";      }
	static get TYPE_TAG_ARRAY_BUFFER() { return "ArrayBuffer";   }
	static get TYPE_TAG_FILE()         { return "File";          } //Ex <input>.files[x]
	static get TYPE_TAG_BLOB()         { return "Blob";          }
	static get TYPE_TAG_B64_DATA_URL() { return "base64DataURL"; } //Ex "data:image/png;base64,a798sgd798asd7ga98s7dg98"
	static get TYPE_TAG_OBJECT_URL()   { return "objectURL";     } //Ex "blob:null/as8df098as9d8f0"
	
	static get BASENAME_WO_EXT_NONAME() { return "file"; }
	
	_typeTag            = null; //Not a file's extension, but to give detail on if it's an <img>, <canvas>, File, Blob etc
	_anything           = null; //Anything we want to use as a file. Currently supporting <img> (HTMLImageElement & Image), <canvas> (HTMLCanvasElement), ArrayBuffer, File, Blob, base64 data URL & object URL
	_baseNameWExt       = null;
	_isRevokedObjectURL = false;
	
	
	constructor(anything, baseNameWExt=null)
	{
		const typeTag = B_REST_DOMFilePtr._typeTag_eval(anything);
		if (!typeTag) { B_REST_Utils.throwEx("Received unsupported file obj. Currently supporting <img> (HTMLImageElement & Image), <canvas> (HTMLCanvasElement), ArrayBuffer, File, Blob, base64 data URL & object URL"); }
		
		if (baseNameWExt===null)
		{
			switch (typeTag)
			{
				case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB:
					baseNameWExt = anything.name;
				break;
				case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL:
					const mime = anything.match(/data:([^;]+)/)[1]; //Ex "data:image/png;base64,..."
					const ext  = B_REST_Utils.files_mimeToExt(mime); //Could ret NULL
					if (ext!==null) { baseNameWExt=`${B_REST_DOMFilePtr.BASENAME_WO_EXT_NONAME}.${ext}`; }
				break;
			}
		}
		
		this._typeTag      = typeTag;
		this._anything     = anything;
		this._baseNameWExt = baseNameWExt;
	}
		static _typeTag_eval(anything)
		{
			if (!anything) { return null; }
			
			if (B_REST_Utils.string_is(anything))
			{
				if (anything.indexOf("blob:")===0)                                 { return B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL;   }
				if (anything.indexOf("data:")===0 && anything.indexOf(";base64,")) { return B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL; }
			}
			else
			{
				if (anything instanceof ArrayBuffer)       { return B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER; }
				if (anything instanceof HTMLCanvasElement) { return B_REST_DOMFilePtr.TYPE_TAG_CANVAS;       }
				if (anything instanceof Image)             { return B_REST_DOMFilePtr.TYPE_TAG_IMG;          } //NOTE: Image and HTMLImageElement constructors aren't the same, but if we do instanceof, it works for both...
				if (anything instanceof File)              { return B_REST_DOMFilePtr.TYPE_TAG_FILE;         }
				if (anything instanceof Blob)              { return B_REST_DOMFilePtr.TYPE_TAG_BLOB;         }
			}
			
			return null;
		}
	
	
	
	/*
	Expects a native instance of FileList, ret from an <input type="file"> or a drag n drop event (check the 2 next methods below)
	Returns a single instance of B_REST_DOMFilePtr, or an arr of it
	Rets NULL if we've got nothing
	*/
	static from_fileList(fileList, expectMultiple)
	{
		B_REST_Utils.instance_isOfClass_assert(FileList, fileList);
		
		if (fileList.length===0) { return null; }
		
		if (expectMultiple) { return [...fileList].map(loop_file => new B_REST_DOMFilePtr(loop_file)); }
		return new B_REST_DOMFilePtr(fileList[0]);
	}
		//Shortcut for from_fileList(), where we decide expectMultiple based on if it's a <input type="file" multiple> or not
		static from_fileInput(input)
		{
			if (!(input instanceof HTMLInputElement) || input.getAttribute("type")!=="file") { B_REST_Utils.throwEx(`Expected an <input type="file">`); }
			
			const expectMultiple = !!input.getAttribute("multiple");
			return B_REST_DOMFilePtr.from_fileList(input.files, expectMultiple);
		}
		/*
		Shortcut for from_fileList(), but for when we release dragged items over something
		Since we have no way to tell if we were supposed to get only one or multiple files, use a param to figure out, and if we get more than supposed, ignore extra files
		Rets NULL when what we dragged wasn't files, but another HTML elem on the page
		*/
		static from_dropEvent(event, expectMultiple)
		{
			B_REST_Utils.instance_isOfClass_assert(DragEvent, event);
			
			return B_REST_DOMFilePtr.from_fileList(event.dataTransfer.files, expectMultiple);
		}
	
	
	
	get typeTag() { return this._typeTag; }
	get typeTag_is_arrayBuffer()   { return this._typeTag===B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER; }
	get typeTag_is_base64DataURL() { return this._typeTag===B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL; }
	get typeTag_is_objectURL()     { return this._typeTag===B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL;   }
	get typeTag_is_canvas()        { return this._typeTag===B_REST_DOMFilePtr.TYPE_TAG_CANVAS;       }
	get typeTag_is_img()           { return this._typeTag===B_REST_DOMFilePtr.TYPE_TAG_IMG;          }
	get typeTag_is_file()          { return this._typeTag===B_REST_DOMFilePtr.TYPE_TAG_FILE;         }
	get typeTag_is_blob()          { return this._typeTag===B_REST_DOMFilePtr.TYPE_TAG_BLOB;         }
	
	get baseNameWExt()    { return this._baseNameWExt; }
	set baseNameWExt(val) { this._baseNameWExt=val;    }
	
	get baseNameWOExt() { return B_REST_Utils.files_baseNameToName(this._baseNameWExt); }
	get ext()           { return B_REST_Utils.files_baseNameToExt(this._baseNameWExt);  }
	
	get isRevokedObjectURL() { return this._isRevokedObjectURL; }
	
	//Can yield NULL
	get mime_from_bestGuess()
	{
		//Cases ordered by speed and accuracy
		switch (this._typeTag)
		{
			//For native File and Blob, we have it in a "type" prop, but it's maybe empty
			case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB:
				if (this._anything.type) { return this._anything.type; }
				//Else figure out by ext
			break;
			//For base64 data URLs, it's in the header, ex "data:image/png;base64,a798sgd798asd7ga98s7dg98"
			case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL:
				const tmp_dataMarker   = this._anything.indexOf(":");
				const tmp_base64Marker = this._anything.indexOf(";");
				if (tmp_dataMarker===-1 || tmp_base64Marker===-1) { B_REST_Utils.throwEx(`Couldn't find mime in base64 string`); }
				return this._anything.substring(tmp_dataMarker+1, tmp_base64Marker);
			//For ArrayBuffer instances, we can't figure (just an arr of 8-32 bytes)
			case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER:
				//Figure out by ext
			break;
			//For the following, we could figure, but it would require lots of transforms
			case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL: case B_REST_DOMFilePtr.TYPE_TAG_CANVAS: case B_REST_DOMFilePtr.TYPE_TAG_IMG:
		 		//Figure out by ext
			break;
			default:
				B_REST_Utils.throwEx(`Unexpected type tag "${this._typeTag}"`);
			break;
		}
		
		//If we get here, it means we must rely on the extension
		{
			const ext = this.ext; //Do this to avoid recalculating the getter
			if (!ext) { return null; }
			
			return B_REST_Utils.files_extToMime(ext);
		}
	}
	
	//Can yield NULL
	get size()
	{
		switch (this._typeTag)
		{
			//For native File and Blob, we have it in a "size" prop
			case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB:
				return this._anything.size;
			//For base64 data URLs, we can get a rough estimate by dividing string length by 1.37 (https://stackoverflow.com/a/52903425/11242888)
			case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL:
				return Math.round(this._anything.length / 1.37);
			//For ArrayBuffer instances, we have it in a "byteLength" prop
			case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER:
				return this._anything.byteLength;
			//For the following, we could figure, but it would require lots of transforms
			case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL: case B_REST_DOMFilePtr.TYPE_TAG_CANVAS: case B_REST_DOMFilePtr.TYPE_TAG_IMG:
				return null;
			default:
				B_REST_Utils.throwEx(`Unexpected type tag "${this._typeTag}"`);
				break;
		}
	}
	get size_humanReadable() { return B_REST_Utils.files_humanReadableSize(this.size??0); }
	
	//Can yield NULL
	get width()
	{
		switch (this._typeTag)
		{
			//Easy cases
			case B_REST_DOMFilePtr.TYPE_TAG_IMG:    return this._anything.naturalWidth;
			case B_REST_DOMFilePtr.TYPE_TAG_CANVAS: return this._anything.width;
			//For all the other cases, we might be able to achieve that by doing lots of transforms
			case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB: case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL: case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER: case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL:
				return null;
			default:
				B_REST_Utils.throwEx(`Unexpected type tag "${this._typeTag}"`);
				break;
		}
	}
	
	//Can yield NULL
	get height()
	{
		switch (this._typeTag)
		{
			//Easy cases
			case B_REST_DOMFilePtr.TYPE_TAG_IMG:    return this._anything.naturalHeight;
			case B_REST_DOMFilePtr.TYPE_TAG_CANVAS: return this._anything.height;
			//For all the other cases, we might be able to achieve that by doing lots of transforms
			case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB: case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL: case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER: case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL:
				return null;
			default:
				B_REST_Utils.throwEx(`Unexpected type tag "${this._typeTag}"`);
				break;
		}
	}
	
	
	//Once used (ex after doing "<img>.src=..."), blob/file objectURL should be freed via this method. if not called, then they'll get freed when the page is killed
	objectURL_revoke()
	{
		if (!this.typeTag_is_objectURL) { B_REST_Utils.throwEx(`Can't revoke objectURL when file data isn't stored as Blob/File's objectURL`); }
		B_REST_Utils.files_objectURL_revoke(this._anything);
		this._isRevokedObjectURL = true;
	}
	_objectURL_assertNotRevoked()
	{
		if (this._isRevokedObjectURL) { B_REST_Utils.throwEx(`Can't use object URL, because it's already revoked`); }
	}
	
	
	/*
	Converts the instance to an object URL first and downloads it
	Might throw if browser has issues with forcing a download click, or if this instance is a revoked object URL
	WARNING: May cause infinite loop if the fake <a> click() bubbles back to the elem that started call_download(), so careful with domContainer
	*/
	async download(baseNameWExt=null, domContainer=null)
	{
		if (!baseNameWExt) { baseNameWExt=this._baseNameWExt || "data"; }
		if (!domContainer) { domContainer=B_REST_Utils.documentBody;    }
		
		const objectURL = await this.to_objectURL(); //Will throw if it's already an object URL and that it's revoked, via _objectURL_assertNotRevoked()
		
		const a = document.createElement("a");
		a.style.display = "none";
		a.href          = objectURL;
		a.download      = baseNameWExt; //If we didn't include this prop, imgs would just open in a new tab without downloading
		//NOTE: Could have to add the following, but doesn't seem necessary: a.target="_blank"
		
		domContainer.appendChild(a);
		a.click(); //WARNING: May cause infinite loop if the fake <a> click() bubbles back to the elem that started call_download(), so careful with domContainer
		a.remove();
		
		if (!this.typeTag_is_objectURL) { B_REST_Utils.files_objectURL_revoke(objectURL); }
	}
	/*
	Same intent as call_download(), but opens the file in a new window
	Works w HTML, images, pdf, etc
	Rets the Window instance
	*/
	async download_inlineNewWindow(baseNameWExtOrWindowTitle=null)
	{
		if (!baseNameWExtOrWindowTitle) { baseNameWExtOrWindowTitle=this._baseNameWExt || "data"; }
		
		const objectURL    = await this.to_objectURL();  //Will throw if it's already an object URL and that it's revoked, via _objectURL_assertNotRevoked()
		const windowHandle = window.open(objectURL);
		if (!this.typeTag_is_objectURL) { B_REST_Utils.files_objectURL_revoke(objectURL); }
		
		windowHandle.onload = () => windowHandle.document.title=baseNameWExtOrWindowTitle;
		
		return windowHandle;
	}
	
	
	/*
	NOTES FOR THE FOLLOWING CONVERSIONS:
		These don't alter this._anything (otherwise we'd lose original data), and only revokeObjectURL for tmp conversions
		File = <input>.files[x]
	*/
		async to_arrayBuffer()
		{
			switch (this._typeTag)
			{
				case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER:
					return this._anything;
				case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL: case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL: case B_REST_DOMFilePtr.TYPE_TAG_CANVAS: case B_REST_DOMFilePtr.TYPE_TAG_IMG: case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB: //No need
				default:
					B_REST_Utils.throwEx(`Conversion from "${this._typeTag}" to TYPE_TAG_ARRAY_BUFFER not supported`);
					break;
			}
		}
		
		async to_base64DataURL()
		{
			switch (this._typeTag)
			{
				case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL:
					return this._anything;
				case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER:
					return B_REST_DOMFilePtr._to_base64DataURL_from_arrayBuffer(this._anything, this.mime_from_bestGuess);
				case B_REST_DOMFilePtr.TYPE_TAG_CANVAS:
					return this._anything.toDataURL(this.mime_from_bestGuess); //NOTE: If MIME not specified, will take "image/png" by default
				case B_REST_DOMFilePtr.TYPE_TAG_IMG: case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL: //WARNING: For objectURL, only possible if it's a objectURL of an img
					const tmp_canvas = await this.to_canvas();
					return tmp_canvas.toDataURL(this.mime_from_bestGuess); //NOTE: If MIME not specified, will take "image/png" by default
				case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB: //File and Blob have the same way of doing
					return B_REST_DOMFilePtr._to_base64DataURL_from_blob(this._anything);
				default:
					B_REST_Utils.throwEx(`Conversion from "${this._typeTag}" to TYPE_TAG_B64_DATA_URL not supported`);
					break;
			}
		}
			static _to_base64DataURL_from_arrayBuffer(arrayBuffer, mime)
			{
				B_REST_Utils.instance_isOfClass_assert(ArrayBuffer, arrayBuffer);
				
				const tmp_arrBytes = [].slice.call(new Uint8Array(arrayBuffer));
				var   tmp_binaryString = "";
				
				// tmp_arrBytes.forEach(loop_byte => {tmp_binaryString+=String.fromCharCode(loop_byte)});
				for (const loop_byte of tmp_arrBytes) { tmp_binaryString+=String.fromCharCode(loop_byte); }
				
				return `data:${mime};base64,${btoa(tmp_binaryString)}`;
			}
			static async _to_base64DataURL_from_blob(blob) //NOTE: Also works with a File, because it extends Blob
			{
				B_REST_Utils.instance_isOfClass_assert(Blob, blob);
				
				return new Promise((resolve,reject) =>
				{
					const tmp_fileReader = new FileReader();
					tmp_fileReader.onloadend = () => resolve(tmp_fileReader.result);
					tmp_fileReader.readAsDataURL(blob);
				});
			}
		
		async to_canvas()
		{
			switch (this._typeTag)
			{
				case B_REST_DOMFilePtr.TYPE_TAG_CANVAS:
					return this._anything;
				case B_REST_DOMFilePtr.TYPE_TAG_IMG:
					return B_REST_DOMFilePtr._to_canvas_fromImg(this._anything);
				case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER: case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL: case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL: case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB:
					return B_REST_DOMFilePtr._to_canvas_fromImg(await this.to_img()); //NOTE: If ojectURL, don't assume we should B_REST_Utils.files_objectURL_revoke() it
				default:
					B_REST_Utils.throwEx(`Conversion from "${this._typeTag}" to TYPE_TAG_CANVAS not supported`);
					break;
			}
		}
			static _to_canvas_fromImg(img)
			{
				const canvas  = document.createElement("canvas");
				const ctx     = canvas.getContext("2d");
				canvas.height = img.naturalHeight;
				canvas.width  = img.naturalWidth;
				ctx.drawImage(img, 0, 0);
				return canvas;
			}
		
		async to_img()
		{
			switch (this._typeTag)
			{
				case B_REST_DOMFilePtr.TYPE_TAG_IMG:
					return this._anything;
				case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER:
					const tmp_objectURL_1 = B_REST_Utils.files_objectURL_create(await this.to_blob());
					return B_REST_DOMFilePtr._to_img_from_base64DataURLOrObjectURL(tmp_objectURL_1, true);
				case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL:
					return B_REST_DOMFilePtr._to_img_from_base64DataURLOrObjectURL(this._anything, false);
				case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL:
					this._objectURL_assertNotRevoked();
					return B_REST_DOMFilePtr._to_img_from_base64DataURLOrObjectURL(this._anything, false);
				case B_REST_DOMFilePtr.TYPE_TAG_CANVAS:
					return new Promise((resolve,reject) =>
					{
						const img = document.createElement("img");
						img.onload = () => resolve(img);
						img.src = this._anything.toDataURL(this.mime_from_bestGuess); //NOTE: If MIME not specified, will take "image/png" by default
					});
				case B_REST_DOMFilePtr.TYPE_TAG_FILE: case B_REST_DOMFilePtr.TYPE_TAG_BLOB:
					const tmp_objectURL_2 = B_REST_Utils.files_objectURL_create(this._anything);
					return B_REST_DOMFilePtr._to_img_from_base64DataURLOrObjectURL(tmp_objectURL_2, true);
				default:
					B_REST_Utils.throwEx(`Conversion from "${this._typeTag}" to TYPE_TAG_IMG not supported`);
					break;
			}
		}
			static async _to_img_from_base64DataURLOrObjectURL(base64DataURLOrObjectURL, revokeObjectURL)
			{
				return new Promise((resolve,reject) =>
				{
					const img = document.createElement("img");
					img.onload = () =>
					{
						if (revokeObjectURL) { B_REST_Utils.files_objectURL_revoke(base64DataURLOrObjectURL); }
						resolve(img);
					};
					img.src = base64DataURLOrObjectURL;
				});
			}
		
		async to_file()
		{
			
			switch (this._typeTag)
			{
				case B_REST_DOMFilePtr.TYPE_TAG_FILE:
					return this._anything;
				case B_REST_DOMFilePtr.TYPE_TAG_BLOB: case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER:
					return new File([this._anything], this.fileName_toUse);
				case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL:
					const {arr:tmp_arrBytes_1, mime:tmp_mime_1} = B_REST_DOMFilePtr._base64DataURL_toU8Arr(this._anything);
					return new File([tmp_arrBytes_1], this.fileName_toUse, {type:tmp_mime_1}); //Or we could use mime_from_bestGuess(), but don't, in case it's not coherent
				case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL: case B_REST_DOMFilePtr.TYPE_TAG_CANVAS: case B_REST_DOMFilePtr.TYPE_TAG_IMG:
					const tmp_base64DataURL = await this.to_base64DataURL();
					const {arr:tmp_arrBytes_2, mime:tmp_mime_2} = B_REST_DOMFilePtr._base64DataURL_toU8Arr(tmp_base64DataURL);
					return new File([tmp_arrBytes_2], this.fileName_toUse, {type:tmp_mime_2}); //Or we could use mime_from_bestGuess(), but don't, in case it's not coherent
				default:
					B_REST_Utils.throwEx(`Conversion from "${this._typeTag}" to TYPE_TAG_FILE not supported`);
					break;
			}
		}
			//Ret as {arr,mime}
			static _base64DataURL_toU8Arr(base64DataURL)
			{
				const tmp_arrBase64Parts = base64DataURL.split(","); //No need to validate parts, because it's already taken care by _typeIs_asTag()
				const tmp_mime           = tmp_arrBase64Parts[0].split(":")[1].split(";")[0];
				const tmp_byteString     = atob(tmp_arrBase64Parts[1]);
				const tmp_arrBytes       = new Uint8Array(tmp_byteString.length);
				
				for (let i=0; i<tmp_byteString.length; i++) { tmp_arrBytes[i]=tmp_byteString.charCodeAt(i); }
				
				return {arr:tmp_arrBytes, mime:tmp_mime};
			}
		
		async to_blob()
		{
			switch (this._typeTag)
			{
				case B_REST_DOMFilePtr.TYPE_TAG_BLOB: case B_REST_DOMFilePtr.TYPE_TAG_FILE: //Apparently, a File extends Blob...
					return this._anything;
				case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL:
					const {arr:tmp_arrBytes_1, mime:tmp_mime_1} = B_REST_DOMFilePtr._base64DataURL_toU8Arr(this._anything);
					return new Blob([tmp_arrBytes_1], {type:tmp_mime_1}) //Or we could use mime_from_bestGuess(), but don't, in case it's not coherent
				case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER:
					return new Blob([this._anything], {type:this.mime_from_bestGuess});
				case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL: case B_REST_DOMFilePtr.TYPE_TAG_CANVAS: case B_REST_DOMFilePtr.TYPE_TAG_IMG:
					const tmp_base64DataURL = await this.to_base64DataURL();
					const {arr:tmp_arrBytes_2, mime:tmp_mime_2} = B_REST_DOMFilePtr._base64DataURL_toU8Arr(tmp_base64DataURL);
					return new Blob([tmp_arrBytes_2], {type:tmp_mime_2}) //Or we could use mime_from_bestGuess(), but don't, in case it's not coherent
				default:
					B_REST_Utils.throwEx(`Conversion from "${this._typeTag}" to TYPE_TAG_BLOB not supported`);
					break;
			}
		}
		
		async to_objectURL()
		{
			switch (this._typeTag)
			{
				case B_REST_DOMFilePtr.TYPE_TAG_OBJECT_URL:
					this._objectURL_assertNotRevoked();
					return this._anything;
				case B_REST_DOMFilePtr.TYPE_TAG_BLOB: case B_REST_DOMFilePtr.TYPE_TAG_FILE:
					return B_REST_Utils.files_objectURL_create(this._anything);
				case B_REST_DOMFilePtr.TYPE_TAG_ARRAY_BUFFER:
					const tmp_file = new File([this._anything], this.fileName_toUse);
					return B_REST_Utils.files_objectURL_create(tmp_file);
				case B_REST_DOMFilePtr.TYPE_TAG_B64_DATA_URL: case B_REST_DOMFilePtr.TYPE_TAG_CANVAS: case B_REST_DOMFilePtr.TYPE_TAG_IMG:
					return B_REST_Utils.files_objectURL_create(await this.to_blob()); //Prefer Blob to File, because File extends Blob anyways
				default:
					B_REST_Utils.throwEx(`Conversion from "${this._typeTag}" to TYPE_TAG_OBJECT_URL not supported`);
					break;
			}
		}
};
