<!--
	This component must be used in an "App.vue"
	Will display a spinner until the B_REST_VueApp_base::boot_await() call is over, or an err msg
	We can override the default slot to display another way
	Usage ex:
		logo:            require("@/custom/assets/logo-icon.svg")
		backgroundImage: require("@/custom/assets/jungle-bg--vertical.png")
		<br-app-booter text-color="white" background-color="#0b3d46" :logo="logo" :logo-width="200" :background-image="backgroundImage" />
-->

<template>
	<div :style="$bREST.globalCSSVars">
		<v-app class="br-app-booter">
			<v-slide-x-transition leave-absolute>
				<!--
					Really important to have leave-absolute, otherwise we'll see a plain bg for a while before the content below appears, which is bad when doing sudoX
					<v-slide-x-transition leave-absolute> and <v-fade-transition leave-absolute> works well
					IMPORTANT: Don't put <v-app> BELOW this, or will cause hell w <v-dialog>. Check BrPrompt.vue for why
				-->
				
				<router-view v-if="isAppUsable" />
					<!--
						NOTES:
							-If we get probs while trying to reload the app after clearing user (ex breaking "switch (user.type)"), we could maybe try to add an if !$bREST.boot_isUnbooting here,
								but now we added B_REST_App_base::user_isLogoutOrSudoXCallLoading to drop <router-view> completely, so it should be OK
					-->
				
				<slot v-else name="default" v-bind="{isSpinning, errorMsg, reboot}">
					<v-container fluid class="fill-height" :style="{backgroundColor,backgroundSize:'cover',backgroundPosition:'top',backgroundImage:'url('+backgroundImage+')'}">
						<div style="min-width:300px; width:calc(100% - 200px);" class="mx-auto fill-height">
							<v-img :src="logo" :width="logoWidth" class="mx-auto br-app-booter--logo" :style="logoStyle" /> 
							<v-progress-linear v-if="isSpinning" style="width:100%" class="mt-6" :color="progressbarColor" indeterminate />
						</div>
						<v-banner v-if="errorMsg" color="error" dark style="position:fixed; bottom:0; left:0; right:0;">
							<v-icon slot="icon" size="36">mdi-wifi-strength-2-alert</v-icon>
							{{ errorMsg }}
							<template #actions> <v-btn text @click.stop.prevent="reboot()">{{ $bREST.t_core("app.booter.reload") }}</v-btn> </template>
						</v-banner>
					</v-container>
				</slot>
			</v-slide-x-transition>
			
			<!-- Core functionalities -->
				<br-right-drawer />
				<br-picker v-for="(loop_picker_handle,loop_index) in $bREST.pickers_handles" :key="loop_index" :picker-handle="loop_picker_handle" />
				<br-toaster-manager :instance="$bREST.toasterManager" />
		</v-app>	
	</div>
</template>

<script>
	
	export default {
		props: {
			//Props used if we don't override the default display. Otherwise we can define the default slot like <template #default="{isSpinning, errorMsg, reboot}">...</template>
			logo:             {type:undefined, default:null}, //Ex pass a const logo=require("@/custom/assets/logo.png")
			logoWidth:        {type:Number,    default:null},
			logoStyle:        {type:undefined, default:null}, //Obj or string
			backgroundImage:  {type:undefined, default:null}, //Ex pass a const backgroundImg=require("@/custom/assets/bg.png")
			backgroundColor:  {type:String,    default:"#FFF"},
			progressbarColor: {type:String,    default:"primary"},
		},
		watch: {
			"$bREST.uiBreakpoint.name"(newVal,oldVal)
			{
				this.$bREST.utils.console_info(`Breakpoint now changed to: ${this.$bREST.uiBreakpoint.name}`,this.$bREST.uiBreakpoint);
			},
		},
		data()
		{
			return {
				errorMsg: null,
			};
		},
		async created()
		{
			//Do this, for between transitions, and also for before bg img is loaded
			{
				const body = window.document.body;
				body.style.backgroundColor = this.backgroundColor;
				body.setAttribute("data-readme-b-rest", "backgroundColor set from BrAppBooter::created() to prevent white flash");
			}
			
			try
			{
				await this.$bREST.boot_await(); //Check B_REST_App_base::boot_await() docs. Also called in B_REST_App_base::instance_init()
			}
			catch (e)
			{
				this.errorMsg = this.$bREST.t_custom("app.booter.error");
			}
		},
		computed: {
			isAppUsable() { return this.$bREST.boot_isBooted && !this.$bREST.boot_isUnbooting && !this.$bREST.user_isLogoutOrSudoXCallLoading; },
			isSpinning()  { return !this.isAppUsable && !this.errorMsg; },
		},
		methods: {
			reboot() { this.$bREST.reboot(); },
		},
	};
	
</script>

<style>
	
	@import '../styles.css';
	
</style>
<style scoped>
	
	.br-app-booter {
		/*
		If ever in mobile (for ex) we get a page that overflows out horiz, ex because of a long span / input,
		when we open nav <v-navigation-drawer>, it will expand out of the screen to fit w content width,
		so we can't access the [x] btn to close the menu anymore. To fix, we need to prevent horizontal scrolling,
		either here or on the root elems of layouts.
		*/
			max-width: 100vw;
			overflow-x: clip; /* NOTE: Don't put hidden, or we'll end up w 2-3 -vertical- scrollbars for some reason */
	}
		.br-app-booter--logo { 
			background: initial; /* Vuetify applies var(--v-dark-base) */
		}
	
</style>