<!--
	NOTE: Works even if we keep the instance NULL from the beginning, so no need to hold an extra v-if like <br-prompt v-if="instance" :instance="instance" />
	WARNING about glitch (that doesn't happen anymore) where prompt doesn't show up when it starts as active, related to BrAppBooter's <v-slide-x-transition>
		NOTE: Doesn't happen anymore, as long as BrAppBooter's transition happens below <v-app>. So fucks w v-slide-x-transition>v-app, but not v-app>v-slide-x-transition
		Prob we used to have:
			BrAppBooter causes hell having 2 <v-app> nodes that must live together for 300ms; one being directly in the component when not ready,
				and another one nested ex in custom MainLayout.vue. Can happen too when we sudoIn/Out
			When a <v-dialog :value="true"> is visible from the beginning, it might never show up and not be found in DOM
			When Vuetify boots, vue.runtime.esm.js calls whenTransitionEnds() on <v-app> multiple times (because of BrAppBooter's transition),
			and since <v-dialog> binds by default to <v-app>, for some reason it ends up deleting the <v-overlay> that the <v-dialog>
			is trying to create (check call to cb() in whenTransitionEnds()), and then dialog won't appear.
			If we check what's ret by getTransitionInfo() in <v-app>'s whenTransitionEnds(), we get a duration of 300ms,
			and if we wait for that duration before showing the dialog -for the first time-, then it works.
			However, if dialog is created long time after the app has booted, then waiting isn't necessary.
			Tried other things to go around, but didn't work:
				Setting :attach to:
					false -> Default; changes nothing
					".v-application" -> Equivalent to false
					".v-application--wrap" -> Changes nothing
					"#someDivInAComponent" -> Shows up wo waiting, but the <v-overlay> isn't there, so nothing works. Ex, VDialog.ts's onClickOutside() doesn't get called, so no animation, no way to close
					this.$el.parentElement -> Equivalent result to "#someDivInAComponent"
					this.$parent.$vnode -> Didn't work; don't remember
					this.$parent -> Didn't work; don't remember
				Setting :internal-activator="true"
				Setting :transition="false" -> Strangely, still fucks if we don't wait 300ms
				Setting :eager="true" -> Worse
				Found out that it's not related to having :persistent or not
				Appropriate duration is 300ms, but taking into account inner MainLayout.vue and such mounted() duration into account we need more - but it's unsure how much,
					even if we migrate B_REST_VueApp_base::brAppBooterHack_lastAppUsable_now() call in it, instead of in BrAppBooter's isAppUsable watch
-->
<template>
	<v-dialog v-if="instance" :value="isActive" :persistent="isPersistent" @input="cancel()" max-width="500px">
		<v-card v-if="instance" :loading="instance.isIntercepting" :disabled="instance.isIntercepting" :color="instance.color" :dark="!!instance.color">
			<template #progress><v-progress-linear color="accent" indeterminate absolute /></template>
			<v-card-title class="text-h5">{{ instance.title }}</v-card-title>
			<v-card-text>
				<div v-for="(loop_line,loop_idx) in bodyLines" :key="loop_idx">{{ loop_line===""?"&nbsp;":loop_line }}</div>
				
				<br-prompt v-if="instance.interceptor_retDialog" :instance="instance.interceptor_retDialog"></br-prompt>
				
			</v-card-text>
			<v-card-actions>
				<v-row justify="end" class="mb-1">
					<template v-for="(loop_action,loop_idx) in instance.actions">
						<v-btn    v-if="loop_action" :key="loop_idx" :color="loop_action.color" :disabled="!loop_action.enabled" text @click.stop.prevent="instance.selectAction(loop_action.tag)">{{ loop_action.label }}</v-btn>
						<v-spacer v-else             :key="loop_idx" />
					</template>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	
	import { B_REST_Vuetify_Prompt } from "./B_REST_Vuetify_Prompt.js";
	
	
	
	export default {
		props: {
			instance: {type:B_REST_Vuetify_Prompt, default:null},
		},
		computed: {
			isActive()     { return this.instance?.isActive;                                 },
			isPersistent() { return this.instance?.isModal || this.instance?.isIntercepting; },
			bodyLines()    { return this.instance?.body?.split("\n") ?? [];                  },
		},
		methods: {
			cancel() { if(this.instance){this.instance.cancel();} },
		},
	};
	
</script>