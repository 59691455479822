<template>
	<div class="d-flex" :class="smallIcons ? 'justify-space-between' : 'justify-end'">
		<v-btn                              icon :small="smallIcons" :dark="dark" :color="cloud_color" style="pointer-events:none;"><v-icon>mdi-cloud-upload-outline</v-icon></v-btn>
		<v-btn v-if="failedRetry_can"       icon :small="smallIcons" :dark="dark" color="error" @click.stop.prevent="item.ifNewPreparingFailed_retry()"><v-icon>mdi-autorenew</v-icon></v-btn>
		<v-btn v-if="delete_canChangeState" icon :small="smallIcons" :dark="dark" @click.stop.prevent="$emit('click:delete')"><v-icon>{{ delete_icon }}</v-icon></v-btn>
		<v-btn v-if="download_can"          icon :small="smallIcons" :dark="dark" @click.stop.prevent="item.download()"><v-icon>mdi-download-outline</v-icon></v-btn>
	</div>
</template>

<script>
	
	import { B_REST_FileControlItem } from "../../../../classes";
	
	export default {
		props: {
			item:       {type:B_REST_FileControlItem, required:true},
			dark:       {type:Boolean,                required:false, default:false},
			smallIcons: {type:Boolean,                required:false, default:false},
		},
		computed: {
			cloud_color()           { return this.item.status_isStored ? "success" : "grey";                                },
			download_can()          { return !this.item.ifStored_toDelete && this.item.canDownload;                         },
			delete_icon()           { return this.item.ifStored_toDelete ? "mdi-delete-off-outline" : "mdi-delete-outline"; },
			delete_canChangeState() { return !this.item.control.isReadOnly && !this.item.status_isNewPreparing;             },
			failedRetry_can()       { return this.item.status_isNewPreparingFailed;                                         },
		},
	};
	
</script>