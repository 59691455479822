
import B_REST_Utils            from "../B_REST_Utils.js";
import { B_REST_Request_base } from "../api/B_REST_Request.js";
import B_REST_App_base         from "../app/B_REST_App_base.js";
import B_REST_Descriptor       from "../descriptors/B_REST_Descriptor.js";
import B_REST_ModelFields      from "./B_REST_ModelFields.js";



export class B_REST_Model_Load_SearchOptions
{
	static get QSA_NULL_TAG()    { return "<null>"; }
	static get PAGING_SIZE_ALL() { return null;     }
	
	_descriptor                = null;  //Instance of B_REST_Descriptor (for filters validation)
	_filters                   = [];    //Arr of B_REST_Model_Load_SearchOptions_Filter_x instances, where names should make sense against the descriptor
	_orderByList               = [];    //Arr of B_REST_Model_Load_SearchOptions_OrderByItem instances. Note that we can sort by ANY fieldNamePath that is a B_REST_FieldDescriptor_DB
	_paging_size               = null;  //Can be PAGING_SIZE_ALL for all, or greater than zero
	_paging_index              = 0;     //Zero-based page. Can't be NULL. Can only go out of bounds when we don't know the nb of records
	_paging_calcFoundRowsCount = false; //If we want the server to calc how many records are there, before paging
	_extraData                 = null;  //Arr or obj passed to calls
	//Stuff available only after doing calls
	_lastCall_nbRecords_all      = null; //Total nb of records in DB, against "static" server-filters (ex permissions limiting data access)
	_lastCall_nbRecords_filtered = null; //Out of those we "can" see, nb after we've applied filters in our load options, before paging
	
	_multilingualStrings_whichLangs = null; //For TYPE_MULTILINGUAL_STRING. Loads all available langs if NULL, otherwise only the specified lang key, if available. WARNING: Should only be done if we plan on using the field val in RO, otherwise if we save we'll lose the other langs that weren't loaded
	
	
	constructor(descriptor)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Descriptor, descriptor);
		
		this._descriptor  = descriptor;
		this._paging_size = B_REST_App_base.instance.defaultPagingSize;
	}
		//Creates an instance, from a common B_REST_Descriptor (by name)
		static commonDefs_make(name)
		{
			const descriptor = B_REST_Descriptor.commonDefs_get(name);
			return new B_REST_Model_Load_SearchOptions(descriptor);
		}
	
	
	
	_throwEx(msg, details=null) { B_REST_Utils.throwEx(`B_REST_Model_Load_SearchOptions<${this._descriptor.name}>: ${msg}`,details); }
	
	
	
	get descriptor() { return this._descriptor; }
	
	
	
	validateAgainstDescriptor_todo()
	{
		B_REST_Utils.console_todo([
			`Maybe we'd like to validate possibilities, against field names & B_REST_Descriptor::customFilters
				and then also validate dot notation through all sub models, with their own filter names defs.
				They'd be as {isDBField (vs custom), name, possibleOps(?)}
			However, it adds a huge overhead, so maybe just let the server crash instead`
		]);
	}
	
	
	
	get multilingualStrings_whichLangs() { return this._multilingualStrings_whichLangs; }
	//WARNING: Limiting to a lang should only be done if we plan on using the field val in RO, otherwise if we save we'll lose the other langs that weren't loaded
	multilingualStrings_isReadonlyModel_limitToLang(which) { this._multilingualStrings_whichLangs=which; } //Can use B_REST_App_base::locale_lang
	
	
	
	//EXTRA DATA RELATED
		get extraData()    { return this._extraData; }
		set extraData(val) { this._extraData=val;    }
	
	
	
	//FILTERS RELATED
		get filters()            { return this._filters;                                          }
		get filters_hasSet()     { return !!this._filters.find(loop_filter=>loop_filter.isSet);   }
		get filters_hasChanges() { return !!this._filters.find(loop_filter=>loop_filter.changed); }
		filters_unflagChanges()
		{
			for (const loop_filter of this._filters) { loop_filter.changed = false; }
		}
		filters_reset()
		{
			for (const loop_filter of this._filters) { loop_filter.reset(); }
		}
		filters_remove_all() { this._filters=[]; }
		//Null if we've got none set
		filters_toObj()
		{
			const obj = [];
			
			for (const loop_filter of this._filters)
			{
				if (loop_filter.isSet) { obj.push(loop_filter.toObj()); }
			}
			
			return obj.length>0 ? obj : null;
		}
		//As "f~firstName~p_like_p~bob|...", or null if we've got none set
		filters_toQSA()
		{
			const filters_qsaParts = [];
			
			for (const loop_filter of this._filters)
			{
				if (loop_filter.isSet) { filters_qsaParts.push(loop_filter.toQSAPart()); }
			}
			
			return filters_qsaParts.length>0 ? filters_qsaParts.join("|") : null;
		}
		/*
		The following allow creating filter objs and then refer to them later again
		Usage exs:
			f_auto("age").valOrArr = "Best guess by field type";
			
			f_isNull("user").on = true;
			
			f_equalOrIN("something").valOrArr = 123;
			f_equalOrIN("something").valOrArr = ["a", 4, null];
			
			f_greaterOrEqualTo("total").numberOrString = 123.45;
			
			f_between("date").x = "2022-01-01";
			f_between("date").y = "2022-01-31";
			
			f_pLikeP("calc_search").string = "@gmail";
		To help use them with <br-field-db> instances, we can pass a B_REST_ModelField_DB ptr as the 2nd param, which will control the filter's state
		WARNINGS:
			-We can't define 2 times the same filter name + op. Use throwIfExists to control whether we reuse or throw
			-For now, filter_equalOrIN() & filter_not_equalOrIN() don't accept single NULLs ([1,2,null] is ok though), since empty fields in UI return NULL
			-No parsing of data is done in filters in frontend, so if we got a date like "2022-01-01T00:00", it'll be sent as such
				For that, we shouldn't send a pwd field to server, as it won't get encoded in frontend
		NOTE: Later, maybe we'd like them to have some other default vals when we allocate them
		*/
			//Access data with .valOrArr
			f_auto(name,throwIfExists=true) { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_AUTO,throwIfExists); }
			//Access data with .on
			f_isNull(name,throwIfExists=true)     { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_NULL,throwIfExists);   }
			f_not_isNull(name,throwIfExists=true) { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_N_NULL,throwIfExists); }
			//Access data with .on
			f_isZeroOrEmptyString(name,throwIfExists=true)     { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_0_EMPTY_STR,throwIfExists);   }
			f_not_isZeroOrEmptyString(name,throwIfExists=true) { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_N_0_EMPTY_STR,throwIfExists); }
			//Access data with .valOrArr
			f_equalOrIN(name,throwIfExists=true)        { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_EQ_IN,throwIfExists);   }
			f_not_equalOrIN(name,throwIfExists=true)    { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_N_EQ_IN,throwIfExists); }
			//Access data with .numberOrString
			f_lowerThan(name,throwIfExists=true)        { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_LT,throwIfExists);    }
			f_lowerOrEqualTo(name,throwIfExists=true)   { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_LT_EQ,throwIfExists); }
			f_greaterThan(name,throwIfExists=true)      { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_GT,throwIfExists);    }
			f_greaterOrEqualTo(name,throwIfExists=true) { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_GT_EQ,throwIfExists); }
			//Access data with .x and .y (both must be filled)
			f_between(name,throwIfExists=true)     { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_BTW,throwIfExists);   }
			f_not_between(name,throwIfExists=true) { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_N_BTW,throwIfExists); }
			//Access data with .string
			f_like(name,throwIfExists=true)       { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_LIKE,throwIfExists);       }
			f_pLikeP(name,throwIfExists=true)     { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_P_LIKE_P,throwIfExists);   }
			f_pLike(name,throwIfExists=true)      { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_P_LIKE,throwIfExists);     }
			f_likeP(name,throwIfExists=true)      { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_LIKE_P,throwIfExists);     }
			f_not_like(name,throwIfExists=true)   { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_N_LIKE,throwIfExists);     }
			f_not_pLikeP(name,throwIfExists=true) { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_N_P_LIKE_P,throwIfExists); }
			f_not_pLike(name,throwIfExists=true)  { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_N_P_LIKE,throwIfExists);   }
			f_not_likeP(name,throwIfExists=true)  { return this._filter_checkDefine(name,B_REST_Model_Load_SearchOptions_Filter_base.OP_N_LIKE_P,throwIfExists);   }
			//Custom alternative; which prop we'll have to use will depend on which of the above cases it yield
			f_specifyOp(name,op,throwIfExists=true) { return this._filter_checkDefine(name,op,throwIfExists); }
				_filter_checkDefine(name, op, throwIfExists)
				{
					B_REST_Utils.console_todo([
						`For now, backend doesn't allow defining the same filter twice, even with diff ops. Possible that we can't easily fix that in backend, as it could have impacts for when we try to set pk filter in load_pk`,
					]);
					
					let filter = this._filters.find(loop_filter => loop_filter.name===name && loop_filter.op===op);
					if (filter)
					{
						if (throwIfExists) { this._throwEx(`Already defined filter "${name}" with op "${op}"`); }
						return filter;
					}
					
					//NOTE: We need to do that, because when we instantiate a new ModelOptions_Load, it doesn't know it's for which model it'll be yet (though we should rethink server code)
					const isCustom = !!this._descriptor.customFilters_find(name, /*throwOnNotFound*/false);
					
					filter = B_REST_Model_Load_SearchOptions_Filter_base.createDerivedFromOp(name, op, isCustom);
					this._filters.push(filter);
					return filter;
				}
	
	
	
	//ORDER BY RELATED
		get orderByList()   { return this._orderByList; }
		orderByList_reset() { this._orderByList = []; }
		orderByList_add(fieldNamePath, isASC=true) { this._orderByList.push(new B_REST_Model_Load_SearchOptions_OrderByItem(fieldNamePath,isASC)); }
		orderByList_toObj()
		{
			if (this._orderByList.length===0) { return null; }
			
			return this._orderByList.map(loop_orderByItem => loop_orderByItem.toObj());
		}
		//As "firstName~ASC|coords.city~ASC"
		orderByList_toQSA()
		{
			return this._orderByList.map(loop_orderBy => loop_orderBy.toQSAPart()).join("|");
		}
	
	
	
	//PAGING RELATED
		get paging_size() { return this._paging_size; }
		set paging_size(val)
		{
			if (val===0) { this._throwEx(`Can't set paging size to 0. NULL is OK though`); }
			this._paging_size = val;
		}
		get paging_size_isAll() { return this._paging_size===B_REST_Model_Load_SearchOptions.PAGING_SIZE_ALL; }
		paging_size_all()       { this._paging_size=B_REST_Model_Load_SearchOptions.PAGING_SIZE_ALL;          }
		
		get paging_index() { return this._paging_index; }
		set paging_index(val)
		{
			if (val===null || val<0) { this._throwEx(`Can't set paging index to NULL or below zero`); }
			
			//Do this check when page count is known and higher than 0
			const pageCount = this.paging_pageCount;
			if (pageCount && val>=pageCount) { this._throwEx(`Paging index must be below ${pageCount}`); }
			
			this._paging_index = val;
		}
		
		get paging_calcFoundRowsCount()    { return this._paging_calcFoundRowsCount; }
		set paging_calcFoundRowsCount(val) { this._paging_calcFoundRowsCount = val;  }
		
		/*
		Do this after an API call, to indicate if we now know (or not) the nb of records in DB
		Will help recalculating the nb of pages there is
		Also, if we were trying to see something out of bounds, will move back paging index
		*/
		paging_lastCall_updateNbRecords(filtered=null, all=null)
		{
			this._lastCall_nbRecords_filtered = filtered;
			this._lastCall_nbRecords_all      = all;
			
			//Check if we have to shift current page idx
			const pageCount = this.paging_pageCount;
			if (pageCount!==null && this._paging_index>=pageCount) { this._paging_index = pageCount===0 ? 0 : pageCount-1; }
		}
		get lastCall_nbRecords_all()      { return this._lastCall_nbRecords_all;      }
		get lastCall_nbRecords_filtered() { return this._lastCall_nbRecords_filtered; }
		
		/*
		If we set the last call to have paging_calcFoundRowsCount=true, then server should ret infos about the nb of records we have (against filters etc),
		If that's the case, use paging_lastCall_updateNbRecords() to update nb of known records (or mark that we don't know anymore)
		Then, it depends on scenarios:
			-Nb of records unknown: NULL
			-No records matching:   0 pages
			-We don't want paging:  1 page
			-Otherwise:             Normal paging count algo
		*/
		get paging_pageCount()
		{
			if (this._lastCall_nbRecords_filtered===null) { return null; }
			if (this._lastCall_nbRecords_filtered===0)    { return 0;    }
			if (this._paging_size===null)                 { return 1;    }
			
			return Math.ceil(this._lastCall_nbRecords_filtered/this._paging_size);
		}
		//Rets NULL if we don't know, otherwise bool
		get paging_isLastPage()
		{
			const pageCount = this.paging_pageCount;
			if (pageCount===null) { return null; }
			
			return this._paging_index+1 >= pageCount; //Do >= to prevent hell in edge cases
		}
		
		/*
		Helpers to move paging
		IMPORTANT:
			-They don't cause auto loading of data; they're just helpers
			-If we don't know the nb of pages we have, paging_last() will throw
			-For paging_next(), will throw depending on if we *know* we're getting too far
		*/
		paging_first() { this._paging_index=0; }
		paging_prev()  { this.paging_index--;  } //Use setter, so if we go too much below it'll throw
		paging_next()  { this.paging_index++;  } //Use setter, so if page count is known and we go too far, it'll throw
		paging_last()
		{
			const pageCount = this.paging_pageCount;
			if (pageCount===null) { this._throwEx(`Page count is unknown, so we can't reach the last page`); }
			
			this._paging_index = pageCount-1;
		}
	
	
	
	/*
	Ret NULL, or only filled props as:
		{
			filters,
			orderByList,
			paging_size,
			paging_index,
			paging_calcFoundRowsCount,
			multilingualStrings_whichLangs,
			extraData
		}
	*/
	toObj()
	{
		const obj = {};
		
		//Filters
		{
			const filters_toObj = this.filters_toObj();
			if (filters_toObj) { obj.filters = filters_toObj; }
		}
		
		//Order bys
		{
			const orderByList_toObj = this.orderByList_toObj();
			if (orderByList_toObj) { obj.orderByList = orderByList_toObj; }
		}
		
		//Paging
		{
			if (this._paging_size!==null)                { obj.paging_size               = this._paging_size;               }
			if (this._paging_index!==null)               { obj.paging_index              = this._paging_index;              }
			if (this._paging_calcFoundRowsCount!==false) { obj.paging_calcFoundRowsCount = this._paging_calcFoundRowsCount; }
		}
		
		//Stuff for TYPE_MULTILINGUAL_STRING
		if (this._multilingualStrings_whichLangs) { obj.multilingualStrings_whichLangs=this._multilingualStrings_whichLangs; }
		
		//Extra data
		if (this._extraData!==null) { obj.extraData = this._extraData; }
		
		return B_REST_Utils.object_isEmpty(obj) ? null : obj;
	}
	
	fromObj(obj)
	{
		obj = B_REST_Utils.object_hasValidStruct_assert(obj, {
			filters:                        {accept:[null,Array],   default:null},
			orderByList:                    {accept:[null,Array],   default:null},
			paging_size:                    {accept:[null,Number],  default:null},
			paging_index:                   {accept:[null,Number],  default:null},
			paging_calcFoundRowsCount:      {accept:[null,Boolean], default:null},
			multilingualStrings_whichLangs: {accept:[null,String],  default:null},
			extraData:                      {accept:[null,Object],  default:null},
		}, "B_REST_Model_Load_SearchOptions::fromObj");
		
		//IMPORTANT: Don't do the following, or if framework bound them to components, they might stop working: filters_remove_all(). Also put throwIfExists=false to reuse if already created
		if (obj.filters)
		{
			for (const loop_filterObj of obj.filters)
			{
				this._filter_checkDefine(loop_filterObj.name,loop_filterObj.op,/*throwIfExists*/false).fromObj(loop_filterObj);
			}
		}
		
		this.orderByList_reset();
		if (obj.orderByList)
		{
			for (const loop_orderByObj of obj.orderByList) { this.orderByList_add(loop_orderByObj.fieldNamePath,loop_orderByObj.isASC); }
		}
		
		this._paging_size                    = obj.paging_size                    ?? B_REST_App_base.instance.defaultPagingSize;
		this._paging_index                   = obj.paging_index                   ?? 0;
		this._paging_calcFoundRowsCount      = obj.paging_calcFoundRowsCount      ?? false;
		this._multilingualStrings_whichLangs = obj.multilingualStrings_whichLangs ?? null;
		this._extraData                      = obj.extraData                      ?? null;
	}
	
	
	
	/*
	If we prefer generating as "?so_fields=...&so_sort=...", instead of as toObj() in for POST data for ex, pass it directly to a B_REST_Request_base's QSA
	WARNING:
		Shouldn't use because:
			-Servers usually limit to 8k chars in URL
			-Causes hell if we want to use special chars like ",|~:[]"
	*/
	toQSA(request)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
		
		//Filters, as "f~firstName~p_like_p~bob|..."
		{
			const filters_qsa = this.filters_toQSA();
			if (filters_qsa) { request.qsa_add("so_filters", filters_qsa); }
		}
		
		//Order bys, as "firstName~ASC|coords.city~ASC"
		{
			const orderByList_qsa = this.orderByList_toQSA();
			if (orderByList_qsa) { request.qsa_add("so_sort",orderByList_qsa); }
		}
		
		if (this._paging_size!==null)             { request.qsa_add("so_pageSize",      this._paging_size);                         }
		if (this._paging_index!==null)            { request.qsa_add("so_pageIndex",     this._paging_index);                        }
		if (this._paging_calcFoundRowsCount)      { request.qsa_add("so_calcFoundRows", 1);                                         }
		if (this._multilingualStrings_whichLangs) { request.qsa_add("so_whichLangs",    this._multilingualStrings_whichLangs);      } //Stuff for TYPE_MULTILINGUAL_STRING
		if (this._extraData!==null)               { request.qsa_add("so_extraData",     B_REST_Utils.json_encode(this._extraData)); }
	}
	
	clone_shallow()
	{
		const cloned = new B_REST_Model_Load_SearchOptions(this._descriptor);
		
		cloned._filters                   = this._filters;
		cloned._orderByList               = this._orderByList;
		cloned._paging_size               = this._paging_size;
		cloned._paging_index              = this._paging_index;
		cloned._paging_calcFoundRowsCount = this._paging_calcFoundRowsCount;
		cloned._extraData                 = this._extraData;
		
		return cloned;
	}
};






	export class B_REST_Model_Load_SearchOptions_Filter_base
	{
		//NOTE: The tags match server's ModelOptions_Load_FilterData::OP_x
		static get OP_AUTO()          { return null;            } // Best guess
		static get OP_NULL()          { return "null";          } // `field` IS NULL
		static get OP_N_NULL()        { return "n_null";        } // `field` IS NOT NULL
		static get OP_0_EMPTY_STR()   { return "0_empty_str";   } // `field` = 0  or `field` = ""
		static get OP_N_0_EMPTY_STR() { return "n_0_empty_str"; } // `field` != 0 or `field` != ""
		static get OP_EQ_IN()         { return "eq_in";         } // `field` = ? or `field` IN (x,y,z).   If it contained NULL in vals, will be converted to OP_NULL
		static get OP_N_EQ_IN()       { return "n_eq_in";       } // `field` != ? `field` NOT IN (x,y,z). If it contained NULL in vals, will be converted to OP_N_NULL
		static get OP_LT()            { return "lt";            } // `field` < ?
		static get OP_LT_EQ()         { return "lt_eq";         } // `field` <= ?
		static get OP_GT()            { return "gt";            } // `field` > ?
		static get OP_GT_EQ()         { return "gt_eq";         } // `field` >= ?
		static get OP_BTW()           { return "btw";           } // `field` BETWEEN ? AND ?. NOTE: For betweens, we don't have to fill both vals
		static get OP_N_BTW()         { return "n_btw";         } // `field` NOT BETWEEN ? AND ?. NOTE: For betweens, we don't have to fill both vals
		static get OP_LIKE()          { return "like";          } // `field` LIKE ?
		static get OP_P_LIKE_P()      { return "p_like_p";      } // `field` LIKE %?%
		static get OP_P_LIKE()        { return "p_like";        } // `field` LIKE %?
		static get OP_LIKE_P()        { return "like_p";        } // `field` LIKE ?%
		static get OP_N_LIKE()        { return "n_like";        } // `field` NOT LIKE ?
		static get OP_N_P_LIKE_P()    { return "n_p_like_p";    } // `field` NOT LIKE %?%
		static get OP_N_P_LIKE()      { return "n_p_like";      } // `field` NOT LIKE %?
		static get OP_N_LIKE_P()      { return "n_like_p";      } // `field` NOT LIKE ?
		static _OPS = [
			B_REST_Model_Load_SearchOptions_Filter_base.OP_AUTO,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_NULL,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_N_NULL,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_0_EMPTY_STR,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_N_0_EMPTY_STR,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_EQ_IN,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_N_EQ_IN,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_LT,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_LT_EQ,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_GT,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_GT_EQ,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_BTW,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_N_BTW,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_LIKE,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_P_LIKE_P,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_P_LIKE,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_LIKE_P,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_N_LIKE,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_N_P_LIKE_P,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_N_P_LIKE,
			B_REST_Model_Load_SearchOptions_Filter_base.OP_N_LIKE_P,
		];
		static _nonSetVals = [undefined, null, ""];
		
		
		_name            = null;      //Either a fieldNamePath or custom filter name
		_op              = null;      //Const of OP_x
		_isCustom        = null;      //If it matches with a fieldNamePath or if it's a custom filter
		_valOrArrOrNULL  = undefined; //Where NULL is considered "set". No parsing of data is done in filters in frontend, so if we got a date like "2022-01-01T00:00", it'll be sent as such. So don't send pwds either.
		_modelFieldOrArr = null;      //Other way of working, where we can have 1 or 2 B_REST_ModelField_DB instances. If so, then valOrArrOrNULL & changed will be ignored (but we should though)
		_changed         = true;      //Helps to know when we change filters and that we should put back paging to page 0
		
		
		constructor(name, op, isCustom)
		{
			this._name = name;
			
			if (!B_REST_Model_Load_SearchOptions_Filter_base._OPS.includes(op)) { this._throwEx(`Got unknown filter op "${op}"`); }
			
			this._op       = op;
			this._isCustom = isCustom;
			
			//NOTE: For _modelFieldOrArr, use modelFieldOrArr_reAssign()
		}
		
		static _throwEx(msg,details=null) { B_REST_Utils.throwEx(`B_REST_Model_Load_SearchOptions_Filter_base: ${msg}`,details); }
		       _throwEx(msg,details=null) { B_REST_Utils.throwEx(`B_REST_Model_Load_SearchOptions_Filter_base<${this._name}>: ${msg}`,details); }
		
		
		
		get name()      { return this._name;                 }
		get op()        { return this._op;                   }
		get isCustom()  { return this._isCustom;             }
		get isSet()     { return this.finalData!==undefined; }
		//WARNING: No parsing of data is done in filters in frontend, so if we got a date like "2022-01-01T00:00", it'll be sent as such. So don't send pwds either.
		get finalData()
		{
			if (this._modelFieldOrArr)
			{
				/*
				NOTES:
					This is overriden in B_REST_Model_Load_SearchOptions_Filter_Between, so there are no arr conditions here
					this._modelFieldOrArr.val yields undefined when not set, which is what we want here
				*/
				const singleModelFieldVal = this._modelFieldOrArr.val;
				
				if (B_REST_Utils.array_is(singleModelFieldVal)) { return singleModelFieldVal.length>0 ? singleModelFieldVal : undefined; }
				
				return B_REST_Model_Load_SearchOptions_Filter_base._nonSetVals.includes(singleModelFieldVal) ? undefined : singleModelFieldVal;
			}
			
			return this._valOrArrOrNULL;
		}
		
		get changed()
		{
			if (!this._modelFieldOrArr) { return this._changed; }
			
			if (this._modelFieldOrArr instanceof B_REST_ModelFields.DB) { return this._modelFieldOrArr.userTouch_has; }
			
			return this._modelFieldOrArr[0].userTouch_has || this._modelFieldOrArr[1].userTouch_has;
		}
		set changed(val)
		{
			if (!this._modelFieldOrArr) { this._changed = val; }
			else if (this._modelFieldOrArr instanceof B_REST_ModelFields.DB) { this._modelFieldOrArr.userTouch_toggle(val); }
			else
			{
				this._modelFieldOrArr[0].userTouch_toggle(val);
				this._modelFieldOrArr[1].userTouch_toggle(val);
			}
		}
		
		get modelFieldOrArr() { return this._modelFieldOrArr; }
		//When we change modelFields maybe we want to transfer previous modelFields val into the new ones, or overwrite
		modelFieldOrArr_reAssign(modelFieldOrArrOrNULL, keepPreviousVals)
		{
			//Ex when we're in a B_REST_Vuetify_GenericList_Filter and we unbind
			if (modelFieldOrArrOrNULL===null) { this._modelFieldOrArr=null; return; }
			
			const opIsBtwn = this._op===B_REST_Model_Load_SearchOptions_Filter_base.OP_BTW || this._op===B_REST_Model_Load_SearchOptions_Filter_base.OP_N_BTW;
			
			//Normal cases (not betweens)
			if (modelFieldOrArrOrNULL instanceof B_REST_ModelFields.DB)
			{
				if (opIsBtwn) { this._throwEx(`When we pass modelFieldOrArrOrNULL and it's for OP_x_BTW, we must receive an arr of exactly 2 instances`); }
				
				if (keepPreviousVals && this._modelFieldOrArrOrNULL) { modelFieldOrArrOrNULL.val=this._modelFieldOrArrOrNULL.val; }
			}
			//Betweens
			else if (B_REST_Utils.array_is(modelFieldOrArrOrNULL) && B_REST_Utils.array_isOfClassInstances(B_REST_ModelFields.DB,modelFieldOrArrOrNULL))
			{
				if (!opIsBtwn || modelFieldOrArrOrNULL.length!==2) { this._throwEx(`When modelFieldOrArrOrNULL is an arr, it must be for OP_x_BTW and have exactly 2 instances`); }
				
				if (keepPreviousVals && this._modelFieldOrArrOrNULL)
				{
					modelFieldOrArrOrNULL[0].val = this._modelFieldOrArrOrNULL[0].val;
					modelFieldOrArrOrNULL[1].val = this._modelFieldOrArrOrNULL[1].val;
				}
			}
			else { this._throwEx(`Expected modelFieldOrArrOrNULL to be either a single DB field or an arr of them, depending on the wanted op`,modelFieldOrArrOrNULL); }
			
			this._modelFieldOrArr = modelFieldOrArrOrNULL;
		}
		
		
		//NOTE: Later, maybe we'd like them to have some other default vals when we allocate them
		reset()
		{
			if (!this._modelFieldOrArr)
			{
				this._valOrArrOrNULL = undefined;
				this._changed        = true;
			}
			else if (this._modelFieldOrArr instanceof B_REST_ModelFields.DB)
			{
				this._modelFieldOrArr.clear();
				this._modelFieldOrArr.userTouch_toggle(true);
			}
			//When _modelFieldOrArr is an arr of 2 B_REST_ModelFields.DB
			else
			{
				this._modelFieldOrArr[0].clear();
				this._modelFieldOrArr[1].clear();
				
				this._modelFieldOrArr[0].userTouch_toggle(true);
				this._modelFieldOrArr[1].userTouch_toggle(true);
			}
		}
		
		fromObj(obj)
		{
			obj = B_REST_Utils.object_hasValidStruct_assert(obj, {
				name:           {accept:[String],                   required:true},
				valOrArrOrNull: {accept:[Number,String,Array,null], required:true},
				op:             {accept:[String],                   required:true},
				isCustom:       {accept:[Boolean],                  required:true},
			}, "B_REST_Model_Load_SearchOptions_Filter::fromObj");
			
			let obj_valOrArrOrNull = obj.valOrArrOrNull;
			
			if (!this._modelFieldOrArr)
			{
				this._valOrArrOrNULL = obj_valOrArrOrNull;
				this._changed        = true;
			}
			else if (this._modelFieldOrArr instanceof B_REST_ModelFields.DB)
			{
				/*
				WARNING:
					Used to have the following code to "avoid hell, when we decide to change filter specs and it doesn't fit anymore w prev prefs", but don't remember why exactly.
					Causes filters on a TYPE_ARR field descriptor to never become set, nor be able to restore filter prefs from a prev page (ex for a picker multiple:true in Vue implementation).
					Old code:
						if (B_REST_Utils.array_is(obj_valOrArrOrNull)) { obj_valOrArrOrNull=null; }
					It was either for:
						-Permission concerns when dude shouldn't see XYZ anymore that he picked yesterday
						-Filter toggling between !multiple and then multiple because client changed his mind. Check new code
					NOTE: Check next WARNING too in other code branch below
				*/
				
				//Just prevent hell when filter used to be !multiple, some int got saved to prefs, and client wanted it multiple so now fucks, or the opposite
				if (obj_valOrArrOrNull!==null)
				{
					const fieldDescriptor_isArr = this._modelFieldOrArr.fieldDescriptor.type_is_arr;
					const val_isArr             = B_REST_Utils.array_is(obj_valOrArrOrNull);
					if (fieldDescriptor_isArr!==val_isArr)
					{
						B_REST_Utils.console_warn(`Discarded filter val because it looks like it used to be a !multiple filter that now turned multiple (or the opposite), while we're still passing non-arr vals to it`,{filter:this,obj_valOrArrOrNull});
						obj_valOrArrOrNull = null;
					}
				}
				
				this._modelFieldOrArr.val = obj_valOrArrOrNull;
				this._modelFieldOrArr.userTouch_toggle(false);
			}
			//When this._modelFieldOrArr is an arr, and obj_valOrArrOrNull is either set (an arr) or unset (NULL)
			else
			{
				/*
				WARNING:
					Check the warning above. Used to have the following code but not sure if it's justified:
						if (obj_valOrArrOrNull!==null) { obj_valOrArrOrNull=null; } //Implies B_REST_Utils.array_is(obj_valOrArrOrNull)===true
				*/
				
				//Just prevent hell when filter used to be !multiple, some int got saved to prefs, and client wanted it multiple so now fucks
				if (obj_valOrArrOrNull!==null && !B_REST_Utils.array_is(obj_valOrArrOrNull))
				{
					B_REST_Utils.console_warn(`Discarded filter val because it looks like it used to be a !multiple filter that now turned multiple, while we're still passing non-arr vals to it`,{filter:this,obj_valOrArrOrNull});
					obj_valOrArrOrNull = null;
				}
				
				this._modelFieldOrArr[0].val = obj_valOrArrOrNull ? obj_valOrArrOrNull[0] : null;
				this._modelFieldOrArr[1].val = obj_valOrArrOrNull ? obj_valOrArrOrNull[1] : null;
				
				this._modelFieldOrArr[0].userTouch_toggle(false);
				this._modelFieldOrArr[1].userTouch_toggle(false);
			}
		}
		
		toObj()
		{
			if (!this.isSet) { return null; }
			
			return {
				name:           this._name,
				valOrArrOrNull: this.finalData,
				op:             this._op,
				isCustom:       this._isCustom,
			};
		}
	
		//As "f~firstName~p_like_p~bob|...", where prefix is f~ for fieldNamePath ones and c~ for custom ones
		toQSAPart()
		{
			if (!this.isSet) { return null; }
			
			let val = this.finalData;
			if (val===undefined) { this._throwEx(`Filter not supposed to be set but still undefined`); }
			
			if (B_REST_Utils.array_is(val))
			{
				const formattedVals = [];
				for (const loop_val of val) { formattedVals.push(B_REST_Model_Load_SearchOptions_Filter_base._toQSAPart_oneVal(loop_val)); }
				
				val = `[${formattedVals.join(",")}]`;
			}
			else { val=B_REST_Model_Load_SearchOptions_Filter_base._toQSAPart_oneVal(val); }
			
			return `${this._isCustom?"c":"f"}~${this._name}~${this._op}~${val}`;
		}
			static _toQSAPart_oneVal(val)
			{
				switch (val)
				{
					case null:  return B_REST_Model_Load_SearchOptions.QSA_NULL_TAG;
					case true:  return 1;
					case false: return 0;
				}
				
				return val;
			}
		
		static createDerivedFromOp(name, op, isCustom)
		{
			switch (op)
			{
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_AUTO:
					return new B_REST_Model_Load_SearchOptions_Filter_ValOrArr(name, op, isCustom);
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_NULL:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_N_NULL:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_0_EMPTY_STR:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_N_0_EMPTY_STR:
					return new B_REST_Model_Load_SearchOptions_Filter_On(name, op, isCustom);
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_EQ_IN:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_N_EQ_IN:
					return new B_REST_Model_Load_SearchOptions_Filter_ValOrArr(name, op, isCustom);
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_LT:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_LT_EQ:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_GT:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_GT_EQ:
					return new B_REST_Model_Load_SearchOptions_Filter_NumberOrString(name, op, isCustom);
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_BTW:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_N_BTW:
					return new B_REST_Model_Load_SearchOptions_Filter_Between(name, op, isCustom);
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_LIKE:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_P_LIKE_P:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_P_LIKE:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_LIKE_P:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_N_LIKE:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_N_P_LIKE_P:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_N_P_LIKE:
				case B_REST_Model_Load_SearchOptions_Filter_base.OP_N_LIKE_P:
					return new B_REST_Model_Load_SearchOptions_Filter_String(name, op, isCustom);
				default:
					B_REST_Model_Load_SearchOptions_Filter_base._throwEx(`B_REST_Model_Load_SearchOptions: Unknown op "${op}" for "${name}"`);
			}
		}
	};
		export class B_REST_Model_Load_SearchOptions_Filter_ValOrArr extends B_REST_Model_Load_SearchOptions_Filter_base
		{
			//Syntax sugar. WARNING: Ignored when we use modelFieldOrArr for now (though we should link code together but would add lots of overhead)
			get valOrArr() { return this._valOrArrOrNULL; }
			set valOrArr(val)
			{
				this._valOrArrOrNULL = B_REST_Model_Load_SearchOptions_Filter_base._nonSetVals.includes(val) ? undefined : val;
				this._changed        = true;
			}
		};
		export class B_REST_Model_Load_SearchOptions_Filter_On extends B_REST_Model_Load_SearchOptions_Filter_base
		{
			//Syntax sugar. WARNING: Ignored when we use modelFieldOrArr for now (though we should link code together but would add lots of overhead)
			get on() { return this._valOrArrOrNULL!==undefined  ? !!this._valOrArrOrNULL : undefined; }
			set on(val)
			{
				this._valOrArrOrNULL = (val!==undefined) ? (val?1:0) : undefined;
				this._changed        = true;
			}
		};
		export class B_REST_Model_Load_SearchOptions_Filter_NumberOrString extends B_REST_Model_Load_SearchOptions_Filter_base
		{
			//Syntax sugar. WARNING: Ignored when we use modelFieldOrArr for now (though we should link code together but would add lots of overhead)
			get numberOrString() { return this._valOrArrOrNULL; }
			set numberOrString(val)
			{
				this._valOrArrOrNULL = B_REST_Model_Load_SearchOptions_Filter_base._nonSetVals.includes(val) ? undefined : val;
				this._changed        = true;
			}
		};
		export class B_REST_Model_Load_SearchOptions_Filter_String extends B_REST_Model_Load_SearchOptions_Filter_base
		{
			//Syntax sugar. WARNING: Ignored when we use modelFieldOrArr for now (though we should link code together but would add lots of overhead)
			get string() { return this._valOrArrOrNULL; }
			set string(val)
			{
				this._valOrArrOrNULL = B_REST_Model_Load_SearchOptions_Filter_base._nonSetVals.includes(val) ? undefined : val;
				this._changed        = true;
			}
		};
		//NOTE: For betweens, we don't have to fill both vals
		export class B_REST_Model_Load_SearchOptions_Filter_Between extends B_REST_Model_Load_SearchOptions_Filter_base
		{
			_x = undefined;
			_y = undefined;
			
			//Syntax sugar. WARNING: Ignored when we use modelFieldOrArr for now (though we should link code together but would add lots of overhead)
			get x() { return this._x; }
			set x(val)
			{
				this._x       = val;
				this._changed = true;
			}
			
			get y() { return this._y; }
			set y(val)
			{
				this._y       = val;
				this._changed = true;
			}
			
			//Override base method
			get finalData()
			{
				let x = this._modelFieldOrArr ? this._modelFieldOrArr[0].val : this.x;
				let y = this._modelFieldOrArr ? this._modelFieldOrArr[1].val : this.y;
				
				if (B_REST_Model_Load_SearchOptions_Filter_base._nonSetVals.includes(x)) { x=null; }
				if (B_REST_Model_Load_SearchOptions_Filter_base._nonSetVals.includes(y)) { y=null; }
				
				//We must at least have one of them set in order to count as filled / used
				if (x===null && y===null) { return undefined; }
				return [x, y];
			}
		};
	
	
	
	
	
	
	
	export class B_REST_Model_Load_SearchOptions_OrderByItem
	{
		fieldNamePath = null; //Note that we can sort by ANY fieldNamePath that is a B_REST_FieldDescriptor_DB
		isASC         = true;
		
		
		constructor(fieldNamePath, isASC)
		{
			this.fieldNamePath = fieldNamePath;
			this.isASC         = isASC;
		}
		
		
		toObj()
		{
			return {
				fieldNamePath: this.fieldNamePath,
				isASC:         this.isASC,
			};
		}
		
		//As "firstName~ASC|coords.city~ASC"
		toQSAPart() { return this.isASC ? `${this.fieldNamePath}~ASC` : `${this.fieldNamePath}~DESC`; }
	};
