<template>
	<v-main class="public-layout">
		<div class="fill-height full-bg-img" :class="main_fullBg_class">
			<router-view />
		</div>
		<p v-if="$bREST.currentSessionInfo_debug_show" class="text-caption ma-2" style="position:fixed;right:24px;top:5px;z-index:1000;width:300px;" v-html="$bREST.currentSessionInfo_debug_html" />
	</v-main>
</template>

<script>
	
	export default {
		computed: {
			main_fullBg_class()
			{
				return this.$bREST.uiBreakpoint.name==="xs" ? "full-bg-image--vertical" : "full-bg-image--horizontal"; //NOTE: We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
			},
		},
	}
	
</script>

<style scoped>
	
	.full-bg-img {
		background-repeat: no-repeat;
		background-size: auto;
		background-position: top;
	}
		.full-bg-image--vertical   { background-image: url("../assets/splashscreen--vertical--dark.jpg");        }
		.full-bg-image--horizontal { background-image: url("../assets/splashscreen--horizontal--greyscale.jpg"); }
	
</style>