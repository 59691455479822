<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<!-- Common things between client & staff -->
				<client-staff-form-common-stuff ref="clientStaffFormCommonStuff" :show-opt-fields="showOptFields" :user-type="$bREST.consts.user_types.CLIENT" :model="model" :extra-data="extraData" @change:pwdConfirmation="recalcPwdConfirmationValidation()" @change:recoveryEmailConfirmation="recalcRecoveryEmailConfirmationValidation()" />
			<!-- More client fields, that should be editable even by himself in accountCreation / myProfile -->
				<v-row>
					<v-col v-if="showOptFields" cols="12" sm="6"> <br-field-db :model="model" field="gender" /> </v-col>
					<v-col cols="12" sm="6"> <br-field-db :model="model" field="configSourceMarketing_fk" items="configSourceMarketingList" /> </v-col>
					<v-col v-if="main_franchisee_canSee" cols="12" sm="6"> <br-field-db :model="model" field="main_franchisee_fk" items="filteredFranchiseeList" :label="main_franchisee_label" /> </v-col>
					<v-col v-if="reason_isAccountCreation || reason_isMyProfile" cols="12"> <br-field-db :model="model" field="c28_wantsSpam" as="checkbox" :label="c28_wantsSpam_label" /> </v-col>
				</v-row>
			<!-- More client fields, for management -->
				<template v-if="reason_isModule">
					<v-row>
						<!-- 🚀↑app>modules>x>XForm.vue>template>fields↑🚀 -->
					</v-row>
					<v-row><v-col cols="12"> <br-field-db :model="model" field="user.isEnabled" as="checkbox" /> </v-col></v-row>
					
					<v-expansion-panels v-model="openPanels" accordion>
						<!-- Notes -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.notes.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.notes">
							<x-note-list :note-list="model.select('notes')">
								<template #extraFields="{loop_model}">
									<v-col cols="12"> <br-field-db :model="loop_model" field="configClientNote_fk" items="configClientNoteList" /> </v-col>
								</template>
							</x-note-list>
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Contracts -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.contracts.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.contracts">
							<custom-btn color="primary" @click="contracts_new()">{{ t("tabs.contracts.new") }}</custom-btn>
							<custom-btn color="transparent|error" outlined @click="contracts_terminate()" class="ml-4">{{ t("tabs.contracts.terminate") }}</custom-btn>
							<br-generic-form-base-sub-model-list :form="self" route-names="client>clientSessionContract" :accordion="false" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Tokens -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.tokens.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.tokens">
							<client-form-token-summary :model="model" />
						</v-expansion-panel-content> </v-expansion-panel>
					</v-expansion-panels>
				</template>
			<!--
				Show contrats etc too if logged as client.
				IMPORTANT:
					Don't merge this w the above tabs code when in management, because management has action btns & uses a <br-generic-form-base-sub-model-list>,
					while in myProfile we use a normal list but w a diff apiBaseUrl
			-->
				<template v-if="reason_isMyProfile && $bREST.user_isAuth">
					<v-expansion-panels v-model="openPanels" accordion>
						<!-- Contracts -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.contracts.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.contracts">
							<client-session-contract-list :from-loader="{apiBaseUrl:'/clients/profile/contracts'}" :show-title="false" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Tokens -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.tokens.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.tokens">
							<client-form-token-summary :model="model" />
						</v-expansion-panel-content> </v-expansion-panel>
					</v-expansion-panels>
				</template>
		</template>
		
		<template v-if="reason_isAccountCreation" #actions>
			<custom-btn color="success" class="mx-auto" @click="reason_isAccountCreation_attemptSave()">{{ t("reason_isAccountCreation.save.label") }}</custom-btn>
				<!-- NOTE: Removed :disabled="!shouldSavingBeEnabled", because people are too dumb to check red err msgs at bottom of form when they wonder why btn is disabled -_- -->
		</template>
		
		<!-- 🚀↑app>modules>x>XForm.vue>template>slots↑🚀 -->
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericForm } from "@/bREST/core/implementations/vue";
	import RegFlow from "@/custom/components/regFlow/RegFlow.js";
	
	//If when model is new-ed or done loading (existing pk), if we should also load the tabs content or defer until we open them for the 1st time
	const LOAD_ON_MOUNT_TABS = {
		notes:     false,
		contracts: false,
		tokens:    false,
	};
	
	
	export default {
		name: "clientForm",
		components: {
			ClientStaffFormCommonStuff: () => import("@/custom/components/ClientStaffFormCommonStuff.vue"),
			ClientSessionContractList:  () => import("@/custom/routerViews/modules/clientSessionContract/ClientSessionContractList.vue"),
			XNoteList:                  () => import("@/custom/components/XNoteList.vue"),
			ClientFormTokenSummary:     () => import("./ClientFormTokenSummary.vue"),
			/* 🚀↑app>modules>x>XForm.vue>js>components↑🚀 */
		},
		mixins: B_REST_Vuetify_GenericForm.createMixin({
			modelName:          "Client",
			apiBaseUrl:         "/clients/",
			showValidationErrs: true,    //🚀❓ If we want red err msgs near the save btn at the bottom of the form ❓🚀
			showSkeletonLoader: true,    //🚀❓ While we load an existing record, do we want a grey "skeleton" overlay ? ❓🚀
		  //autoUpdateInterval: 2000,    //🚀❓ If we want that blurring fields auto save modifs every X msecs ❓🚀
			requiredFields:     "<all>|user(<dbOnly>|pwd_dbHash|sudoHash)|notes(<dbOnly>|authorUser.<toLabel>)", //🚀❓ Check server's ModelOptions_base.php docs for "fieldNamePath" possibilities ❓🚀
									//IMPORTANT: Server RouteParser_Clients::PROFILE_ALLOWED_FIELD_NAME_PATHS & frontend's ClientForm.vue::requiredFields must match
			todos: [
				//🚀❓ Arr of {isDone,isBug,text} that will appear automatically at the top of the form ❓🚀
			],
			async modelReady()
			{
				/*
					🚀❓
						When a new record, called right on component mount.
						When an existing record, called after it's done loading & afterLoad() hook done
						Usage ex, where we also adjust for possible cases where we'd get a parent pk
							if (this.model.isNew)
							{
								//For when route is like /citizens/:citizen/animals/:pkTag, or we're from a BrGenericListBase::openFormInVDialog(), etc. Can get better cue parent_modelName or parent_routeName. Check props docs
								if (this.parent_pkTag) { this.model.select("citizen_fk").val=this.parent_pkTag; }
							}
					🚀❓
				*/
				
				if (this.model.isNew && this.regFlow) { this.model.select("main_franchisee_fk").val=this.regFlow.franchisee_fk; }
			},
			async beforeLoad(request)
			{
				if (this.reason_isMyProfile)
				{
					request.reConstruct(request.constructor.METHOD_GET, "clients/profile");
					request.needsAccessToken = true;
				}
			},
			async afterLoad(response,models) { }, //🚀❓ When an existing record, a hook where we still have the API B_REST_Response available to pimp the model. Called before modelReady() ❓🚀
			//🚀❓ Called at the beginning of awaitUnsavedChangesSaved_x() to check if it's ok to save. Can also be called manually. Should put customErrorList.x_add/x_if() here. Check BrGenericFormBase.vue::customErrors_x() docs or how we use it ex in UserForm.vue or MyProfile.vue ❓🚀
			async customValidator()
			{
				//🚀❓ User name & email unicity validation + email verification security code are taken care of automatically in B_REST_App_base::_commonDefs_setupDescriptorHooks()
				
				this.recalcPwdConfirmationValidation();
				this.recalcRecoveryEmailConfirmationValidation();
			},
			//🚀❓ When we want to save, a hook so we can pimp the API B_REST_Request_x that will be sent to the server ❓🚀
			async beforeSave(request,model)
			{
				if (this.reason_isAccountCreation)
				{
					request.reConstruct(request.constructor.METHOD_POST, "clients/profile");
					request.needsAccessToken_setDont();
				}
				else if (this.reason_isMyProfile)
				{
					request.reConstruct(request.constructor.METHOD_PATCH, "clients/profile");
					request.needsAccessToken = true;
				}
				
				if (this.regFlow) { RegFlow.apiCalls_injectRegFlowHash(request,this.regFlow); } //Check server's RouteParser_OutdoorFitness_base::_abstract_setSpecs_fromRequest_checkHasRegFlowHash_setClient_injectForceReload() docs for flow of info between frontend & server for that
			},
			async afterSave(response,model,isSuccess,wasNew) { }, //🚀❓ Like afterLoad(), we just saved and we still have access to the API B_REST_Response, to do extra stuff ❓🚀
		}),
		data()
		{
			return {
				LOAD_ON_MOUNT_TABS,
				dataSets: {
					/* 🚀↑app>modules>x>XForm.vue>js>dataSets↑🚀 */
				},
				age: null,
				openPanels: [],
			};
		},
		computed: {
			reason_isAccountCreation() { return  this.extraData?.reason==="accountCreation"; },
			reason_isMyProfile()       { return  this.extraData?.reason==="myProfile";       },
			reason_isModule()          { return !this.extraData?.reason;                     },
			regFlow()                  { return this.extraData?.regFlow ?? null;             },  //Check server's RouteParser_OutdoorFitness_base::_abstract_setSpecs_fromRequest_checkHasRegFlowHash_setClient_injectForceReload() docs for flow of info between frontend & server for that
			isSelf()                   { return this.reason_isAccountCreation || this.reason_isMyProfile || this.model.select("user_fk").val===this.$bREST.user_pk; },
			main_franchisee_canSee()   { return this.reason_isAccountCreation || (this.reason_isModule&&this.model_isNew) || this.$bREST.roles_isAnyRoleAdmin; },
			main_franchisee_label()    { return this.reason_isAccountCreation ? `${this.t("reason_isAccountCreation.main_franchisee.label")} *` : null; },
			c28_wantsSpam_label()      { return this.t("reason_isAccountCreation_or_isMyProfile.c28_wantsSpam.label"); },
			showOptFields()            { return this.reason_isMyProfile||this.reason_isModule; },
		},
		methods: {
			recalcPwdConfirmationValidation()
			{
				//🚀❓ Calls a func in BrFieldDbConfirmation hosted in common base form, to check if we should register a custom err in the form. Check its docs + BrGenericFormBase::shouldSavingBeEnabled() docs
				this.customErrorList.fast_if(this.$refs.clientStaffFormCommonStuff.pwdConfirmation_checkShouldWarn(), "pwdMismatch");
					//WARNING: $refs aren't reactive, so don't end up using this in some computed - use $emit instead
			},
			recalcRecoveryEmailConfirmationValidation()
			{
				//🚀❓ Calls a func in BrFieldDbConfirmation hosted in common base form, to check if we should register a custom err in the form. Check its docs + BrGenericFormBase::shouldSavingBeEnabled() docs
				this.customErrorList.fast_if(this.$refs.clientStaffFormCommonStuff.recoveryEmailConfirmation_checkShouldWarn(), "recoveryEmailMismatch");
					//WARNING: $refs aren't reactive, so don't end up using this in some computed - use $emit instead
			},
			contracts_new()       { this.$refs.clientStaffFormCommonStuff.quickConnect(); },
			contracts_terminate() { this.$refs.clientStaffFormCommonStuff.quickConnect(); },
			reason_isAccountCreation_attemptSave()
			{
				this.model.userTouch_toggleAllFields(true);
				this.awaitUnsavedChangesSaved_alwaysResolve();
			},
		},
	};
	
</script>