<template>
	<br-generic-list-base :derived-component="_self">
		<!--
			🚀❓
				3 ways to get col's data, where the 3rd is an ex of if we want to make it out of multiple things:
					<template #item.someFieldOrColumnName="{ rowInfo, colInfo, modelField }">
						<span>{{ modelField.val }}</span>
						<span>{{ rowInfo.model.select("someFieldName").val }}</span>
						<span>{{ rowInfo.model.select_nonEmptyValsConcatenated("first_name|last_name") }}</span>
					</template>
			🚀❓
		-->
		
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "clientList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./ClientForm.vue"),
			modelName: "Client",
			fromLoader: {
				apiBaseUrl: "/clients/",
			},
			cols: {
				/*
					🚀❓
						Can create cols made of multiple fields, ex:
							someColName: {
								fieldNamePaths:"first_name|last_name",
								style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}, -> fromBreakpoint: Vuetify breakpoint like xs|sm|md|lg|xl
								toCellContent(col,model)
								{
									return model.select_nonEmptyValsConcatenated("first_name|last_name"); -> Check server's ModelOptions_base.php docs for "fieldNamePath" possibilities
								},
									-> toCellContent() is only required if we want to do something custom
							}
						
						Also have additional props:
							{
								click: {
									isEnabled: bool | (<B_REST_Vuetify_GenericList.Col>col,<B_REST_Model>model),                        -> If a func, must ret bool
									hook:      async(<B_REST_Vuetify_GenericList.Col>col,<B_REST_Model>model,isCtrlClickOrMiddleClick), -> Must ret bool for if action was successful
										-> This is for when we just click on some row's cell
								},
								isEditable: bool | (<B_REST_Vuetify_GenericList.Col>col,<B_REST_Model>model), -> If a func, must ret bool. Will convert the read only column into a <br-field-db>
								style: {
									hasRightBorder,
									width,
									tdClass: (<B_REST_Vuetify_GenericList.Col>col,<B_REST_Model>model), -> Must ret a string/arr/obj. Will go into a <td :class> NOTE: We have this.$bREST.classProp_addTag() helper
									tdStyle: (<B_REST_Vuetify_GenericList.Col>col,<B_REST_Model>model), -> Must ret a string/arr/obj. Will go into a <td :style> NOTE: We have this.$bREST.styleProp_addTag() helper
								},
								vBind:     {}, -> Will transfer props to the component, ex {class:"text-body-1 warning--text", style:"width:10px"}
								vOn:       {}, -> Will transfer events to the components, ex {click(){}, hover(){}},
								extraData: {}, -> Other stuff we want to keep in the B_REST_Vuetify_GenericList.Col instance
							}
					🚀❓
				*/
				
				name: {
					fieldNamePaths: "user.<toLabel>",
					toCellContent(col,model)
					{
						return model.select("user").toLabel();
					},
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				phone: {
					fieldNamePaths: "phone",
					style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				address: {
					fieldNamePaths: "address",
					style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				postalCode: {
					fieldNamePaths: "postalCode",
					style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				/*
					🚀❓
						Also have additional props:
							{
								click: {
									isEnabled: bool | (<B_REST_Vuetify_GenericList.GlobalAction>action,<B_REST_Model>selectedModels=null),                        -> If a func, must ret bool
									hook:      async(<B_REST_Vuetify_GenericList.GlobalAction>action,<B_REST_Model>selectedModels=null,isCtrlClickOrMiddleClick), -> Must ret bool for if action was successful
								},
								icon,          -> Ex "mdi-account"
								style,         -> Props to transfer to the v-btn, ex {rounded:true, outlined:false, size:48}
								color,         -> Ex "warning"
								selectionType, -> One of B_REST_Vuetify_GenericList.GlobalAction.SELECTION_TYPE_x
								extraData,     -> Other stuff we want to keep in the B_REST_Vuetify_GenericList.GlobalAction instance
							}
					🚀❓
				*/
				
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				/*
					🚀❓
						Also have additional props:
							{
								click: {
									isEnabled: bool | (model<B_REST_Model>),                        -> If a func, must ret bool
									hook:      async(model<B_REST_Model>,isCtrlClickOrMiddleClick), -> Must ret bool for if action was successful
										-> This is for when we just click anywhere on the row
								},
								style: {
									trStyle: (model<B_REST_Model>), -> Must ret a string/arr/obj. Will go into a <tr :class> NOTE: We have this.$bREST.classProp_addTag() helper
									trClass: (model<B_REST_Model>), -> Must ret a string/arr/obj. Will go into a <tr :style> NOTE: We have this.$bREST.styleProp_addTag() helper
								},
							}
					🚀❓
				*/
				
				checkbox: {isEnabled:true},
				actions: {
					/*
						🚀❓
							Also have additional props:
								{
									click: {
										isEnabled: bool | (<B_REST_Vuetify_GenericList.RowAction>action,<B_REST_Model>model),
										hook:      async(<B_REST_Vuetify_GenericList.RowAction>action,<B_REST_Model>model,isCtrlClickOrMiddleClick), //Must ret bool
									},
									icon,      -> Ex "mdi-email"
									style,     -> Props to transfer to the v-btn, ex {rounded:true, outlined:false, size:48}
									color,     -> Ex "success"
									extraData, -> Other stuff we want to keep in the B_REST_Vuetify_GenericList.RowAction instance
								}
						🚀❓
					*/
					
					quickConnect: {
						click: {
							isEnabled(action,model) { return !!model.select("user.sudoHash").data; },
							async hook(action,model,isCtrlClickOrMiddleClick)
							{
								await this.$bREST.sudoIn(model.select("user.sudoHash").data);
								return true;
							},
						},
						icon: "mdi-account-switch",
					},
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				/*
					🚀❓
						Also have additional props:
							{
								custom: NULL or the following (either fieldNamePath or custom must be set)
										{
												customFilterName: ex "someCitizenCustomFilterName" defined in the model in the server
											or
												fieldDescriptor:  We must either provide our own fake B_REST_FieldDescriptors.DB (B_REST_FieldDescriptor_DB) instance
												type:             ... or at least specify a B_REST_FieldDescriptors.DB.TYPE_x (B_REST_FieldDescriptor_DB::TYPE_x)
										},
								brFieldDbProps: Optional map of optional attrs like {items, multiple:<bool>, as:<string>, ...} (check BrFieldDb.vue docs), to pass down to a <br-field-db>. NOTE: We can also set some out of this obj; check below (ex {as,items,picker})
							}
					🚀❓
				*/
				
				calc_flatSearch: {fieldNamePath:"calc_flatSearch"},
				c28_wantsSpam: {fieldNamePath:"c28_wantsSpam"},
				calc_type: {fieldNamePath:"calc_type"},
				configSourceMarketing: {fieldNamePath:"configSourceMarketing_fk", items:"configSourceMarketingList"},
				main_franchisee_fk: {fieldNamePath:"main_franchisee_fk", picker:"franchiseeList", multiple:true},
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>