
import { B_REST_Utils, B_REST_Model } from "../../../../../classes";
import B_REST_VueApp_base             from "../../../B_REST_VueApp_base.js";
import B_REST_VueApp_RouteDef         from "../../../B_REST_VueApp_RouteDef.js";



class B_REST_Vuetify_GenericList_Action_base
{
	_listComponent   = null;   //BrGenericListBase der this belongs to
	_name            = null;   //Unique X action name
	_click_isEnabled = null;   //Either bool or func. Signature depends on the derived class
	_click_hook      = null;   //Async func. Signature depends on the derived class. Data table will freeze until it resolves / rejects. Must ret bool
	_mustConfirm     = false;  //If we want an automatic dialog to prompt for confirmation. Loc path will be "<locBasePath>.confirm", and will contain extraData: {count, modelsString}. Ex "Do you want to del the {count} following records: {modelsString} ?"
	_displayResult   = false;  //If after the hook, we want msgs "<locBasePath>.success" or "<locBasePath>.failure" to appear automatically, both again with {count, modelsString}
	_icon            = null;   //Like "mdi-xxx"
	_style           = null;   //If we want a btn shape over the icon, or just an icon w/o btn borders. Vuetify prop like "tile", "rounded" etc
	_color           = null;   //Color of the icon or btn
	_extraData       = null;   //Optional obj
	//WARNING: If we add stuff here, also add in constructor() + clone()
	
	
	//For options struct, check deriveds
	constructor(listComponent, name, options)
	{
		this._listComponent = listComponent;
		this._name          = name;
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			click:         {accept:[Object],  required:true},
			mustConfirm:   {accept:[Boolean], default:null},
			displayResult: {accept:[Boolean], default:null},
			icon:          {accept:[String],  default:null},
			style:         {accept:[String],  default:null}, //For now a string, but maybe wrong
			color:         {accept:[String],  default:null},
			extraData:     {accept:[Object],  default:null},
		}, "Generic list action");
		
		//Click related
		{
			const clickOptions = B_REST_Utils.object_hasValidStruct_assert(options.click, {
				isEnabled: {accept:[Boolean,Function], default:true},
				hook:      {accept:undefined,          required:true},
			}, "Generic list action click");
			
			this._click_isEnabled = clickOptions.isEnabled;
			this._click_hook      = clickOptions.hook;
		}
		
		this._mustConfirm   = options.mustConfirm;
		this._displayResult = options.displayResult;
		this._icon          = options.icon;
		this._style         = options.style;
		this._color         = options.color;
		this._extraData     = options.extraData;
	}
	
	
	static _throwEx(msg, details=null) { B_REST_Utils.throwEx(msg, details); }
	       _throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`, details); }
	
	
	get listComponent() { return this._listComponent; }
	
	get name()          { return this._name;                                              }
	get debugName()     { return `B_REST_Vuetify_GenericList_Action_base<${this._name}>`; }
	get mustConfirm()   { return this._mustConfirm;                                       }
	get displayResult() { return this._displayResult;                                     }
	get icon()          { return this._icon;                                              }
	get style()         { return this._style;                                             }
	get color()         { return this._color;                                             }
	get extraData()     { return this._extraData;                                         }
	
	
	getBtnAttrs(isClickable) { return this._abstract_getBtnAttrs(isClickable); }
		_abstract_getBtnAttrs(isClickable) { B_REST_Vuetify_GenericList_Action_base._throwEx(`Must override in der`); }
	
	//Props we allow changing later
	set extraData(val) { this._extraData=val; }
	
	//Prefix ex "contactPicker.<whichActions>.<name>" to stuff like ".label", etc
	get locBasePath() { return this._abstract_locBasePath; }
	get label()       { return this._loc("label"); }
		get _abstract_locBasePath() { B_REST_Vuetify_GenericList_Action_base._throwEx(`Must override in der`); }
		_loc(subLocPath,details=null) { return B_REST_VueApp_base.instance.t_custom(`${this.locBasePath}.${subLocPath}`,details); }
	
	//For automatic prompts
	getMsg_confirm(count,modelsString) { return this._loc("confirm",{count,modelsString}); }
	getMsg_success(count,modelsString) { return this._loc("success",{count,modelsString}); }
	getMsg_failure(count,modelsString) { return this._loc("failure",{count,modelsString}); }
	
	_perform_click_isEnabled(modelOrModelListOrNULL)
	{
		if (!this._click_isEnabled)       { return false; }
		if (this._click_isEnabled===true) { return true;  }
		
		try       { return this._click_isEnabled.call(this._listComponent,this,modelOrModelListOrNULL); }
		catch (e) { B_REST_Utils.throwEx(`click_isEnabled hook failed, for ${this.debugName}: ${e}`);   }
		return false;
	}
	//Must ret if hook went well
	async _perform_click_hook(modelOrModelListOrNULL, isCtrlClickOrMiddleClick)
	{
		if (!this._click_hook) { return false; }
		
		try       { return await this._click_hook.call(this._listComponent,this,modelOrModelListOrNULL,isCtrlClickOrMiddleClick); }
		catch (e) { B_REST_Utils.throwEx(`click_hook failed, for ${this.debugName}: ${e}`);                                       }
		return false;
	}
	
	//To be used in der defineCommonAction_x()
	static async _defineCommonAction_addEdit(ifEdit_model, isCtrlClickOrMiddleClick, alwaysOpenInVDialog)
	{
		const myApp         = B_REST_VueApp_base.instance; //Or this.$bREST
		const methodPrefix  = isCtrlClickOrMiddleClick ? "routes_goBlank" : "routes_go";
		const methodSuffix  = ifEdit_model             ? "pkTag"          : "new"; //Ex routes_go_subModuleForm_pkTag() vs routes_go_subModuleForm_new()
		const openInVDialog = alwaysOpenInVDialog || this.pickerHandle!==null;
		
		if (this.isCurrentRouteMainComponent && this.subModelListOptions) { B_REST_Utils.throwEx(`Can't have isCurrentRouteMainComponent & subModelListOptions set at the same time`); }
		
		//When the list is the main component of the current route (most of the time)
		if (this.isCurrentRouteMainComponent)
		{
			if (openInVDialog) { return this.openFormInVDialog(ifEdit_model?ifEdit_model.pk_tag:null); }
			
			//Most of the time, we want adds & edits to leave the current page and redirect to the form's route
			const routeDef = myApp.routes_current_def;
			if (routeDef.type!==B_REST_VueApp_RouteDef.TYPE_AUTH_MODULE_LIST) { myApp.throwEx(`Expected current route to be for a B_REST_VueApp_RouteDef::TYPE_AUTH_MODULE_LIST`,routeDef); }
			const moduleName = routeDef.name.replace("-list", ""); //Ex "client-list" -> "client"
			
			const args = [moduleName];
			if (ifEdit_model) { args.push(ifEdit_model.pk_tag); } //As pkTag
			
			const methodName = `${methodPrefix}_moduleForm_${methodSuffix}`; //Ex routes_go_moduleForm_pkTag
			return myApp[methodName](...args);
		}
		//When the list lies within a BrGenericFormBaseSubModelList, used in a BrGenericFormBase
		else if (this.subModelListOptions)
		{
			if (openInVDialog) { return this.openFormInVDialog(ifEdit_model?ifEdit_model.pk_tag:null); } //Will auto take care of adding parent_form info
			
			const {parent_routeName, parent_form, sub_routeName} = this.subModelListOptions;
			const qsa                                            = null;
			const reloadApp                                      = false;
			
			if (parent_form.model_hasUnsavedChanges)
			{
				//Note that when we get there, parent already has a PK, so it's not to make sure it's created
				
				if (!parent_form.shouldSavingBeEnabled) { B_REST_Utils.throwEx(`We must save parent form, but we can't; prolly because of validation errs`); }
				await parent_form.awaitUnsavedChangesSaved(); //Throws on err
			}
			
			const args = [parent_routeName, parent_form.model.pk, sub_routeName];
			if (ifEdit_model) { args.push(ifEdit_model.pk_tag); } //As sub_pkTag
			args.push(qsa);
			args.push(reloadApp);
			
			const methodName = `${methodPrefix}_subModuleForm_${methodSuffix}`; //Ex routes_go_subModuleForm_pkTag
			return myApp[methodName](...args);
		}
		else if (this.pickerHandle)
		{
			if (!openInVDialog || ifEdit_model) { B_REST_Utils.throwEx(`Not supposed to happen`); }
			return this.openFormInVDialog(null); //Will auto take care of adding parent_form info
		}
		//When the list is used in some component, that is prolly not a BrGenericForm
		else
		{
			if (openInVDialog) { B_REST_Utils.throwEx(`Not supported for now, but we should + means that after creation/exit, list should reflect the change`); }
			B_REST_Utils.throwEx(`Got unhandled case`);
		}
	}
};
	
	
	
	
	
	
	export class B_REST_Vuetify_GenericList_GlobalAction extends B_REST_Vuetify_GenericList_Action_base
	{
		static get SELECTION_TYPE_0()   { return "none";       } //Ex for an add btn, we don't need to display checkboxes to be able to do the action
		static get SELECTION_TYPE_1()   { return "exactlyOne"; } //For an action that requires exactly 1 checked row
		static get SELECTION_TYPE_0_N() { return "optional";   } //For an action that we can either do on all rows, or only the checked ones
		static get SELECTION_TYPE_1_N() { return "required";   } //For an action that requires checked rows
		
		_selectionType = B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0_N;
		/*
		Options as
			{
				click: {
					isEnabled: bool | (<B_REST_Vuetify_GenericList_GlobalAction>action,<B_REST_Model>selectedModels=null)
					hook:      async(<B_REST_Vuetify_GenericList_GlobalAction>action,<B_REST_Model>selectedModels=null,isCtrlClickOrMiddleClick) //Must ret bool
									WARNING: Don't change signature, as it'd cause probs in BrGenericListBase::on_xAction_click() when user overrides hooks
				},
				icon,
				style,
				color,
				selectionType, //One of B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_x
				extraData,
			}
		*/
		constructor(listComponent, name, options)
		{
			super(listComponent, name, options);
			
			if (B_REST_Utils.object_hasPropName(options,"selectionType")) { this._selectionType=options.selectionType; }
		}
		
		
		get _abstract_locBasePath() { return `${this._listComponent.t_baseLocPath}.globalActions.${this._name}`; }
		
		get selectionType()        { return this._selectionType; }
		get selectionType_is_0()   { return this._selectionType===B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0;   }
		get selectionType_is_1()   { return this._selectionType===B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_1;   }
		get selectionType_is_0_N() { return this._selectionType===B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0_N; }
		get selectionType_is_1_N() { return this._selectionType===B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_1_N; }
		
		_abstract_getBtnAttrs(isClickable)
		{
			const WIDTH = 36; //NOTE: If we change that, will maybe have impacts in BrGenericListBase.vue::ROW_ACTIONS_BTN_REQ_SIZE
			
			const style = this._style || null;
			const color = this._color || "primary";
			
			return {
				color:       isClickable ? color : null,
				dark:        isClickable && !!this._color,
				icon:        style==="icon",
				tile:        style==="tile",
				rounded:     style==="rounded",
			};
		}
		
		click_isEnabled(selectedModelsOrNULL) { return this._perform_click_isEnabled(selectedModelsOrNULL); }
		//Must ret if hook went well
		async click_hook(selectedModelsOrNULL,isCtrlClickOrMiddleClick) { return this._perform_click_hook(selectedModelsOrNULL,isCtrlClickOrMiddleClick); }
		
		/*
		Helper to define a {add:{...}} action
		For options, for now we only support passing the equivalent of {click:{isEnabled(){}}}, as "options.isEnabled"
		Check BrGenericListBase huge doc block for ex
		NOTE: If for a subModelList, will check if parent form has unsaved changes, and if it does, will try to save them and could throw on validation err etc (no matter new/existing PK)
		*/
		static defineCommonAction_add(options=null)
		{
			const alwaysOpenInVDialog = options?.openInVDialog ?? false;
			
			return {
				add: {
					click: {
						isEnabled: options?.isEnabled ?? true,
						async hook(action,selectedModels,isCtrlClickOrMiddleClick)
						{
							return B_REST_Vuetify_GenericList_Action_base._defineCommonAction_addEdit.call(this,/*ifEdit_model*/null,isCtrlClickOrMiddleClick,alwaysOpenInVDialog);
						},
					},
					icon: options?.icon ?? "mdi-plus",
					selectionType: B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0,
				},
			};
		}
	};
	
	
	
	
	
	
	export class B_REST_Vuetify_GenericList_RowAction extends B_REST_Vuetify_GenericList_Action_base
	{
		/*
		Options as
			{
				click: {
					isEnabled: bool | (<B_REST_Vuetify_GenericList_RowAction>action,<B_REST_Model>model)
					hook:      async(<B_REST_Vuetify_GenericList_RowAction>action,<B_REST_Model>model,isCtrlClickOrMiddleClick) //Must ret bool
									WARNING: Don't change signature, as it'd cause probs in BrGenericListBase::on_xAction_click() when user overrides hooks
				},
				icon,
				style,
				color,
				extraData,
			}
		*/
		constructor(listComponent, name, options)
		{
			super(listComponent, name, options);
		}
		
		
		get _abstract_locBasePath() { return `${this._listComponent.t_baseLocPath}.rowActions.${this._name}`; }
		
		
		
		click_isEnabled(model)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
			return this._perform_click_isEnabled(model);
		}
		//Must ret if hook went well
		async click_hook(model, isCtrlClickOrMiddleClick)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
			return this._perform_click_hook(model, isCtrlClickOrMiddleClick);
		}
		
		_abstract_getBtnAttrs(isClickable)
		{
			const WIDTH = 36; //NOTE: If we change that, will maybe have impacts in B_REST_Vuetify_GenericList::ROW_ACTIONS_BTN_REQ_SIZE
			
			const style = this._style || "icon";
			const color = this._color || null;
			
			return {
				color:       isClickable ? color : null,
				dark:        isClickable && !!this._color,
				icon:        style==="icon",
				tile:        style==="tile",
				rounded:     style==="rounded",
				width:       WIDTH,
				"min-width": WIDTH,
			};
		}
		
		/*
		Helper to define a {edit:{...}} action
		For options, for now we only support passing the equivalent of {click:{isEnabled(){}}}, as "options.isEnabled"
		Check BrGenericListBase huge doc block for ex
		NOTE: If for a subModelList, will check if parent form has unsaved changes, and if it does, will try to save them and could throw on validation err etc (no matter new/existing PK)
		*/
		static defineCommonAction_edit(options=null)
		{
			const alwaysOpenInVDialog = options?.openInVDialog ?? false;
			
			return {
				edit: {
					click: {
						isEnabled: options?.isEnabled ?? true,
						async hook(action,model,isCtrlClickOrMiddleClick)
						{
							return B_REST_Vuetify_GenericList_Action_base._defineCommonAction_addEdit.call(this,model,isCtrlClickOrMiddleClick,alwaysOpenInVDialog);
						},
					},
					icon: options?.icon ?? "mdi-pencil",
					selectionType: B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0,
				},
			};
		}
	};
